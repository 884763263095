
import React, { useState } from 'react';
import { handleDelete, handleKeyDown, handleChange } from './handleModal';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Typography,
    Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        buttonUpload: {
            backgroundColor: "#026277",
            color: "white",
            textTransform: "none",
            borderRadius: "6px",
            fontSize: "10px",
            display: "flex",
            alignItems: "flex-end",
            gap: "2px",
            width: "30px",

            padding: "6px 12px", // Added padding for better appearance
            '&:disabled': {
                backgroundColor: '#B0B0B0', // Gray color for the disabled state
                color: '#FFFFFF'           // White text for the disabled state
            },
            '&:hover': {
                backgroundColor: '#026277', // Gray color for the disabled state
                color: "white",
                // padding: "8px 14px", // Added padding for better appearance

            }
        },
        listEmails: {
            fontSize: "10px"
        }
    };
});
const ModalInvite = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);
    const handleEnd = async () => {
        // Perform action based on selected folder
        console.log("");
    };
    return (
        <div>
            <div style={{ marginTop: "20px" }}>
                {items.map((item) => (

                    <div className={`${classes.listEmails} tag-item`} key={item}>
                        {item}
                        <button
                            type="button"
                            className="button"

                            onClick={() => handleDelete(item, items, setItems)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                    borderRadius: "8px",
                    border: "1px solid #D5D4DC",
                }}
            >
                <TextField
                    fullWidth
                    variant="standard"
                    placeholder={t("invite_placeholder")}
                    InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.textField }, // Apply the custom class here

                        style: { fontSize: "12px", color: "black" }
                    }}
                    InputLabelProps={{
                        style: { fontSize: "12px" }
                    }}
                    style={{ padding: "3px" }}

                    value={value}
                    onKeyDown={(evt) => {
                        handleKeyDown(
                            evt,
                            value,
                            setItems,
                            setValue,
                            items,
                            setError
                        );
                    }}
                    onChange={(value) => {
                        handleChange(value, setValue, setError);
                    }}
                //   onPaste={email.handlePaste}
                />
            </div>
            <Box display="flex" justifyContent="flex-end" marginTop="10px">
                <Button disabled={isLoading || items.length === 0} className={classes.buttonUpload} onClick={handleEnd}>{isLoading ? <ClipLoader size={12} color={"#026277"} /> : t("invite_button")}</Button>
            </Box>
        </div>
    );
};

export default ModalInvite;
