import React from "react";
import { PacmanLoader } from "react-spinners";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Spinner = ({ height, left }) => {
  const classes = useStyles();

  return (
    <div className={classes.spinner} style={{ height, left }}>
      <PacmanLoader size={25} color={"#026277"} />
    </div>
  );
};

export default Spinner;
