import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useLocation, Routes, Route } from 'react-router-dom';
import ChooseFiles from './ChooseFiles';
import ReadComponent from './ReadComponent';
import EvaluateComponent from './MainComponent';
import CorrectionComponent from './CorrectionComponent';
import { useAuth } from '../Auth/Authhandle';
import { WebSocketContext } from '../WebSocketProvider';
import { Typography } from "@material-ui/core";
import { t } from 'i18next';
import { fetchMemorise } from './Api/handle';
import NeedCredits from '../NoCreditsModal/NeedCredits';

const MemoriseView = () => {
    const { accessToken, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { memorise_id } = useParams(); // Adjust to match your parameter name
    const [initPage, setInitPage] = useState(1);
    const [finalPage, setFinalPage] = useState(20);
    const [fileChosen, setFileChosen] = useState({});
    const [folderChosen, setFolderChosen] = useState({});
    const [openGeneration, setOpenGeneration] = useState(false);
    const [openCorrecting, setOpenCorrecting] = useState(false);
    const { messageCallback } = useContext(WebSocketContext);
    const [memoriseId, setMemoriseId] = useState("");
    const [showUpgrade, setShowUpgrade] = useState(false)

    useEffect(() => {
        if (messageCallback) {
            let memorise_id = "";
            switch (messageCallback.type) {
                case "MemoriseGenerating":
                    memorise_id = messageCallback.MemoriseGuid;
                    setMemoriseId(memorise_id);
                    navigate(`/memorise/${memorise_id}/read`);
                    break;
                case "MemoriseCorrecting":
                    setOpenCorrecting(false);
                    memorise_id = messageCallback.MemoriseGuid;
                    setMemoriseId(memorise_id);
                    navigate(`/memorise/${memorise_id}/correction`);
                    break;
                default:
                    console.log("Unhandled message type:", messageCallback.document);
            }
        }
    }, [messageCallback]);

    function getIdFromUrl() {
        const url = new URL(window.location.href);
        const pathSegments = url.pathname.split('/');

        // Assuming the ID is always in the 3rd segment, adjust the index if necessary
        const id = pathSegments[2]; // pathSegments[0] is '', pathSegments[1] is 'memorise', pathSegments[2] is the ID

        return id;
    }

    // Initial data fetch on page load
    let currentId = getIdFromUrl();
    // fetchDataById(currentId);
    useEffect(() => {
        if (!isNaN(initPage) && !isNaN(finalPage) && currentId != "" && accessToken != null && user != null) {
            fetchMemorise(accessToken, currentId, user)
                .then(data => {
                    setInitPage(data.init_page)
                    setFinalPage(data.final_page)
                })
                .catch(error => {
                    console.error("Error fetching file data:", error);
                });

        }
    }, [currentId, accessToken, user]);
    useEffect(() => {
        if (location.state?.reset) {
            setFileChosen({});
            setFolderChosen({});
            navigate("/memorise", { replace: true, state: {} }); // Clear the reset state to avoid loops
        }
    }, [location.state, navigate]);

    const handleGenerateMemorise = () => {
        // setOpenGeneration(true);
    };

    const handleGenerateCorrection = () => {
        setOpenCorrecting(true);
    };

    const renderGenerationModal = () => (
        openGeneration && (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: "99999"
            }}>
                <div style={{
                    textAlign: 'center',
                    padding: 20,
                }}>
                    <img src={require("./img/readyMemorise.svg").default} style={{
                        maxWidth: '400px',
                        marginBottom: 15,
                    }} />
                    <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#026277" }}>{t("ready_memorise")}</Typography>
                </div>
            </div>
        )
    );

    const renderCorrectingModal = () => (
        openCorrecting && (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: "99999"
            }}>
                <div style={{
                    textAlign: 'center',
                    padding: 20,
                }}>
                    <img src={require("./img/readyMemorise.svg").default} style={{
                        maxWidth: '400px',
                        marginBottom: 15,
                    }} />
                    <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#026277" }}>Estamos comprobando tus resultados</Typography>
                </div>
            </div>
        )
    );

    return (
        <>
            {
                <NeedCredits open={showUpgrade}

                    onClose={() => setShowUpgrade(false)} />
            }
            <div>
                {renderGenerationModal()}
                {renderCorrectingModal()}
                <Routes>
                    <Route path="/" element={
                        <ChooseFiles
                            nextStep={handleGenerateMemorise}
                            setFileChosen={setFileChosen}
                            setFolderChosen={setFolderChosen}
                            accessToken={accessToken}
                            user={user}
                            setInitPage={setInitPage}
                            setFinalPage={setFinalPage}
                            finalPage={finalPage}
                            initPage={initPage}
                            setOpenGeneration={setOpenGeneration} // Show the modal immediately
                            setShowUpgrade={setShowUpgrade}
                        />
                    } />
                    <Route path=":memorise_id/read" element={
                        <ReadComponent

                            accessToken={accessToken}
                            user={user}
                            fileChosen={fileChosen}
                            setFileChosen={setFileChosen}
                        />
                    } />
                    <Route path=":memorise_id/evaluate" element={
                        <EvaluateComponent
                            accessToken={accessToken}
                            user={user}
                            fileChosen={fileChosen}
                            initPage={initPage}
                            finalPage={finalPage}
                            nextStep={handleGenerateCorrection}
                        />
                    } />
                    <Route path=":memorise_id/correction" element={
                        <CorrectionComponent
                            accessToken={accessToken}
                            user={user}
                            fileChosen={fileChosen}
                            initPage={initPage}
                            finalPage={finalPage}
                            setShowUpgrade={setShowUpgrade}
                        />
                    } />
                </Routes>
            </div>
        </>
    );
};

export default MemoriseView;
