import {
  Button,
  Grid,
  Paper,
  Typography, Container
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { fetchSummaryChapterid } from '../Api/ChaptersApi';
import ModalFolder from "../Folders/ModalFolders";
import NeedCredits from "../NoCreditsModal/NeedCredits";

import { useParams } from "react-router-dom";
import { fetchChaptersDocument } from '../Api/ChaptersApi'; // Import the fetchUserInfo function
import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function
import Document from "../Documents/Document";
import FolderContent from "../Folders/FolderContent"; // Adjust the path based on your folder structure
import BreadCrumb from "../NewComponents/BreadCrumb"; // Adjust the path based on your folder structure
import Chat from "../NewComponents/Chat";
import * as handle from "./handle";
import { useNavigate } from 'react-router-dom';

import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";

import { useTranslation } from 'react-i18next';
import Summary from "../Documents/SummaryChapters";
import { WebSocketContext } from '../WebSocketProvider';
import CustomList from "./CustomList";
import useMainStructureStyles from "./Styles/useMainStructureStyles";
import UploadButton from "../NewComponents/SubirApuntesButton";
import ModalParent from "../AllModals/ParentModal";
import { fetchFolderExams } from "../Api/ExamsApi";
import ModalNotReady from "../NewComponents/ModalNotReady";
import FullScreenLoader from "../NewComponents/LoadingExam";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



function MainStructure({ active_folder }) {

  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const [listDocuments, setListDocuments] = useState([]);
  const [listMemorise, setListMemorise] = useState([]);
  const [listExams, setListExams] = useState([]); // Define setListExams state

  const [messages, setMessages] = useState([]); // Move messages state up
  const [isVideo, setIsVideo] = useState(true);
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [valueVideo, setValueVideo] = useState("Ver video");
  const [videoUrl, setVideoUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [typeModal, setTypeModal] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [listFolders, setListFolders] = useState([])
  // Folder info 
  const [folder, setFolder] = useState(null)
  // Single Doc info
  const [docInfo, setDocInfo] = useState(null)

  //Alert
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");

  const { t } = useTranslation();

  const { chapter_id, selectedChapter } = useParams();


  // async function fetchAllFolders() {
  //   if (accessToken && user) {
  //     try {
  //       const fetchFolders = await fetchUserFolders(accessToken, "GET", null, user);
  //       setListFolders(fetchFolders);


  //     } catch (error) {
  //       console.error("Error fetching folders: ", error);
  //     }
  //   }
  // }
  // useEffect(() => {
  //   fetchAllFolders();
  // }, [accessToken, user]);
  const { folder_id, selectedFolder } = useParams();
  const [summary, setSummary] = useState("")
  // For upload file pop up
  // Assuming modalOpenUpload is a boolean to control the visibility of a modal
  const [modalOpenUpload, setModalOpenUpload] = useState(false);
  const [titleOptionUpload, setTitleOptionUpload] = useState('');
  const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
  const [buttonOptionUpload, setButtonOptionUpload] = useState('');
  const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
  const [typeUpload, setTypeUpload] = useState('');
  const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
  const [showAlertUpload, setShowAlertUpload] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [messageUpload, setMessageUpload] = useState('');
  const [chaptersTitle, setChaptersTitle] = useState([]);
  const [chapterTitle, setChapterTitle] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [width, setWidthModal] = useState("");
  const [isDocument, setIsDocument] = useState(false);
  const [isPreparingExam, setIsPreparingExam] = useState(false);

  const classes = useMainStructureStyles(isDocument);


  const handleUploadFile = () => {
    handle.handleUploadFile(
      t,
      setModalOpenUpload,
      setTitleOptionUpload,
      setDescriptionOptionUpload,
      setButtonOptionUpload,
      setPlaceholderMessageUpload,
      setTypeUpload,
      setWidthModal
    );
  };
  const handleCreateExam = () => {
    handle.handleCreateExam(
      t,
      setModalOpenUpload,
      setTitleOptionUpload,
      setDescriptionOptionUpload,
      setButtonOptionUpload,
      setPlaceholderMessageUpload,
      setTypeUpload,
      setWidthModal,

    );
  };
  useEffect(() => {
    async function fetchData() {
      if (accessToken && user) {
        handle.fetchData(accessToken, setFolder, folder_id, user); // Call the fetchData function
        handle.fetchDocuments(accessToken, setListDocuments, folder_id, user);
      }
    }

    fetchData();
  }, [accessToken, user]);






  const fetchExams = async () => {

    if (accessToken && user && folder) {
      try {
        const listExamsFetched = await fetchFolderExams(accessToken, "GET", folder.guid, user);
        setListExams(listExamsFetched);
      } catch (error) {
        console.error(error);
      }
    }
  };
  // const updateListDocuments = async (document_id) => {
  //   console.log("DID WE GET HERE???")
  //   // setListDocuments(currentDocuments => {
  //   //   const filteredEntries = currentDocuments.filter(([guid]) => guid !== document_id);
  //   //   return filteredEntries
  //   // });
  //   setListDocuments([])
  //   handle.fetchDocuments(accessToken, setListDocuments, folder_id, user);
  // };

  const updateListDocuments = async (document_id) => {

    // State update function
    setListDocuments(currentDocuments => {
      // Filter out the document with the specified ID
      const filteredEntries = currentDocuments.filter(document => document.guid !== document_id);
      return filteredEntries;
    });
  }





  // const shouldShowGenerateButton = useMemo(() => {
  //   // Check if any chapter does not have a summary generated
  //   return chaptersTitle.some(chapter => !chapter.is_active);
  // }, [chaptersTitle]);  // Recompute when chaptersTitle changes

  // const setAllChaptersLoading = () => {
  //   const newLoadingStates = chaptersTitle.reduce((acc, chapter) => {
  //     acc[chapter.guid] = true;  // Set loading to true for each chapter
  //     return acc;
  //   }, {});
  //   setLoadingStates(newLoadingStates);
  // };
  const navigate = useNavigate();


  const [typeAlert, setTypeAlert] = useState("");
  const [linkinMessage, setLinkinMessage] = useState("");
  const [examRedirectId, setExamRedirectId] = useState("");
  const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
  const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");

  const backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
  const url = "";
  const { messageCallback } = useContext(WebSocketContext);
  useEffect(() => {
    if (messageCallback) {
      switch (messageCallback.type) {
        case "summary":
          setSummary(messageCallback.summary);
          break;
        case "documentsUpdate":
          console.log("callback ", messageCallback.document)
          let folderId_callback = messageCallback.folderId
          let documentId_callback = messageCallback.documentId
          setShowAlert(false);
          setShowAlertUpload(false)
          setShowSpinnerUpload(false)
          // navigate(`/${folderId_callback}`)

          setListDocuments(prevDocuments => [...prevDocuments, messageCallback.document]);
          break;
        case "Exam":
          fetchExams()
          break;
        case "Error":
          alert(messageCallback.messageAlert)
          setShowAlert(false);
          setShowAlertUpload(false)
          setShowSpinnerUpload(false)
          break;

        default:
          console.log("Unhandled message type:", messageCallback.document);
      }
    }
  }, [messageCallback]);
  // const handleWebSocketMessage = useCallback((message) => {
  //   console.log('Received chapters:', message);
  //   switch (message.type) {
  //     case "chapterUpdate":
  //       setChaptersTitle(message.chapters);
  //       break;

  //     default:
  //       console.log("Unhandled message type:", message);
  //   }
  // }, []);
  // const [sendMessage] = useWebSocketComponent(url, handleWebSocketMessage);
  const handleExamCreated = () => {
    fetchExams();
  };
  const [openNotReady, setOpenNotReady] = useState(false);
  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };

  useEffect(() => {

    if (isPreparingExam) {
      const timer = setTimeout(() => {
        setIsPreparingExam(false);
      }, 4000);
      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
    }
  }, [isPreparingExam]);


  return (
    <>
      {showAlert &&
        <SmallAlert
          message={message}
          progressBar={false}
          onClose={() => setShowAlert(false)}
          linkinMessage={linkinMessage}
          typeAlert={typeAlert}
        />
      }
      {showAlertUpload &&
        <SmallAlert
          message={messageUpload}
          progressBar={progressBar}
          onClose={() => setShowAlertUpload(false)}
        />
      }

      {
        <NeedCredits open={showUpgrade} message={message}

          onClose={() => setShowUpgrade(false)} />
      }

      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {isPreparingExam && <FullScreenLoader
        imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
        title={t('fullScreenLoader.examPreparingTitle')}
        message={t('fullScreenLoader.examPreparingMessage')}
        buttonText={t('fullScreenLoader.examPreparingButton')}
        onClose={() => { setIsPreparingExam(false); }}
        onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}

      />}
      <Container

        spacing={3}
        className={classes.gridContainer}
      >
        <>
          <Grid
            item
            className={classes.leftPart}
            style={{ width: "100%" }}
          >
            <Paper elevation={3} className={classes.paper} style={{ height: "95vh" }}>
              <div style={{ position: 'relative', justifyContent: "space-between", display: "flex", marginBottom: 5 }}>
                {/* <BreadCrumb className={classes.breadcrumbContainer} root={t("home")} docInfo={docInfo} folderInfo={folder} accessToken={accessToken} chapterTitle={chapterTitle} /> */}
                {!docInfo ? (
                  <Typography style={{
                    color: "#026277",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    letterSpacing: "-0.225px",
                    paddingLeft: 2
                  }}>{folder ? folder.name : "Folder"}</Typography>
                ) : (
                  <Typography style={{
                    color: "#026277",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    letterSpacing: "-0.225px",
                    paddingLeft: 2
                  }}>{docInfo.title}</Typography>
                )}
                {folder !== null && active_folder && (
                  <div>
                    <UploadButton onClick={handleUploadFile} />
                    {/* <Button onClick={() => handle.handleShareFolder(
                      t,
                      setModalOpen,
                      setTitle,
                      setDescription,
                      setButton,
                      setPlaceholderMessage,
                      setTypeModal,
                      setOpenMenu
                    )}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 19C15 16.7909 12.3137 15 9 15C5.68629 15 3 16.7909 3 19M19 16V13M19 13V10M19 13H16M19 13H22M9 12C6.79086 12 5 10.2091 5 8C5 5.79086 6.79086 4 9 4C11.2091 4 13 5.79086 13 8C13 10.2091 11.2091 12 9 12Z" stroke="#000B0D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Button>

                    <Button onClick={() => handle.handleUploadFile(
                      t,
                      setModalOpenUpload,
                      setTitleOptionUpload,
                      setDescriptionOptionUpload,
                      setButtonOptionUpload,
                      setPlaceholderMessageUpload,
                      setTypeUpload,

                    )}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 18V12M12 12L9 14M12 12L15 14M13 3.00087C12.9045 3 12.7973 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20296 19 9.09561 18.9991 9M13 3.00087C13.2856 3.00347 13.4663 3.01385 13.6388 3.05526C13.8429 3.10425 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59182 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53376 18.9963 8.71451 18.9991 9M13 3.00087V5.8C13 6.9201 13 7.47977 13.218 7.90759C13.4097 8.28392 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.9991M18.9991 9H19.0002" stroke="#000B0D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Button> */}
                  </div>
                )}




              </div>
              {active_folder ? (
                <div style={{ overflowY: "scroll" }}>
                  <FolderContent
                    listDocuments={listDocuments}
                    listMemorise={listMemorise}
                    setListMemorise={setListMemorise}
                    folder={folder}
                    updateListDocuments={updateListDocuments} // Pass the function as a prop
                    setIsDocument={false}
                    // setMessage={setMessage}
                    // setShowAlert={setShowAlert}
                    accessToken={accessToken}
                    user={user}
                    setTitle={setTitleOptionUpload}
                    setDescription={setDescription}
                    setButton={setButton}
                    setPlaceholderMessage={setPlaceholderMessage}
                    setModalOpen={setModalOpenUpload}
                    setType={setTypeUpload}
                    handleUploadFile={handleUploadFile} // Pass the function as a prop
                    handleCreateExam={handleCreateExam}
                    setListExams={setListExams}
                    listExams={listExams}
                    handleClickOpenNotReady={handleClickOpenNotReady}

                  />
                </div>
              ) : (

                chapter_id ? (
                  <div className={classes.summaryContainer}>
                    <Summary accessToken={accessToken} user={user} setChapterTitle={setChapterTitle} />
                  </div>

                ) : (
                  null
                  // <Document docInfo={docInfo} summary={summary} accessToken={accessToken} user={user} setMessage={setMessage} setShowAlert={setShowAlert} handleCreateExam={handleCreateExam} />
                )




              )}

            </Paper>
          </Grid>
          {!active_folder && (
            <Grid
              item
              className={classes.rightPart}


            >
              <Paper elevation={3} className={classes.paperright}


              // style={isDocument ? { marginRight: "10px", height: "calc(100% - 60px)" } : null}

              >




                <Chat
                  height={"100%"}
                  messages={messages}
                  setMessages={setMessages}
                  accessToken={accessToken} // Passing accessToken as a prop
                  user={user}
                  isPdf={true}


                />


              </Paper>
            </Grid>
          )}
        </>
      </Container >

      {/* 
      {modalOpen && (
        <ModalFolder
          open={true}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          type={typeModal}
          onClose={() => {
            setModalOpen(false); setOpenMenu(false); // Refresh folders list
          }}
          folder_id={folder.guid}
        />
      )
      } */}
      {
        modalOpenUpload && (
          <ModalParent
            open={modalOpenUpload}
            title={titleOptionUpload}
            description={descriptionOptionUpload}
            sendButton={buttonOptionUpload}
            placeholdMessage={placeholderMessageUpload}
            type={typeUpload}
            onClose={() => setModalOpenUpload(false)}
            setShowSpinner={setShowSpinnerUpload}
            setShowAlert={setShowAlertUpload}
            setMessage={setMessageUpload}
            setProgressBar={setProgressBar}
            setShowUpgrade={setShowUpgrade}
            width={width}
            onExamCreated={handleExamCreated} // Pass the callback to handle exam creation
            updateListDocuments={updateListDocuments}
            setIsPreparingExam={setIsPreparingExam}
            setFolderSelectedGuid={setFolderSelectedGuid}
            setDocumentSelectedGuid={setDocumentSelectedGuid}

          />
        )
      }

    </>
  );
}

export default MainStructure;
