import React from "react";
import { BeatLoader } from "react-spinners";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    spinnerButton: (props) => ({
        position: "absolute",
        top: "5%",
        transform: props.customTransform || "translate(-50%, -50%)", // Use customTransform if provided
        display: "inline-flex",
        alignItems: "center",
        padding: "8px 16px", // Add padding to create a button-like appearance
        borderRadius: "10px", // Apply border radius
        border: "1px solid #026277", // Add border
        backgroundColor: "#ffffff", // Add background color if needed
        cursor: "pointer", // Add pointer cursor
        [theme.breakpoints.down('1700')]: {
            transform: "translate(-100%, -30%)",

        },


        [theme.breakpoints.down('1500')]: {

            transform: "translate(-100%, -20%)",

        },
        [theme.breakpoints.down('1300')]: {

            transform: "translate(-100%, -0%)",

        },

        [theme.breakpoints.up('2000')]: {
            transform: "translate(-50%, -70%)",


        },

    }),
    text: {
        marginLeft: "8px", // Add spacing between text and loader
        marginRight: "20px", // Add spacing between text and loader
        color: "black",
        fontWeight: 500,
        fontSize: "14px",
        padding: "5px 15px",
    },
}));

const Spinner = ({ height, customTransform }) => {
    const classes = useStyles({ customTransform }); // Pass customTransform to useStyles
    const { t } = useTranslation();

    return (
        <div className={classes.spinnerButton} style={{ height }}>
            <Typography className={classes.text}>{t("uploading_file")}</Typography>
            <BeatLoader size={12} color={"#026277"} />
        </div>
    );
};

export default Spinner;
