import { makeStyles } from "@material-ui/core/styles";

const useDocumentStyles = makeStyles((theme) => ({

    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        // Other styles...
        justifyContent: "flex-start", // Add this line
        maxWidth: "none", // Add this line
        margin: "none", // Add this line
    },

    leftPart: {
        //     maxWidth: "initial",
        //     flexBasis: '50vw', // Add this line
        border: "1px solid #6BBDBD",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px", // Add this line
        borderBottomRightRadius: "0px",
        borderBottom: "0px",
        //     marginLeft: "50px",
        //     backgroundColor: "white"
        display: "flex",
        flexDirection: "column",
        flex: 2, // This will make the paper fill the height of its container
        minHeight: '100%',
        height: "auto", overflowY: 'hidden',
        backgroundColor: "white"



    },
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        flexGrow: 1,
        height: "100%",  // Ensure it takes the full height of its parent
        overflowY: "hidden",  // Enable vertical scrolling
        backgroundColor: "white",
        margin: "20px 50px 3px 50px",

    },

    rightPart: {
        // height: "calc(100vh - 40px)", /* Full viewport height */
        // borderLeft: "1px solid black",
        boxShadow: "none !important",
        marginBottom: "40px"
        , flexBasis: '34vw', // Sets the base size of the left part
        [theme.breakpoints.up('2000')]: {
            flexBasis: '34vw',

        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '34vw', // Sets the base size of the left part

        },
    },
    paperright: {
        // padding: "20px",

        marginRight: "10px",
        height: '100%',  // Make Paper take full height of its container
        display: 'flex',
        flexDirection: 'column',  // Ensures the children (like Chat) also stretch properly
        backgroundColor: "#F8FFFF",
        boxShadow: "none"
    },
    paper: {
        margin: "20px 50px 20px 50px",

        display: "flex",
        flexDirection: "column",
        boxShadow: "none",

    },
    chatFixed: {
        // position: "fixed",
        bottom: "10px", // Adjust as needed
        right: "10px", // Adjust as needed
        width: "25vw", // Adjust as needed
        maxHeight: "100%", // Adjust as needed
        overflowY: "auto", // To allow internal scrolling of the chat
        zIndex: 1000, // Make sure the chat is above other elements
        // ... other necessary styles
    },
    importantPart: {
        // position: 'fixed',
        paddingRight: "30px",
        width: '-webkit-fill-available',
        zIndex: 10,
        height: '-webkit-fill-available', // Full height of its container
        display: 'flex', // Add flex display
        flexDirection: 'column', // Stack children vertically
    },
    breadcrumbContainer: {
        position: 'sticky',
        top: 0, // Stick to the top of the viewport
        zIndex: 1100, // Higher index to ensure it's above other content
        padding: theme.spacing(2), // Uniform padding
        width: '100%', // Full width
    },
    summaryContainer: {
        overflowY: "auto", // Only the summary content will scroll
        height: "100%", // You might need to adjust this depending on your layout
    },
    titleAndButtonContainer: {
        justifyContent: 'space-between',
        display: "flex",
        marginBottom: 10,
        marginTop: 15,
        flexShrink: 0,
    },

    // Existing styles...
    chatContainer: {
        flex: 1, // This will allow the chat to grow and fill the space
        display: 'flex', // Ensure it is a flex container
        flexDirection: 'column', // Stack children vertically
        paddingBottom: "20px"
    },
    buttonUpload: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",

        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        },
        padding: "4px 11px !important"
    },


}));

export default useDocumentStyles;