import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode"; // You might need to install jwt-decode

export const useAuth = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);
    const [sessionExpired, setSessionExpired] = useState(false); // Added state for session expiration

    // Function to check if the token is expiring soon
    const isTokenExpiring = (token) => {

        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // current time in seconds
        const expiryTime = decodedToken.exp;
        const timeToExpiry = expiryTime - currentTime;
        return timeToExpiry < 60 * 5; // refresh if less than 5 minutes to expiry

    };

    // Function to fetch or refresh the access token
    const fetchAccessToken = async () => {
        try {
            const token = await getAccessTokenSilently();
            setAccessToken(token);
        } catch (error) {
            console.error("Error getting access token:", error);
        }
    };


    const handleLogout = () => {
        logout({ logoutParams: { returnTo: "https://www.google.com" } })
        setSessionExpired(true); // Set the session expired state
    };
    // Inside your useAuth hook
    useEffect(() => {
        if (isAuthenticated && user) {
            if (!accessToken || isTokenExpiring(accessToken)) {
                fetchAccessToken().catch((error) => {
                    if (error.error === 'login_required' || error.error === 'consent_required') {
                        // Token cannot be refreshed, session has expired
                        handleLogout();
                    }
                });
            }
        }
    }, [accessToken, user, isLoading, isAuthenticated, getAccessTokenSilently]);

    // Then return setSessionExpired from your hook to allow components to trigger this state


    return {
        accessToken,
        user,
        isLoading,
        sessionExpired, // Expose the sessionExpired state
        setSessionExpired, // Expose the function to change the sessionExpired state
        handleLogout // Expose the logout function
    }
};
