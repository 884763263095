import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    examTitle: {
        fontSize: '12px !important', // Use important to override any existing styles
    },
    deleteIcon: {
        cursor: 'pointer',
    },
    seeExam: {
        cursor: 'pointer',
        marginRight: "30px",
        fontSize: "10px",
        padding: "5px",
        borderRadius: "6px",
        color: "black",
        backgroundColor: "#E2F1F1",
        color: "#026277",
        fontWeight: "500"

    },
}));

const ExamList = ({ listExams, handleItemClick, handleRemoveExam }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div style={{ maxHeight: '575px', overflowY: 'auto' }}>
            <List component="nav" aria-label="mailbox folders">
                {Object.entries(listExams).map(([guid, examDetails]) => (
                    <React.Fragment key={guid}>
                        <ListItem >
                            <ListItemText
                                primaryTypographyProps={{ className: classes.examTitle }}
                                secondaryTypographyProps={{ className: classes.examTitle }}
                                primary={examDetails.title}
                                secondary={examDetails.location}
                            />
                            {!examDetails.is_active ? (
                                <>
                                    <ListItemIcon>
                                        <ClipLoader color="#026277" size={15} />
                                    </ListItemIcon>
                                    <ListItemIcon>
                                        <button
                                            onClick={async (e) => {
                                                e.stopPropagation(); // Prevent row click event
                                                handleRemoveExam(guid);
                                            }}
                                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                        >
                                            <svg
                                                className={classes.deleteIcon}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
                                                    fill="#111827"
                                                />
                                            </svg>
                                        </button>
                                    </ListItemIcon>
                                </>
                            ) : (
                                <>
                                    <ListItemIcon className={classes.seeExam}
                                        onClick={() => handleItemClick(guid, examDetails.is_active)}>
                                        {t("see_exam")}
                                    </ListItemIcon>
                                    <ListItemIcon
                                        onClick={async (e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            handleRemoveExam(guid);
                                        }}
                                    >
                                        <svg
                                            className={classes.deleteIcon}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
                                                fill="#111827"
                                            />
                                        </svg>
                                    </ListItemIcon>
                                </>
                            )}
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
};

export default ExamList;
