import React from 'react';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const BILLING = process.env.REACT_APP_BILLING

const useStyles = makeStyles((theme) => {

    return {
        title: {
            textAlign: 'center',
            color: "#000",

            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "50px", /* 125% */
            letterSpacing: "-0.3px",
            width: "500px",
            margin: '0 auto', // Centers the title div
            marginBottom: '20px'

        },
        subtitle: {
            textAlign: 'center',
            color: "#000",

            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "30px", /* 125% */
            letterSpacing: "-0.3px",
            width: "450px",
            margin: '0 auto', // Centers the title div

        },
    }
})
const SubscriptionInactiveCard = ({ onReactivate }) => {
    const classes = useStyles();
    const { t } = useTranslation(); // Add this line to use the translation hook

    return (
        <Card style={{ display: 'flex', flexDirection: 'row', height: '100vh', alignItems: 'stretch' }}>
            {/* Left part with embedded SVG image */}
            <img
                src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/error4.png"
                alt="Error"
                style={{ height: '100vh', width: 'auto', objectFit: 'cover' }}
            />

            {/* Right part with text and button */}
            <CardContent style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography className={classes.title} >
                    {t("innactive_subscription")}
                </Typography>
                <Typography className={classes.subtitle}>
                    {t("canceled_subscription")}
                </Typography>

                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={() => { window.location.href = `https://billing.stripe.com/p/login/eVa8A35P223ef1S000` }}
                    style={{
                        marginTop: '20px', alignSelf: 'center', width: "120px", textTransform: 'None', display: "flex",
                        width: "184px",
                        height: "66px",
                        padding: "8px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        flexShrink: 0,
                    }}
                >
                    Reactivar
                </Button>
            </CardContent>
        </Card >
    );
};

export default SubscriptionInactiveCard;

