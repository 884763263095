import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Checkbox, withStyles } from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked, Check, CheckboxButtonUnchecked } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

// Custom unchecked icon: a square with pink borders
const SquareUncheckedIcon = () => (
    <div style={{
        width: '24px',
        height: '24px',
        border: '2px solid #026277',
        borderRadius: '4px', // Adjust for more or less rounded corners
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        {/* Empty for the unchecked state */}
    </div>
);

// Custom checked icon: a square with pink borders and a checkmark
const SquareCheckedIcon = withStyles({
    root: {
        // Adjust size, color, etc., as needed
        color: '#026277', // Checkmark color
        // Position the checkmark inside the square
        '& svg': {
            fontSize: '18px', // Adjust checkmark size
        },
    },
})(({ classes }) => (
    <div style={{
        width: '24px',
        height: '24px',
        border: '2px solid #026277',
        borderRadius: '4px', // Adjust for more or less rounded corners
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent', // Change if you want a fill color
    }}>
        <Check className={classes.root} />
    </div>
));

const CustomFormControl = ({ options, value, handleChange, multiSelect = false }) => (
    <FormControl component="fieldset">
        {multiSelect ? (
            <RadioGroup
                name="customOptions"
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        control={
                            <Checkbox
                                icon={<SquareUncheckedIcon />}

                                checked={value.includes(option.value)}
                                onChange={handleChange}
                                value={option.value}
                                checkedIcon={<SquareCheckedIcon />}
                            />
                        }
                        label={
                            <Tooltip title={option.label} placement="top">
                                <span>{option.label.length > 30 ? option.label.slice(0, 30) + '...' : option.label}</span>
                            </Tooltip>
                        }
                    />
                ))}
            </RadioGroup>
        ) : (
            <RadioGroup
                name="customOptions"
                value={value}
                onChange={handleChange}
                style={{ paddingLeft: 3 }}

            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        control={<Radio icon={<SquareUncheckedIcon />} checkedIcon={<SquareCheckedIcon />} />}
                        label={option.label.length > 20 ? option.label.slice(0, 20) + '...' : option.label}
                        value={option.value}
                    />
                ))}
            </RadioGroup>
        )}
    </FormControl>
);

export default CustomFormControl;
