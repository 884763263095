import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, FormControl } from '@mui/material';

function LanguageSwitcher() {
    const { i18n } = useTranslation();

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <FormControl variant="outlined" size="small">
            <Select
                labelId="language-switcher-label"
                id="language-switcher"
                value={i18n.language}
                onChange={handleChange}
                style={{ width: 120, fontSize: "14px" }}

            >
                <MenuItem style={{ fontSize: "14px" }}
                    value="en">English</MenuItem>
                <MenuItem style={{ fontSize: "14px" }}
                    value="es">Español</MenuItem>
            </Select>
        </FormControl>
    );
}

export default LanguageSwitcher;
