import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalFolder from "../../Folders/ModalFolders";
import { makeStyles } from "@material-ui/core/styles";
import * as handle from "./handle";
import { useTranslation } from 'react-i18next';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';

// Sample styles
const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&.MuiMenuItem-root": {
      fontSize: "14px",
      backgroundColor: "transparent !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08) !important",
      },
    },
  },
}));

// Function to handle the SVG click

function Options({ type, folder, fetchDataAndUpdate, showList }) {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [typeModal, setTypeModal] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();


  return (
    <>
      {
        showList ? (
          <>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();

                // Logic for sharing folder
                handle.handleShareFolder(
                  t,
                  setModalOpen,
                  setTitle,
                  setDescription,
                  setButton,
                  setPlaceholderMessage,
                  setTypeModal,
                  setOpenMenu
                );
              }}
            >
              <ShareIcon style={{ color: "#026277" }} /> {/* Assuming you have a ShareIcon component */}
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();

                // Logic for deleting folder
                handle.handleDeleteFolder(
                  t,
                  setModalOpen,
                  setTitle,
                  setDescription,
                  setButton,
                  setPlaceholderMessage,
                  setTypeModal,
                  setOpenMenu
                );
              }}
            >
              <DeleteIcon
                style={{ color: "#026277" }}

              />
            </IconButton>
          </>
        ) : (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              onClick={(event) =>
                handle.handleMenu(event, openMenu, setAnchorEl, setOpenMenu)
              }
            >
              <path
                d="M4.74184 11.0578H4.75133M11.3806 11.0578H11.39M18.0193 11.0578H18.0287M5.69023 11.0578C5.69023 11.5362 5.26562 11.924 4.74184 11.924C4.21806 11.924 3.79346 11.5362 3.79346 11.0578C3.79346 10.5794 4.21806 10.1916 4.74184 10.1916C5.26562 10.1916 5.69023 10.5794 5.69023 11.0578ZM12.3289 11.0578C12.3289 11.5362 11.9043 11.924 11.3806 11.924C10.8568 11.924 10.4322 11.5362 10.4322 11.0578C10.4322 10.5794 10.8568 10.1916 11.3806 10.1916C11.9043 10.1916 12.3289 10.5794 12.3289 11.0578ZM18.9677 11.0578C18.9677 11.5362 18.543 11.924 18.0193 11.924C17.4955 11.924 17.0709 11.5362 17.0709 11.0578C17.0709 10.5794 17.4955 10.1916 18.0193 10.1916C18.543 10.1916 18.9677 10.5794 18.9677 11.0578Z"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Menu
              id={"basic-menu"}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={(event) =>
                handle.handleMenu(event, openMenu, setAnchorEl, setOpenMenu)
              }
              style={{ flexDirection: "column" }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handle.handleShareFolder(
                    t,
                    setModalOpen,
                    setTitle,
                    setDescription,
                    setButton,
                    setPlaceholderMessage,
                    setTypeModal,
                    setOpenMenu
                  );
                }}
                className={classes.menuItem}
              >
                <EditIcon
                  style={{
                    fontSize: "16px",
                    paddingRight: "5px",
                  }}
                />
                {t("share")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handle.handleDeleteFolder(
                    t,
                    setModalOpen,
                    setTitle,
                    setDescription,
                    setButton,
                    setPlaceholderMessage,
                    setTypeModal,
                    setOpenMenu
                  );
                }}
                className={classes.menuItem}
              >
                <DeleteIcon

                  style={{
                    fontSize: "16px",
                    paddingRight: "5px",
                  }}
                />
                {t("delete")}
              </MenuItem>
            </Menu>


          </div>
        )
      }
      {modalOpen && (
        <ModalFolder
          open={true}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          type={typeModal}
          onClose={() => {
            setModalOpen(false); setOpenMenu(false); fetchDataAndUpdate(); // Refresh folders list
          }}
          folder_id={folder.guid}
        />
      )}
    </>
  )
}
export default Options;
