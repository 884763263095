import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useEffect, useState } from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingBottom: theme.spacing(3),
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  strongText: {
    fontWeight: "bold",
  },
  actionButtons: {
    justifyContent: "center",
  },
  createButton: {
    backgroundColor: "#026277",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "white",
      color: "#026277",
    },
  },
  okButton: {

    backgroundColor: "#026277",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "white",
      color: "#026277",
    },
  },
  cancelButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: "red",
      color: "white",
    },
  },
}));

function ClipDialog({
  open,
  firstWord,
  lastWord,
  timeFirstWord,
  timeLastWord,
  transcript_id,
  onClose,
}) {
  const classes = useStyles();
  const [processing, setProcessing] = useState(false); // State for processing message

  let token = localStorage.getItem("outseta_token");

  const handleCreateClip = () => {
    fetch(`${BACKEND_URL}/api_v1/clip_action/?access_token=${token}`, {
      method: "POST",
      body: JSON.stringify({
        time_start: timeFirstWord,
        time_end: timeLastWord,
        T_id: transcript_id,
        type: "start",
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        const presignedUrl = data.presignedUrl;

        // Show processing message
        setProcessing(true);

        // Close the dialog after a delay (you can adjust the delay as needed)
      })
      .catch((err) => {
        console.error(err);
        setProcessing(false); // Hide the processing message in case of an error
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>Crear Clip</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {processing ? (
          <>
            <p>El clip se está procesando y estará listo en unos minutos.</p>

          </>
        ) : (
          <p>
            Quiere crear un clip desde{" "}
            <strong className={classes.strongText}>{firstWord}</strong> hacia{" "}
            <strong className={classes.strongText}>{lastWord}</strong>?
          </p>
        )}
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        {processing ? (
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.okButton}
          >
            OK
          </Button>
        ) : (
          <>
            <Button
              onClick={handleCreateClip}
              variant="contained"
              className={classes.createButton}
            >
              Si
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              className={classes.cancelButton}
            >
              No
            </Button>
          </>
        )}
      </DialogActions>

    </Dialog>
  );
}

export default ClipDialog;
