import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { getCsrfToken } from '../Api/TokenApi'
import { useAuth } from "../Auth/Authhandle";

const REACT_APP_STRIPE_LOADER = process.env.REACT_APP_STRIPE_LOADER;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(REACT_APP_STRIPE_LOADER);

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('');
  const { accessToken, user } = useAuth(); // Use the hook at the top level

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests

    fetch("/create-checkout-session", {
      method: "POST",
      headers: { 'X-CSRFToken': csrfToken, 'Authorization': `Bearer ${accessToken}` }

    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}
export default CheckoutForm;