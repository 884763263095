import React, { useState, useCallback, useEffect } from 'react';
import WorkQuestion from './WorkQuestion'; // Import your question components
import CreateFolder from './CreateFolder';
import CreateExam from './CreateExam';
import useWelcomeStyle from './Styles/useWelcomeStyle';
import { useNavigate } from "react-router-dom";


import {
    Typography,
    Box,
    Button,
    Container,
    LinearProgress, CircularProgress
} from "@mui/material";
import { useAuth } from "../Auth/Authhandle";

import { makeStyles } from "@material-ui/core/styles";
import "./style.css"
import * as api from "./api";
import * as handle from "./handle";
import { useTranslation } from 'react-i18next';
import CalendlyEmbed from '../NewComponents/Calendar/CalendlyComponent';



const SplitScreenComponent = () => {
    const navigate = useNavigate();

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [folderName, setFolderName] = useState("");
    const [folderFiles, setFolderFiles] = useState([]);
    const [examFile, setExamFile] = useState([]);
    const [profession, setProfession] = useState("");
    const [numQuest, setNumQuest] = useState("");
    const [typeQuest, setTypeQuest] = useState("");
    const classes = useWelcomeStyle();
    const { t } = useTranslation();
    const [isActivatingAccount, setIsActivatingAccount] = useState(false); // New state for activation status

    const { accessToken, user } = useAuth(); // Use the hook at the top level


    // Function to handle the next button

    const handleNext = useCallback(() => {

        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }, [currentQuestionIndex, folderName, folderFiles]); // Add dependencies here

    const handleAnswerSelected1 = useCallback(valueSelected => {
        setProfession(valueSelected)
    }, []);
    const handleAnswerSelected2 = (name, files) => {
        setFolderName(name);
        setFolderFiles(files);
    };
    const handleAnswerSelected3 = useCallback((num_questions, type_questions) => {
        setNumQuest(num_questions);
        setTypeQuest(type_questions);
        setExamFile(folderFiles[0]);

    }, []);


    const handleOnboard = async () => {
        setIsActivatingAccount(true); // Start the activation process

        // First api call to save onboard profession
        const promises = [];
        let folderResult = null;
        try {

            if (profession) {
                try {
                    const result = await api.sendAnswer(profession, accessToken, user);
                    if (result && result.path) {
                        // Navigate using the ID from the result
                        navigate(`/onboarding`);
                    } else {
                        // Default redirect if result or ID is not available
                        navigate("/onboarding");
                    }
                } catch (error) {
                    console.error("Error sending answer:", error);
                    // Handle the error accordingly, maybe show a notification to the user
                    navigate("/onboarding");
                }
            } else {
                navigate("/onboarding");
            }
        } catch (error) {
            console.error("Error during onboard process:", error);
            // Optionally, set an error state here to show an error message to the user
        } finally {
            setIsActivatingAccount(false); // End the activation process regardless of outcome
        }
        // Second api call to create folder and upload if folder and files

        // Third call to create exam if all values are correct

    }
    // Render the current question component based on the index
    const renderQuestionComponent = () => {
        if (isActivatingAccount) {
            return (
                <Box textAlign="center">
                    <CircularProgress />
                    <Typography>{t("account_getitng_activated")}...</Typography> {/* Make sure you add this key to your translation files */}
                </Box>
            );
        }
        switch (currentQuestionIndex) {
            case 0: return <WorkQuestion onAnswerSelected={handleAnswerSelected1} />;
            // case 1: return <CalendlyEmbed />;
            // case 0:
            //     return <WorkQuestion onAnswerSelected={handleAnswerSelected1} />;
            // case 1:
            //     return <CreateFolder onAnswerSelected={handleAnswerSelected2} />;
            // case 2:
            //     if (folderFiles) {
            //         return <CreateExam onAnswerSelected={handleAnswerSelected3} />;
            //     } else {
            //         return null
            //     }

            // case 3:
            //     return null

        }
    };
    useEffect(() => {
        // This function ensures Tally embeds are initialized
        if (window.Tally) {
            window.Tally.loadEmbeds();
        }
    }, []);
    return (
        <>
            <div className={classes.fullScreenBanner}>
                <div>

                    <img src={require('./images/iconName.svg').default} alt="Typed Icon" className={classes.typedIconImg} />
                </div>
                <Typography className={classes.bannerText}>
                    {t("banner_phone")}

                </Typography>
                <img src={require('./images/PhoneImage.svg').default} alt="Typed only available on computers" className={classes.bannerImage} />
            </div>
            <div className={classes.root}>
                {/* <div className={classes.imageContainer}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "530px",
                    marginBottom: "40px"
                }}>
                    <Typography
                        style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {t("welcome_typed")} 
                    </Typography>
                    <Typography style={{
                        fontSize: "24px",
                        textAlign: "center",
                        fontWeight: 400,
                        lineHeight: "40px", 
                        letterSpacing: "-0.18px"
                    }}>
                      {t('tell_us_more')}
                    </Typography>
                </div>
            </div> */}
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", margin: "0 auto" }}>

                    <Box mt={2} flexDirection="column" display="flex" >
                        {/* {renderQuestionComponent()} */}
                        <iframe
                            data-tally-src="https://tally.so/embed/n0LEd0?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                            loading="lazy" width="500" height="630" frameborder="0" marginheight="0" marginwidth="0"
                            title="¡Bienvenido/a a Typed!👋"
                        ></iframe>
                        {/* <iframe data-tally-src="https://tally.so/r/n0LEd0?transparentBackground=1" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="¡Bienvenido/a a Typed!👋"></iframe> */}

                        {/* <iframe data-tally-src="https://tally.so/embed/n0LEd0?hideTitle=1&transparentBackground=1" loading="lazy" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0" title="¡Bienvenido/a a Typed!👋"></iframe> */}

                        {/* <iframe data-tally-src="https://tally.so/embed/n0LEd0?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="323" frameborder="0" marginheight="0" marginwidth="0" title="¡Bienvenido/a a Typed!👋"></iframe> */}


                    </Box>

                </div>

            </div >
        </>

    );
};

export default SplitScreenComponent;
