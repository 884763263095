import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
    ButtonGroup,
    IconButton,
} from "@material-ui/core";

import React, { useState, useEffect, useRef } from "react";

import { useSearchParams, useParams } from "react-router-dom";
import { getCsrfToken } from '../Api/TokenApi'

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import List from '@editorjs/list';
import CheckList from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import Code from '@editorjs/code';
import Embed from '@editorjs/embed';
import LinkTool from '@editorjs/link';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import Warning from '@editorjs/warning';
import Table from '@editorjs/table'
import debounce from 'lodash/debounce';
import useChapterStyles from "./Styles/useChapterStyles"
import { useTranslation } from 'react-i18next';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


function Summary({ accessToken, user, setChapterTitle }) {
    const { chapter_id, selectedChapter } = useParams();
    const [hasData, setHasData] = useState(true);
    const { t } = useTranslation(); // Add this line to use the translation hook

    const classes = useChapterStyles();

    const editorRef = useRef(null);
    const editorInstance = useRef(null);

    // Function to send the Editor.js content to the server
    useEffect(() => {
        const adjustEditorHeight = () => {
            const editorElement = document.getElementById('editorjs');
            if (editorElement) {
                const viewportHeight = window.innerHeight;
                const editorOffsetTop = editorElement.getBoundingClientRect().top;
                const distanceToBottom = 20; // Adjust based on desired padding from the bottom of the viewport
                const editorHeight = viewportHeight - editorOffsetTop - distanceToBottom;

                editorElement.style.height = `${editorHeight}px`;
            }
        };

        // Call once to set initial height
        adjustEditorHeight();

        // Add event listener to adjust height on window resize
        window.addEventListener('resize', adjustEditorHeight);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', adjustEditorHeight);
    }, [hasData]); // Empty dependency array ensures this effect runs only once on mount

    const saveData = async (content) => {
        try {
            const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests

            const response = await fetch(`${BACKEND_URL}/api_v1/chapter/${chapter_id}/?user_id=${user['sub']}`, {
                method: 'PUT', // Use PUT to update the content
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-CSRFToken': csrfToken,

                },
                body: JSON.stringify({ "summary": content }), // Make sure to stringify your content
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Assuming you want to log or do something with the response
            const jsonResponse = await response.json();
        } catch (error) {
            console.error('Error saving data:', error);
        }

    };
    const handleRefresh = async () => {
        const summaryData = fetchData();


        if (summaryData) {
            setHasData(true)
            reinitializeEditor(); // Reinitialize editor with new data

        }
    };

    const fetchData = async () => {
        if (!accessToken) {
            // Redirect to login or show an appropriate message/alert
            return;
        }
        try {
            const response = await fetch(`${BACKEND_URL}/api_v1/chapter/${chapter_id}/?is_detail=true&user_id=${user['sub']}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${accessToken}` }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonResponse = await response.json();
            setChapterTitle(jsonResponse.title)
            if (jsonResponse.summary === null) {
                setHasData(false); // No data available
                return null;
            } else {
                setHasData(true); // Data is available
                return jsonResponse.summary;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasData(false); // Assume no data if there's an error

            return null; // Return null or a default structure if the fetch fails
        }
    };
    // Add a function to reinitialize the Editor
    const reinitializeEditor = async () => {
        if (editorInstance.current) {
            editorInstance.current.destroy();
            editorInstance.current = null;
        }
        initEditor(); // Assuming initEditor is your function that initializes the EditorJS
    };

    const initEditor = async () => {
        // Wait for the data to be fetched

        const fetchedData = await fetchData();

        if (editorRef.current) {
            // Only initialize the editor if the editorInstance hasn't been initialized yet
            // and the fetchedData is available
            if (!editorInstance.current && fetchedData) {
                editorInstance.current = new EditorJS({
                    holder: editorRef.current,
                    data: fetchedData, // Use the fetched data directly
                    tools: {
                        header: {
                            class: Header,
                            inlineToolbar: true, // Enable inline toolbar for the header
                        },
                        image: SimpleImage,
                        list: {
                            class: List,
                            inlineToolbar: true, // Enable inline toolbar for the list
                        },
                        checklist: CheckList,
                        quote: {
                            class: Quote,
                            inlineToolbar: true, // Enable inline toolbar for quotes if needed
                        },
                        marker: Marker,
                        code: Code,
                        embed: Embed,
                        linkTool: LinkTool,
                        delimiter: Delimiter,
                        inlineCode: InlineCode,
                        warning: Warning,
                        table: Table,
                        // Add inlineToolbar: true to other tools as needed
                    },
                    readOnly: false, // Ensure Editor is not in read-only mode
                    onChange: debounce(async () => {
                        const outputData = await editorInstance.current.save();
                        saveData(outputData);
                    }, 1000),
                });

            }
        }
    };
    // Initialize Editor.js on component mount
    useEffect(() => {
        // Declare async function inside the useEffect


        initEditor();

        return () => {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, [chapter_id, accessToken]); // Dependency array to re-run the effect if folder_id changes


    return (
        <Grid
            container
            style={{ display: "flex", flexDirection: "column", height: "calc(100% - 40px)", width: "100%" }}
        >
            <Grid container className={classes.grid} style={{ flex: 1 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    {!hasData ? (
                        <div style={{ textAlign: 'center' }}> {/* This div centers its children */}
                            <Typography>{t("summary_ready")}</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleRefresh}
                                style={{ marginTop: '10px', textTransform: 'none' }} // Remove capitalization and add margin
                            >
                                Refrescar resumen {/* Button text in lowercase */}
                            </Button>
                        </div>

                    ) : (
                        <div className={classes.editorContainer}>
                            <Typography id="editorjs" ref={editorRef} className={classes.markdownEditorWrapper}></Typography>
                        </div>
                    )}
                    {/* <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={handleDownloadPDF}>Download as PDF</Button>
        <Button variant="contained" color="secondary" onClick={handleShare} style={{ marginTop: '10px' }}>Share</Button>
        <Button variant="contained" onClick={handleDelete} style={{ marginTop: '10px' }}>Delete</Button>
      </div> */}
                </Grid>
            </Grid>
        </Grid>);
}; export default Summary;