
import { makeStyles } from '@material-ui/core/styles';

const useWelcomeStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        width: "100%",
        [theme.breakpoints.down('700')]: {
            display: "none"
        },
    },
    typedIconImg: {
        position: "absolute",
        top: 10,
        left: 10

    },
    bannerText: {
        color: "#026277",
        fontSize: "20px",
        marginTop: "20px",
        fontWeight: 900,
        lineHeight: "120%",
        color: "#6BBDBD",
        margin: "20px"
    },

    bannerImage: {
        maxWidth: '100%',
        maxHeight: '70%',
    },
    fullScreenBanner: {
        [theme.breakpoints.up('700')]: {
            display: "none"
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        backgroundColor: "white",
        textAlign: 'center',
        position: 'relative',
    },
    imageContainer: {
        width: '50%', // Set a fixed width for the left column
        backgroundImage: 'url(https://images.unsplash.com/photo-1449247709967-d4461a6a6103?auto=format&fit=crop&q=80&w=2671&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#D9D9D9',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',  // Add flexbox display
        justifyContent: 'center', // Center children horizontally
        alignItems: 'center', // Center children vertically
    },

    textContainer: {
        flex: 1, // Let the right column take the remaining space
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    text: {
        fontSize: '24px',
        fontWeight: 'bold',
    },
    customButton: {
        color: "black !important",
        width: "500px !important",
        height: "68px !important",
        marginBottom: "10px !important",
        backgroundColor: "white !important",
        border: "none !important",
        borderRadius: "5px !important",
        display: "flex !important",
        justifyContent: "left !important",
        paddingLeft: "40px !important",
        alignItems: "center !important",
        boxShadow: " 0px 2px 14px 1px rgba(0, 0, 0, 0.06) !important",
        cursor: "pointer !important",
        transition: "0.3s !important",
        outline: "none !important",
        textDecoration: "none !important",
        fontSize: "22px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        lineHeight: "40px !important",
        letterSpacing: "-0.187px !important",
        textTransform: "none !important",
        border: "3px solid #026277 !important",
        borderRadius: "6px !important",
        '&:hover': {
            border: "3px solid #026277 !important",
            borderRadius: "6px !important",


            backgroundColor: "rgba(2, 98, 119, 0.20) !important", // This is the hover effect, adjust as needed
            boxShadow: "0px 4px 14px 1px rgba(0, 0, 0, 0.04) !important"
        }
    },
    button: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    }
}


));

export default useWelcomeStyle;