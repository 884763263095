import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useParams } from "react-router-dom";
import Spinner from "../NewComponents/Spinner";
import Transcript from "./Transcript";
import PDFViewerComponent from "./Viewers/PdfViewer";
import CSVViewerFile from "./Viewers/CSVViewer";
import { fetchExamId, fetchExamsbyDocumentId, fetchFolderExams } from "../Api/ExamsApi";
import ExamList from "../NewComponents/ListExams";
import Summary from "./Summary";

const useStyles = makeStyles((theme) => ({
    csvViewerContainer: {
        maxWidth: "800px", /* Maximum width */
        marginLeft: "auto", /* Auto margin for center alignment */
        marginRight: "auto", /* Auto margin for center alignment */
        /* Add any other styles you need */
    },
    containerSelectors: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f5f7fa',
        borderRadius: '10px',
        padding: '3px',
        gap: "3px",
        width: "fit-content",
        marginBottom: '15px',
        marginTop: '10px',
        paddingRight: "5px",
        margin: "0 auto"
    },
    button: {
        borderRadius: "10px",
        fontSize: "10px",
        textTransform: "none",
        // '&:first-of-type': {
        //     borderTopLeftRadius: '5px',
        //     borderBottomLeftRadius: '5px',
        // },
        // '&:last-of-type': {
        //     borderTopRightRadius: '5px',
        //     borderBottomRightRadius: '5px',
        // },
    },
    selectedButton: {
        backgroundColor: 'white',
        color: '#000',
        border: 'none',
    },
    unselectedButton: {
        backgroundColor: 'transparent',
        color: '#000',
        border: 'none',
    },
    buttonUpload: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",

        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        },
        padding: "4px 11px !important"
    },
    grid: {
        flex: 1,
    },
}));

function Document({ docInfo, setSummary, accessToken, user, setMessage, setShowAlert, summary, handleCreateExam, listExams, setListExams, isGeneratingSummary, setIsGeneratingSummary, shouldCreateSummary }) {
    const { document_id, selectedTranscript } = useParams();
    const { folder_id, selectedFolder } = useParams();
    // const [listExams, setListExams] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation(); // Use useLocation to get the current location

    const [selected, setSelected] = useState(shouldCreateSummary ? 'Summary' : 'Summary');

    const handleButtonClick = (value) => {
        setSelected(value);
    };

    const renderContentBasedOnType = () => {
        if (!docInfo) {
            return (<div> <Spinner left="40%" /></div>); // Or any other placeholder
        }

        const type = docInfo.type_dc;
        if (type === ".pdf") {
            return <PDFViewerComponent docInfo={docInfo} token={accessToken} />;
        } else if (type === ".png" || type === ".jpg") {
            return <div>Image</div>;
        } else if (type === ".csv" || type === ".xlsx") {
            return <CSVViewerFile docInfo={docInfo} />;
        } else if (type === ".mp4" || type === ".mp3") {
            return <Transcript docInfo={docInfo} accessToken={accessToken} user={user} />;
        } else {
            return <div>Unknown File Type</div>;
        }
    };

    const handleItemClick = (guid, isActive) => {
        if (!isActive) {
            // You can add any action here if needed when the item is not active/loading
            setMessage(t("exam_creating"))
            setShowAlert(true)
            return; // Early return to prevent action
        }
        // Redirect if the item is active
        // navigate(`exam/${guid}`);
        const basePath = location.pathname.split('/')[1]; // This gets the first segment after the root
        const newPath = `/${basePath}/exam/${guid}`;

        // Redirect if the item is active
        navigate(newPath);
    };
    const handleRemoveExam = async (exam_id) => {
        // Immediately filter out the exam optimistically

        try {
            setListExams(currentExams => {
                // Convert the object to an array of entries, filter out the exam, and convert back to an object
                const filteredEntries = Object.entries(currentExams).filter(([guid, examDetails]) => guid !== exam_id);
                return Object.fromEntries(filteredEntries);
            });      // Perform the deletion request
            await fetchExamId(accessToken, "DELETE", exam_id, user);
            // No need to do anything here on success, as we've already updated the UI

        } catch (error) {
            console.error("this error", error);
            // Handle error - e.g., show an alert or undo the optimistic update
            setMessage("Error removing exam. Please try again.");
            setShowAlert(true);
            // Optionally, fetch the exams again or revert the change in the state
        }
    };
    const renderContent = () => {
        switch (selected) {
            case 'Document':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {renderContentBasedOnType()}
                        </Grid>
                    </Grid>
                );
            case 'Summary':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {accessToken && <Summary setSummary={setSummary} docInfo={docInfo} accessToken={accessToken} user={user} summary={summary} isGeneratingSummary={isGeneratingSummary} setIsGeneratingSummary={setIsGeneratingSummary} />}
                        </Grid>
                    </Grid>
                );
            case 'Exams':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {/* {listExams && (

                                <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />
                            )} */}

                            {
                                listExams && Object.keys(listExams).length > 0 ? (

                                    <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />


                                ) : (<>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: "50px",
                                        height: '100%',
                                    }}>
                                        <img src={require("./img/emptyExams.svg").default} alt="Preview Exam" />
                                        <Typography style={{ fontSize: "12px", marginBottom: "10px" }}>{t("exams_dont_exist")}</Typography>
                                        <Button className={classes.buttonUpload} onClick={handleCreateExam}>{t("create_exam")}</Button>

                                    </div>
                                </>
                                )
                            }

                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    // async function fetchExams() {
    //     if (accessToken && user) {

    //         try {

    //             const listExamsFetched = await fetchExamsbyDocumentId(accessToken, "GET", docInfo.folder, docInfo.guid, user,);
    //             setListExams(listExamsFetched)
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }

    // }
    // useEffect(() => {
    //     fetchExams()
    // }, [docInfo]);

    return (
        <>
            <Grid
                container
                style={{ display: "flex", flexDirection: "column", height: "90vh", backgroundColor: "white", width: "100%", flexWrap: "unset" }}
            >
                <Box className={classes.containerSelectors}>
                    <Button
                        className={`${classes.button} ${selected === 'Summary' ? classes.selectedButton : classes.unselectedButton}`}
                        onClick={() => handleButtonClick('Summary')}
                    >
                        {t("summary")}
                    </Button>
                    <Button
                        className={`${classes.button} ${selected === 'Document' ? classes.selectedButton : classes.unselectedButton}`}
                        onClick={() => handleButtonClick('Document')}
                    >
                        {t("document")}
                    </Button>
                    <Button
                        className={`${classes.button} ${selected === 'Exams' ? classes.selectedButton : classes.unselectedButton}`}
                        onClick={() => handleButtonClick('Exams')}
                    >
                        {t("exams")}
                    </Button>
                </Box>

                {renderContent()}

            </Grid>
        </>
    );
}

export default Document;
