import React, { useState } from 'react';
import { Typography, Link, TextField, Button } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import pencil from '../../Icons/svg_pencil.svg'
import MultiStageModal from "../../ExamModal/ModalParent"
import { useTranslation } from 'react-i18next';

const GradientButton = styled(Button)(({ theme }) => ({
    background: 'white', // Set the default background color
    color: 'transparent', // Make text transparent
    border: '3px solid transparent', // Define a transparent border
    borderRadius: 4,
    padding: '12px 15px',
    // Apply gradient to border using border-image property
    borderImageSlice: 1,
    borderImageSource: 'linear-gradient(to right, #3EA9D7, #00B564)',
    // Create a gradient text effect
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundImage: 'linear-gradient(to right, #3EA9D7, #00B564)',
    textAlign: 'center', // Center the text inside the button
    transition: 'all 0.3s', // Smooth transition for hover effect
    textTransform: "None",
    '&:hover': {
        borderImageSource: 'linear-gradient(to right, #00B564, #3EA9D7)',
    },
}));


function ExamButtonSVG({ listDocuments, user, token, addNewExam, setShowUpgrade, setMessage, setShowAlert }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation(); // Add this line to use the translation hook

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div style={{ margin: '1em' }}>
            {isModalOpen && <MultiStageModal open={isModalOpen} onClose={handleCloseModal} listDocuments={listDocuments} user={user} token={token} addNewExam={addNewExam} setShowUpgrade={setShowUpgrade} setMessage={setMessage} setShowAlert={setShowAlert} />}
            <GradientButton onClick={handleOpenModal}>
                <img src={pencil} />
                <Typography style={{ fontWeight: 600, fontSize: 12 }}>{t("generate")}</Typography>
            </GradientButton>

        </div>
    );
}

export default ExamButtonSVG;
