import {
    Button,
    Grid,
    Paper,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,

} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { fetchSummaryChapterid } from '../Api/ChaptersApi';
import ModalFolder from "../Folders/ModalFolders";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PotatoIcon from "@mui/icons-material/EmojiNature"; // Use a potato icon
import { useNavigate } from 'react-router-dom';

import { useParams, useLocation } from "react-router-dom";
import { fetchChaptersDocument } from '../Api/ChaptersApi'; // Import the fetchUserInfo function
import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function
import Document from "../Documents/Document";
import FolderContent from "../Folders/FolderContent"; // Adjust the path based on your folder structure
import BreadCrumb from "../NewComponents/BreadCrumb"; // Adjust the path based on your folder structure
import Chat from "../NewComponents/Chat";
import * as handle from "./handle";
import { Container } from "@material-ui/core"
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useTranslation } from 'react-i18next';
import Summary from "../Documents/SummaryChapters";
import { WebSocketContext } from '../WebSocketProvider';
import CustomList from "./CustomList";
import useDocumentStyles from "./Styles/useDocumentStyles";
import UploadButton from "../NewComponents/SubirApuntesButton";
import ModalParent from "../AllModals/ParentModal";
import { fetchExamsbyDocumentId, fetchFolderExams } from "../Api/ExamsApi";
import FullScreenLoader from "../NewComponents/LoadingExam";
import DropdownMenu from "../NewComponents/DropDownComponent";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



function DocumentStructure({ active_folder }) {
    const location = useLocation();

    const { state } = location;

    const shouldCreateExam = state?.shouldCreateExam || false;
    const shouldCreateSummary = state?.shouldCreateSummary || false;

    const { accessToken, user } = useAuth(); // Use the hook at the top level
    const [listDocuments, setListDocuments] = useState([]);
    const [listExams, setListExams] = useState([]); // Define setListExams state

    const [messages, setMessages] = useState([]); // Move messages state up
    const [isVideo, setIsVideo] = useState(true);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [valueVideo, setValueVideo] = useState("Ver video");
    const [videoUrl, setVideoUrl] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [titleOption, setTitle] = useState("");
    const [descriptionOption, setDescription] = useState("");
    const [buttonOption, setButton] = useState("");
    const [placeholderMessage, setPlaceholderMessage] = useState("");
    const [typeModal, setTypeModal] = useState("");
    const [openMenu, setOpenMenu] = useState(false);
    // Folder info 
    const [folder, setFolder] = useState(null)
    // Single Doc info
    const [docInfo, setDocInfo] = useState(null)

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");

    const { t } = useTranslation();

    const { chapter_id, selectedChapter } = useParams();

    const { document_id, selectedDocument } = useParams();
    const initialIsDocument = document_id ? true : false;

    const { folder_id, selectedFolder } = useParams();
    const [summary, setSummary] = useState("")
    // For upload file pop up
    // Assuming modalOpenUpload is a boolean to control the visibility of a modal
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [titleOptionUpload, setTitleOptionUpload] = useState('');
    const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
    const [buttonOptionUpload, setButtonOptionUpload] = useState('');
    const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
    const [typeUpload, setTypeUpload] = useState('');
    const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [chaptersTitle, setChaptersTitle] = useState([]);
    const [chapterTitle, setChapterTitle] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});
    const [width, setWidthModal] = useState("");
    const [isDocument, setIsDocument] = useState(true);
    const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

    const classes = useDocumentStyles(isDocument);


    const handleUploadFile = () => {
        handle.handleUploadFile(
            t,
            setModalOpenUpload,
            setTitleOptionUpload,
            setDescriptionOptionUpload,
            setButtonOptionUpload,
            setPlaceholderMessageUpload,
            setTypeUpload,
            setWidthModal
        );
    };
    const handleCreateExam = () => {
        handle.handleCreateExam(
            t,
            setModalOpenUpload,
            setTitleOptionUpload,
            setDescriptionOptionUpload,
            setButtonOptionUpload,
            setPlaceholderMessageUpload,
            setTypeUpload,
            setWidthModal,

        );
    };
    useEffect(() => {
        async function fetchData() {
            if (accessToken && user) {
                handle.fetchData(accessToken, setFolder, folder_id, user); // Call the fetchData function
                handle.fetchDocuments(accessToken, setListDocuments, folder_id, user);
            }
        }


        fetchData();
    }, [accessToken, user]);
    useEffect(() => {
        fetchExams()
    }, [accessToken, user, folder])
    const fetchChapters = async () => {
        if (!accessToken || !user) return; // Check if accessToken and user are available
        try {
            const chaptersDocument = await fetchChaptersDocument(accessToken, "GET", document_id, user);
            setChaptersTitle(chaptersDocument);
        } catch (error) {
            console.error(error);
            // Optionally, set an error message to display to the user
        }
    };

    // const generateAllSummaries = async () => {
    //   setAllChaptersLoading();  // Set all to loading before starting

    //   for (const chapter of chaptersTitle) {
    //     await generateSummary(chapter.guid);
    //   }
    // };

    useEffect(() => {

        if (shouldCreateExam) {
            handleCreateExam()
        }
    }, [shouldCreateExam])


    useEffect(() => {
        const fetchDecryptedVideoUrl = async (url_stored) => {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
                    method: 'POST',
                    body: JSON.stringify({ fileUrl: url_stored }),
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` }
                });
                if (response.ok) {
                    const blob = await response.blob(); // Handle binary data for video
                    const url = URL.createObjectURL(blob); // Create a URL for the blob object
                    setVideoUrl(url);
                } else {
                    console.error('Failed to fetch video URL');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        async function fetchData() {
            if (accessToken && user) {
                try {

                    const docSeri = await fetchUserFile(accessToken, "GET", folder_id, document_id, null, user);
                    const chaptersDocument = await fetchChaptersDocument(accessToken, "GET", document_id, user);
                    setChaptersTitle(chaptersDocument)
                    setDocInfo(docSeri);

                    setSummary(docSeri.summary)
                    setIsGeneratingSummary(docSeri.is_creating_summary)
                    if (docSeri.type_dc === '.mp4' || docSeri.type_dc === '.mp3') {
                        setIsVideo(true)
                        fetchDecryptedVideoUrl(docSeri.url_stored);

                    } else {
                        setIsVideo(false)
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }

        if (document_id) {

            fetchData(); // Call the fetchData function
        }

    }, [document_id, accessToken, user])

    const fetchExams = async () => {
        if (accessToken && user && folder) {
            try {
                const listExamsFetched = await fetchExamsbyDocumentId(accessToken, "GET", folder.guid, document_id, user,);

                // const listExamsFetched = await fetchFolderExams(accessToken, "GET", folder.guid, user);
                setListExams(listExamsFetched);
            } catch (error) {
                console.error(error);
            }
        }
    };
    const updateListDocuments = async () => {

        handle.fetchDocuments(accessToken, setListDocuments, folder_id, user);

    };





    // const shouldShowGenerateButton = useMemo(() => {
    //   // Check if any chapter does not have a summary generated
    //   return chaptersTitle.some(chapter => !chapter.is_active);
    // }, [chaptersTitle]);  // Recompute when chaptersTitle changes

    // const setAllChaptersLoading = () => {
    //   const newLoadingStates = chaptersTitle.reduce((acc, chapter) => {
    //     acc[chapter.guid] = true;  // Set loading to true for each chapter
    //     return acc;
    //   }, {});
    //   setLoadingStates(newLoadingStates);
    // };


    const [typeAlert, setTypeAlert] = useState("");
    const [linkinMessage, setLinkinMessage] = useState("");
    const [isPreparingExam, setIsPreparingExam] = useState(false);


    const backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
    const url = "";
    const { messageCallback } = useContext(WebSocketContext);
    useEffect(() => {
        if (messageCallback) {
            switch (messageCallback.type) {
                case "summary":

                    setSummary(messageCallback.summary);
                    break;
                case "documentsUpdate":
                    setShowAlert(false);
                    setShowAlertUpload(false)
                    setShowSpinnerUpload(false)
                    setListDocuments(prevDocuments => [...prevDocuments, messageCallback.document]);
                    break;
                case "Exam":

                    fetchExams()
                    break;
                default:
                    console.log("Unhandled message type:", messageCallback.document);
            }
        }
    }, [messageCallback]);
    // const handleWebSocketMessage = useCallback((message) => {
    //   console.log('Received chapters:', message);
    //   switch (message.type) {
    //     case "chapterUpdate":
    //       setChaptersTitle(message.chapters);
    //       break;

    //     default:
    //       console.log("Unhandled message type:", message);
    //   }
    // }, []);
    // const [sendMessage] = useWebSocketComponent(url, handleWebSocketMessage);
    const handleExamCreated = () => {
        fetchExams();
    };
    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + '...';
    };

    useEffect(() => {
        if (isPreparingExam) {
            const timer = setTimeout(() => {
                setIsPreparingExam(false);
            }, 4000);
            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
        }
    }, [isPreparingExam]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option) => {
        setAnchorEl(null);
        if (option) {
            alert(`You selected ${option}`);
        }
    };
    const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
    const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
    const navigate = useNavigate();

    return (
        <>
            {showAlert &&
                <SmallAlert
                    message={message}
                    progressBar={false}
                    onClose={() => setShowAlert(false)}
                    linkinMessage={linkinMessage}
                    typeAlert={typeAlert}
                />
            }
            {showAlertUpload &&
                <SmallAlert
                    message={messageUpload}
                    progressBar={progressBar}
                    onClose={() => setShowAlertUpload(false)}
                />
            }

            {
                <NeedCredits open={showUpgrade} message={message}

                    onClose={() => setShowUpgrade(false)} />
            }

            {isPreparingExam && <FullScreenLoader
                imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
                title={t('fullScreenLoader.examPreparingTitle')}
                message={t('fullScreenLoader.examPreparingMessage')}
                buttonText={t('fullScreenLoader.examPreparingButton')}
                onClose={() => { setIsPreparingExam(false); }}
                onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
            />}


            {/* <Grid

                spacing={3}
                className={classes.gridContainer}
            > */}
            <Container style={{
                display: "flex", flexDirection: "row", gap: "50px", maxHeight: "100%", backgroundColor: "#F8FFFF", maxWidth: "none"

            }}>
                <Grid
                    container
                    spacing={0}

                    className={classes.leftPart}

                >


                    <Paper elevation={3} className={classes.paperLeft} >
                        <div style={{
                            position: 'relative', justifyContent: "space-between", display: "flex",
                        }}>
                            {/* <BreadCrumb className={classes.breadcrumbContainer} root={t("home")} docInfo={docInfo} folderInfo={folder} accessToken={accessToken} chapterTitle={chapterTitle} /> */}
                            {!docInfo ? (
                                <Typography style={{
                                    color: "#026277",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    letterSpacing: "-0.225px",
                                    paddingLeft: 2
                                }}>Document</Typography>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                                    <div>
                                        <Typography style={{
                                            color: "#026277",
                                            fontSize: "20px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            letterSpacing: "-0.225px",
                                            paddingLeft: 2
                                        }}>{truncateText(docInfo.title, 20)}</Typography></div>
                                    <div>
                                        {/* <Button className={classes.buttonUpload} onClick={handleCreateExam}>{t("create_exam")}</Button></div> */}
                                        <DropdownMenu option2={handleCreateExam} />
                                    </div>
                                </div>

                            )}
                            {folder !== null && active_folder && (
                                <div>
                                    {/* <UploadButton onClick={handleUploadFile} endIcon={<ArrowDropDownIcon />}
                                    /> */}
                                </div>
                            )}




                        </div>


                        <Document isGeneratingSummary={isGeneratingSummary} setIsGeneratingSummary={setIsGeneratingSummary} docInfo={docInfo} summary={summary} setSummary={setSummary} accessToken={accessToken} user={user} setMessage={setMessage} setShowAlert={setShowAlert} handleCreateExam={handleCreateExam} listExams={listExams} setListExams={setListExams} shouldCreateSummary={shouldCreateSummary} />


                    </Paper>

                </Grid>

                <Grid
                    item
                    className={classes.rightPart}


                >



                    <Chat
                        height={"100%"}
                        messages={messages}
                        setMessages={setMessages}
                        accessToken={accessToken} // Passing accessToken as a prop
                        user={user}
                        isPdf={true}


                    />



                </Grid>

                {/* </Grid > */}
            </Container >


            {modalOpen && (
                <ModalFolder
                    open={true}
                    title={titleOption}
                    description={descriptionOption}
                    sendButton={buttonOption}
                    placeholdMessage={placeholderMessage}
                    type={typeModal}
                    onClose={() => {
                        setModalOpen(false); setOpenMenu(false); // Refresh folders list
                    }}
                    folder_id={folder.guid}
                />
            )
            }
            {
                modalOpenUpload && (
                    <ModalParent
                        open={modalOpenUpload}
                        title={titleOptionUpload}
                        description={descriptionOptionUpload}
                        sendButton={buttonOptionUpload}
                        placeholdMessage={placeholderMessageUpload}
                        type={typeUpload}
                        onClose={() => setModalOpenUpload(false)}
                        setShowSpinner={setShowSpinnerUpload}
                        setShowAlert={setShowAlertUpload}
                        setMessage={setMessageUpload}
                        setProgressBar={setProgressBar}
                        setShowUpgrade={setShowUpgrade}
                        width={width}
                        onExamCreated={handleExamCreated} // Pass the callback to handle exam creation
                        updateListDocuments={updateListDocuments}
                        setIsPreparingExam={setIsPreparingExam}
                        setFolderSelectedGuid={setFolderSelectedGuid}
                        setDocumentSelectedGuid={setDocumentSelectedGuid}


                    />
                )
            }

        </>
    );
}

export default DocumentStructure;
