import {
    Button,
    Card,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";

import { Container, TextField, } from "@material-ui/core"
import { Box, Slider } from '@mui/material';

import { useAuth } from "../Auth/Authhandle";
import { useCredits } from '../Context/CreditsContext';

import { useTranslation } from 'react-i18next';
import useChooseFileStyles from "./styles/useStylesChooseFile"
import LoadDocuments from "./LoadDocuments";
import { createMemorise } from "./Api/handle";
import { styled } from '@mui/system';
import posthog from 'posthog-js'
import { fetchUserFiles } from "../Api/FilesApi";
import VideoDialog from "../Video/TutorialComponent";
import { useNavigation } from "../NavigationContext";
const MEMORISE_CREDITS = 20
const CustomSlider = styled(Slider)({
    color: '#6BBDBD',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#6BBDBD',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#E2F1F1',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#E2F1F1',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
});
function ChooseFiles({ setOpenGeneration
    , nextStep, setFolderChosen, setFileChosen, accessToken, user, setInitPage, setFinalPage, finalPage, initPage, setShowUpgrade }) {


    const { t } = useTranslation();

    const classes = useChooseFileStyles()
    const { updateCredits, credits } = useCredits();
    const { previousPath, currentPath } = useNavigation();

    const [error, setError] = useState(false);
    const [firstOption, setFirstOption] = useState('');
    const [secondOption, setSecondOption] = useState('');
    const [listFolders, setListFolders] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [files, setFiles] = useState([]);
    const [value, setValue] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const [newFolderName, setNewFolderName] = useState('');
    const [possibleToSend, setPossibleToSend] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openVideo, setOpenVideo] = useState("");
    const [totalPages, setTotalPages] = useState("")
    useEffect(() => {
        // Check if the user navigated from '/welcome' to another page
        if (previousPath === '/onboarding' && currentPath !== '/onboarding') {
            const timer = setTimeout(() => {
                setOpenVideo(true);
            }, 500); // Delay of 3000 milliseconds

            return () => clearTimeout(timer);
        }
    }, [previousPath, currentPath]);
    const handleStartPageChange = (event) => {

        setInitPage(event.target.value);

        validatePages(event.target.value, finalPage);
    };

    function isValidNumber(value) {
        if (value === '' || isNaN(value) || value === null) {

            return false
        } else {
            return true
        }
    }

    const handleEndPageChange = (event) => {
        setFinalPage(event.target.value);
        validatePages(initPage, event.target.value);
    };
    const validatePages = (start, end) => {
        const startPageNum = parseInt(start, 10);
        const endPageNum = parseInt(end, 10);

        if (isNaN(startPageNum) || isNaN(endPageNum)) {
            setError(true);
            setErrorMessage("Los números de página deben ser números válidos.");
        } else if (startPageNum === 0) {
            setError(true);
            setErrorMessage("El número de la página de inicio no puede ser 0.");
        } else if (startPageNum > endPageNum) {
            setError(true);
            setErrorMessage("El número de la página de inicio debe ser menor que el número de la página final.");
        } else if (endPageNum - startPageNum > 20) {
            setError(true);
            setErrorMessage("El rango entre los números de página de inicio y final no puede ser mayor que 20.");
        } else if (endPageNum > totalPages) {
            setFinalPage(totalPages); // Adjust final page to total pages
            if (startPageNum > totalPages) {
                setInitPage(totalPages); // Adjust initial page to total pages
            }
            setError(false);
            setErrorMessage("");
        }
        else {
            setError(false);
            setErrorMessage("");
        }
    };
    const handleKeyPress = (event) => {
        // Allow only numbers
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };
    const prepareFormData = async (files) => {


        const formData = new FormData();

        if (files && files.length > 0) {
            posthog.capture('upload_file_memorise');

            const filesToUpload = files.map((fileItem) => fileItem.file); // Extracting File objects

            // Function to load metadata for a single file and append to FormData
            const loadMetadata = (file, index) => {
                return new Promise((resolve) => {
                    formData.append(`file_${index}`, file);
                    formData.append(`file_${index}_size`, file.size);
                    formData.append(`file_${index}_duration`, 0);
                    resolve();
                });
            };

            const metadataPromises = filesToUpload.map((file, index) => loadMetadata(file, index));
            await Promise.all(metadataPromises);
        }

        return formData;
    };
    const handleSubmit = async () => {
        if (credits < 20) {
            setShowUpgrade(true)
        } else {
            if (firstOption != "" && secondOption != "") {

                setFolderChosen(firstOption)
                setFileChosen(secondOption)
                await createMemorise(accessToken, { "file_id": secondOption.guid, "type_post": "generate", "initPage": initPage, "finalPage": finalPage, "prediction": sliderValue }, user);
                await updateCredits()
                nextStep()
            } else {
                if (files.length > 0 && (newFolderName || value)) {
                    let folderId = "";

                    if (newFolderName) {
                        folderId = newFolderName;
                    } else {
                        folderId = typeof value === 'string' ? value : value.guid;
                    }

                    const formData = await prepareFormData(files);
                    let documentId = null
                    setOpenGeneration(true);  // Show the modal immediately

                    await fetchUserFiles(accessToken, "POST", folderId, formData, user)
                        .then((data) => {
                            const key = Object.keys(data['message'])[0]

                            documentId = data['message'][key][0]; // Ensure `key` is defined or appropriately replaced
                        })
                        .catch((error) => {
                            console.error("Error uploading file:", error);
                            alert("Ha habido un error subiendo el documento");
                        });

                    if (!documentId) {
                        alert("Ha habido un error subiendo el documento");
                    } else {
                        await createMemorise(
                            accessToken,
                            {
                                "file_id": documentId,
                                "type_post": "generate",
                                "initPage": initPage,
                                "finalPage": finalPage,
                                "prediction": sliderValue,
                            },
                            user,
                        );
                        await updateCredits()

                    }
                    setOpenGeneration(false);  // Show the modal immediately

                    nextStep()
                }

            };
        }
    }
    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);

    };
    const handleClickOpen = () => {
        setOpenVideo(true);
    };

    // Handle closing the dialog
    const handleCloseVideo = () => {
        setOpenVideo(false);
    };

    return (
        <>


            {/* <Grid

                spacing={3}
                className={classes.gridContainer}
            > */}
            <Container style={{
                display: "flex", flexDirection: "row", gap: "50px", backgroundColor: "#F8FFFF", maxWidth: "none", height: "100vh", overflowY: "hidden"

            }}>
                <Grid
                    container
                    spacing={0}

                    className={classes.leftPart}

                >



                    <Paper elevation={3} className={classes.paperLeft} >
                        <div style={{
                            display: "flex",
                            position: "relative",
                            alignContent: "flex-end",
                            justifyContent: "flex-end"
                        }}>
                            <Button className={classes.buttonEmpezar} disabled={!possibleToSend || error || !isValidNumber(initPage) || !isValidNumber(finalPage)} onClick={handleSubmit}>{t("start")}</Button>
                        </div>
                        <div style={{
                            position: 'relative', justifyContent: "space-between", display: "flex", flexDirection: "column"
                        }}>
                            <Typography className={classes.title}>{t("study_session")}</Typography>
                            <Typography className={classes.subtitle}>{t("memorise_subtitle")}</Typography>
                            <div>

                            </div>

                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Typography style={{ textAlign: "left", fontSize: 14, fontWeight: "bold", marginBottom: "10px" }} >Elige que documentos quieres seleccionar</Typography>

                            <LoadDocuments
                                translation={t}
                                accessToken={accessToken}
                                user={user}
                                firstOption={firstOption}
                                setFirstOption={setFirstOption}
                                secondOption={secondOption}
                                setSecondOption={setSecondOption}
                                listFolders={listFolders}
                                setListFolders={setListFolders}
                                documents={documents}
                                setDocuments={setDocuments}
                                files={files}
                                setFiles={setFiles}
                                value={value}
                                setValue={setValue}
                                newFolderName={newFolderName}
                                setNewFolderName={setNewFolderName}
                                setPossibleToSend={setPossibleToSend}
                                setTotalPages={setTotalPages}

                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography style={{ textAlign: "left", fontSize: 14, fontWeight: "bold", marginTop: "15px" }} >{t("choose_study")}</Typography>
                            <Typography style={{ textAlign: "left", fontSize: 14, fontWeight: "normal", marginTop: "2px", color: "gray" }} >{t("max_pages_20")}</Typography>
                            <Box display="flex" flexDirection="row" style={{ gap: "20px", marginTop: "10px" }} >
                                <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                                    <Typography style={{ textAlign: "left", fontSize: 14 }}>{t("initial_page")}</Typography>
                                    <TextField

                                        value={initPage}
                                        onChange={handleStartPageChange}
                                        onKeyPress={handleKeyPress}

                                        error={error}
                                        // helperText={error ? 'El rango no puede ser mayor a 20 páginas' : ''}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: '5px', width: '30px', fontSize: 12 } }}
                                        variant="outlined"
                                        size="small"
                                        style={{ marginTop: '5px' }}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                                    <Typography style={{ textAlign: "left", fontSize: 14 }}>{t("final_page")}</Typography>

                                    <TextField
                                        value={finalPage}
                                        onChange={handleEndPageChange}
                                        error={error}
                                        onKeyPress={handleKeyPress}

                                        // helperText={error ? 'El rango no puede ser mayor a 20 páginas' : ''}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: '5px', width: '30px', fontSize: 12 } }}
                                        variant="outlined"
                                        size="small"
                                        style={{ marginTop: '5px' }}
                                    />
                                </div>
                            </Box>
                            {error && (<Typography style={{ color: "red", fontSize: 12 }}>{errorMessage}</Typography>)}
                        </div>

                        <div>
                            <Typography style={{ textAlign: "left", fontSize: 14, fontWeight: "bold", marginTop: "25px" }} >{t("how_much_you_know")}</Typography>
                            <Box display="flex" alignItems="center">

                                <CustomSlider
                                    value={sliderValue}
                                    min={0}
                                    max={100}
                                    step={1}
                                    onChange={handleSliderChange}
                                    valueLabelFormat={(val) => `${val}%`}

                                    style={{ margin: '0 12px', width: '300px', fontSize: "14px" }}
                                />
                                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>{sliderValue}%</Typography>


                            </Box>

                        </div>
                        <div style={{ marginTop: "30px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <div style={{ margin: "0 auto", }}>
                                <Typography style={{ margin: "0 auto", color: "gray", fontSize: "12px", textAlign: "center", marginBottom: "8px" }}>Esta acción te costará {MEMORISE_CREDITS} créditos</Typography>
                            </div>
                            <div style={{ margin: "0 auto", }}>
                                <Button className={classes.buttonEmpezar} disabled={!possibleToSend || error || !isValidNumber(initPage) || !isValidNumber(finalPage)} onClick={handleSubmit}>{t("start")}</Button>
                            </div>
                        </div>

                    </Paper>

                </Grid>

                <Grid
                    item
                    className={classes.rightPart}


                >
                    <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {t("howItWorks")}
                    </Typography>
                    <div>
                        <Typography className={classes.stepsTitle}>
                            {t("step1Title")}
                        </Typography>
                        <Typography className={classes.description}>
                            {t("step1Description")}
                        </Typography>
                    </div>
                    <div>
                        <Typography className={classes.stepsTitle}>
                            {t("step2Title")}
                        </Typography>
                        <Typography className={classes.description}>
                            {t("step2Description")}
                        </Typography>
                    </div>
                    <div>
                        <Typography className={classes.stepsTitle}>
                            {t("step3Title")}
                        </Typography>
                        <Typography className={classes.description}>
                            {t("step3Description")}
                        </Typography>
                    </div>

                    <Box style={{ border: "2px solid #026277", borderRadius: 20, marginTop: "30px" }}>



                        <a onClick={handleClickOpen}
                        >
                            <img
                                style={{ maxWidth: '100%', borderRadius: 20 }}
                                src="https://cdn.loom.com/sessions/thumbnails/b2a481c57c424c14b75332a8ef25dba1-d1070b40d3d0b2d7-full-play.gif"
                                alt="Thumbnail of video"
                            />
                        </a>

                    </Box>

                    <VideoDialog open={openVideo} handleClose={handleCloseVideo} typeVideo="memorise" />



                </Grid>

                {/* </Grid > */}
            </Container >





        </>
    );
}

export default ChooseFiles;
