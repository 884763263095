import React, { createContext, useRef, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const WebSocketContext = createContext();

const WebSocketProvider = ({ children, url, urlchat }) => {
    const ws = useRef(null);
    const wsChat = useRef(null);
    const { getAccessTokenSilently } = useAuth0();

    const [messageCallback, setMessageCallback] = useState(null);
    const [chatMessageCallback, setChatMessageCallback] = useState(null);

    useEffect(() => {
        const connectWebSocket = async () => {
            const accessToken = await getAccessTokenSilently();
            const websocketUrl = `${url}?token=${accessToken}`;
            const websocketUrlChat = `${urlchat}?token=${accessToken}`;

            // General WebSocket
            ws.current = new WebSocket(websocketUrl);
            ws.current.onmessage = event => {
                const message = JSON.parse(event.data);
                setMessageCallback(message);
            };

            ws.current.onerror = error => {
                console.error("WebSocket Error:", error);
            };

            ws.current.onclose = e => {
                console.log('WebSocket is closed. Reconnect will be attempted in 10 seconds.', e.reason);
                setTimeout(connectWebSocket, 10000);
            };

            // Chat WebSocket
            wsChat.current = new WebSocket(websocketUrlChat);
            wsChat.current.onmessage = event => {
                const message = JSON.parse(event.data);
                setChatMessageCallback(message);
            };

            wsChat.current.onerror = error => {
                console.error("Chat WebSocket Error:", error);
            };

            wsChat.current.onclose = e => {
                console.log('Chat WebSocket is closed. Reconnect will be attempted in 10 seconds.', e);
                // setTimeout(connectWebSocket, 10000);
            };
        };
        connectWebSocket();

        return () => {
            if (ws.current) {
                ws.current.close();
            }
            if (wsChat.current) {
                wsChat.current.close();
            }
        };
    }, [getAccessTokenSilently, urlchat]); // Including both URLs in the dependency array

    return (
        <WebSocketContext.Provider value={{ ws, wsChat, messageCallback, setMessageCallback, chatMessageCallback }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketProvider, WebSocketContext };
