
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchFolderMemorise(token, method = "GET", folder_id, user) {
    const user_id = user['sub'];

    const apiUrl = `${BACKEND_URL}/api_v1/memorise/folder/${folder_id}/?user_id=${user_id}`;
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: method,
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching memorises:', error);
        throw error;
    }
}
