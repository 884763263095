export async function handleMultipleFileSelect(files) {
    if (files.length) {
        console.log("");



    }
}


export const handleBeforeUnload = (event, openSpinner, openUploadedSpinner) => {
    if (openSpinner || openUploadedSpinner) {
        event.preventDefault();
        event.returnValue =
            "El archivo aún se está subiendo. ¿Estás seguro de que quieres cerrar la pestaña?"; // Custom message
    }
};


// handle.js
export const openFileInput = (fileInputRef) => {
    fileInputRef.current.click();
};

export const handleDragOver = (event) => {
    event.preventDefault();
};

export const handleDrop = (event, setOpenSpinner, folder, token, user) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
        // Handle dropped files here, e.g., initiate the upload process
        handleMultipleFileSelect(files[0], setOpenSpinner, folder, token, user);
    }
};
