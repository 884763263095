import { makeStyles } from '@material-ui/core/styles';

const useQuestionsStyle = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    textField: {

        '& .MuiInputBase-input': {
            fontSize: '14px', // Customize the font size here
        },

    },
    scoreBox: {
        // backgroundColor: "#6BBDBD",
        width: "150px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        borderRadius: "6px",
        marginTop: "10px"
    },
    hintButton: {
        backgroundColor: "#E2F1F1",
        color: "#6BBDBD",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "106px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#E2F1F1', // Gray color for the disabled state
            color: "#6BBDBD",
            // padding: "8px 14px", // Added padding for better appearance

        }
    },
    goodButton: {
        backgroundColor: "rgba(49, 172, 63, 0.2)",
        color: "rgba(49, 172, 63)",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "106px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",
        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: 'rgba(49, 172, 63, 0.2)', // Gray color for the disabled state
            color: "#rgba(49, 172, 63)",
            // padding: "8px 14px", // Added padding for better appearance

        }
    },
    badButton: {
        backgroundColor: "rgba(195, 12, 12, 0.2)",
        color: "rgba(195, 12, 12)",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "106px",
        margin: "0 auto",
        display: "flex",
        marginBottom: "5px",
        justifyContent: "center",
        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: 'rgba(195, 12, 12, 0.2)', // Gray color for the disabled state
            color: "rgba(195, 12, 12)",
            // padding: "8px 14px", // Added padding for better appearance

        }
    },
    correctQuestion: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "106px",


        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        }
    },

    hintPista: {
        width: "100px",
        color: "#FF8B00",
        backgroundColor: "#FFDAAE",
        marginTop: "20px",
        textAlign: "center",
        borderRadius: "6px",
        marginBottom: "10px",
        padding: 6,
        fontWeight: "bold"

    },
    respuestaCorrecta: {
        width: "150px",
        color: "gray",
        backgroundColor: "lightgray",
        marginTop: "20px",
        padding: 6,
        fontSize: 14,
        textAlign: "center",
        borderRadius: "6px",
        marginBottom: "10px",
    },

}));

export default useQuestionsStyle;
