export const isValid = (email, setError, items) => {
  let error = null;
  if (isInList(email, items)) {
    error = `${email} has already been added.`;
  }

  if (!isEmail(email)) {
    error = `${email} is not a valid email address.`;
  }

  if (error) {
    setError(error);

    return false;
  }

  return true;
};

export const isInList = (email, items) => {
  return items.includes(email);
};

export const isEmail = (email) => {
  return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
};
