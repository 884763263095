import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, TextField, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchUserFile } from '../../Api/FilesApi';
import * as handleDoc from "../../Documents/handleDoc";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 20,
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
        border: "1px solid #E2F1F1",
        cursor: "pointer"
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "10px",
        paddingTop: "20px"
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',

    },
    iconButton: {
        cursor: 'pointer',
        color: '#2D3748',
    },
    content: {
        padding: "10px",

    },
    description: {
        color: '#4A5568',
        fontSize: '14px',
        marginBottom: '8px',
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",

        backgroundColor: '#E2F1F1',
        padding: '6px 10px',
        borderRadius: '0 0 10px 10px',
        textAlign: 'right',
    },
    footerText: {
        fontSize: '12px',
        color: '#026277',
        margin: "auto"
    },
}));

const DocumentsGrid = ({ folder_id, document_id, element, token, user, updateListDocuments }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [isEditing, setIsEditing] = useState(false);
    const [editableTitle, setEditableTitle] = useState("");
    const { t } = useTranslation();

    const handleEditClick = (event) => {
        event.stopPropagation();
        setIsEditing(true);

    };

    useEffect(() => {
        setEditableTitle(element.title)
    }, [element])
    const handleTitleBlur = async () => {
        setIsEditing(false);
        try {
            // Fetch folder name based on the first path segment (folder ID)
            await fetchUserFile(token, "PUT", folder_id, document_id, { "docName": editableTitle }, user);

        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteDocument = async (event) => {
        event.stopPropagation();
        try {
            await handleDoc.deleteDocument(token, document_id, folder_id, user);
            updateListDocuments(document_id);
            handleCloseMenu();
        } catch (error) {
            console.error(error);
        }
    }
    const handleTitleChange = (event) => {
        setEditableTitle(event.target.value);
    };

    const handleClick = (doc_id) => {

        const currentPath = location.pathname;
        const newSegment = doc_id;
        const newPath = `${currentPath}/${newSegment}`;
        navigate(newPath);
    };
    // try {
    //     // Fetch folder name based on the first path segment (folder ID)
    //     await fetchUserFile(token, "PUT", folder_id, document_id, { "docName": newName }, user);

    // } catch (error) {
    //     console.error(error);
    // }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);

    };

    const handleCloseMenu = (event) => {
        event.stopPropagation()

        setAnchorEl(null);
    };

    const truncatedTitle = editableTitle.length > 20 ? `${editableTitle.substring(0, 20)}...` : editableTitle;
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    return (
        <Card className={classes.card} onClick={() => { handleClick(element.guid) }}>
            <Box className={classes.header}>
                {isEditing ? (
                    <TextField
                        value={editableTitle}
                        onChange={handleTitleChange}
                        variant="standard"
                        className={classes.title}
                        onClick={(event) => event.stopPropagation()}
                        onBlur={handleTitleBlur}
                        autoFocus
                        InputProps={{
                            classes: {
                                maxLength: 20,

                                input: classes.titleInput,
                            },
                        }}
                    />
                ) : (
                    <Typography variant="h6" className={classes.title}>
                        {truncatedTitle}
                    </Typography>
                )}
                <svg onClick={handleEditClick} className={classes.iconButton} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 16.0001V20.0001L8 20.0001L18.8686 9.13146L18.8695 9.13061C19.265 8.73516 19.4628 8.53736 19.5369 8.3092C19.6021 8.10835 19.6022 7.89201 19.5369 7.69117C19.4627 7.46284 19.2646 7.26474 18.8686 6.86872L17.1288 5.12892C16.7345 4.7346 16.5369 4.53704 16.3091 4.46301C16.1082 4.39775 15.8919 4.39775 15.691 4.46301C15.463 4.53709 15.2652 4.73488 14.8704 5.12976L14.8686 5.13146L4 16.0001Z" stroke="#026277" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                </svg>
            </Box>
            <CardContent className={classes.content}>
                <Typography className={classes.description}> {element.exam_guids.length} {t("examenes")}</Typography>
            </CardContent>
            <Box className={classes.footer}>
                <Typography className={classes.footerText}>{t("last_attempt")}:  {formatDate(element.date)}</Typography>
                <MoreVertIcon onClick={handleClickMenu} className={classes.iconButton} style={{ float: 'right', color: "#026277" }} />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleDeleteDocument}>Delete</MenuItem>
                    {/* <MenuItem >Share</MenuItem> */}
                </Menu>
            </Box>
        </Card>
    );
};

export default DocumentsGrid;
