import React from 'react';
import { Button, Box } from '@mui/material';

const ShowOptionsInline = ({ options, value, handleChange }) => {
    return (
        <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap" gap={2}>
            {options.map((option) => (
                <Button
                    key={option.value}
                    variant='outlined'
                    onClick={() => handleChange(option.value)} // Pass the option's value directly
                    sx={{
                        textTransform: 'none',
                        color: value === option.value ? "white" : "#026277",
                        backgroundColor: value === option.value ? "#026277" : "#E2F1F1",
                        border: "1px solid",
                        borderColor: value === option.value ? "white" : "#026277",
                        '&:hover': {
                            backgroundColor: "#026277",
                            color: "white",
                            borderColor: "white",
                        },
                        fontSize: "10px",
                        padding: "5px 10px 5px 10px"
                    }}
                >
                    {option.label}
                </Button>
            ))}
        </Box>
    );
};

export default ShowOptionsInline;
