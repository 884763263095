// ProtectedRoute.js
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // if (!isAuthenticated) {
    //     window.location.href = "https://www.typedai.com/waitlist";
    //     return null;
    // }

    if (!isAuthenticated) {

        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;
