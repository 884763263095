// src/Context/CreditsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchUserInfo } from '../Api/UserApi'; // Make sure this fetches user info with credits
import { useAuth } from '../Auth/Authhandle';

const CreditsContext = createContext();

export const useCredits = () => {
    return useContext(CreditsContext);
};

export const CreditsProvider = ({ children }) => {
    const [credits, setCredits] = useState(0);
    const { accessToken, user } = useAuth(); // Use the hook at the top level

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const userData = await fetchUserInfo(accessToken, 'GET', null, user);
                const totalCredits = userData.credits_plan + userData.credits_bought;
                setCredits(totalCredits);
            } catch (error) {
                console.error('Error fetching credits:', error);
            }
        };

        if (accessToken && user) {
            fetchCredits();
        }
    }, [accessToken, user]);

    const updateCredits = async () => {
        try {
            const userData = await fetchUserInfo(accessToken, 'GET', null, user);
            const totalCredits = userData.credits_plan + userData.credits_bought;
            setCredits(totalCredits);
        } catch (error) {
            console.error('Error updating credits:', error);
        }
    };

    return (
        <CreditsContext.Provider value={{ credits, setCredits, updateCredits }}>
            {children}
        </CreditsContext.Provider>
    );
};
