import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { updateMemorise } from './Api/handle';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        alignItems: 'flex-start',
        borderRadius: '15px',
        // border: "1px solid green"
    },
    '& .MuiInputLabel-root': {
        top: '-10px',
        left: '10px',
        fontSize: '12px !important',
    },
    '& .MuiOutlinedInput-root': {
        paddingTop: '10px',
    },
});

const IndexList = ({ accessToken, guid, user, memoriseData }) => {
    const { t } = useTranslation();
    const location = useLocation();




    const [descriptionTask, setDescriptionTask] = useState('');
    const [errorPages, setErrorPages] = useState(false);


    useEffect(() => {
        if (memoriseData) {
            setDescriptionTask(memoriseData.index);
        }
    }, [memoriseData]);

    const handleStartPageChange = (event) => {
        setDescriptionTask(event.target.value);
        debounceSave(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Add your logic for handling Enter key press
        }
    };

    // Create a ref to hold the timeout id
    const debounceTimeoutRef = React.useRef(null);

    const debounceSave = (value) => {
        // Clear the existing timeout to reset the debounce timer
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout to save the changes
        debounceTimeoutRef.current = setTimeout(() => {
            saveToDatabase(value);
        }, 1000); // Adjust the delay as needed
    };

    const saveToDatabase = (value) => {
        let requestBody = { 'index': value };
        updateMemorise(accessToken, guid, requestBody, user);
    };

    const isReadOnly = location.pathname.includes('/evaluate');

    return (
        <>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                Tus notas
            </Typography>
            <CustomTextField
                value={descriptionTask}
                onChange={handleStartPageChange}
                onKeyPress={handleKeyPress}
                variant="outlined"
                size="small"
                placeholder="Escribe aquí información que te puede ayudar después a recordar el temario"
                style={{
                    marginTop: '5px',
                    backgroundColor: 'white',
                    height: '100%',
                    width: "100%",
                    paddingBottom: "40px",
                    borderRadius: "30px",
                    fontSize: "12px !important",

                }}
                InputProps={{
                    readOnly: isReadOnly,
                    style: {
                        height: '100%',
                        fontSize: "12px !important"
                    }
                }}
                multiline
            />
        </>
    );
};

export default IndexList;
