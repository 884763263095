import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Paper,
    Link,
    TextField, RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel, Button,

} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "@auth0/auth0-react";

import { useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { fetchUserInfo, fetchUserSettings, fetchUserSubscription } from '../Api/UserApi'; // Import the fetchUserInfo function
import * as handle from "./handleConfig.js"
import { useAuth } from "../Auth/Authhandle";
import { Skeleton } from "@material-ui/lab";
import ConfigSkeleton from "./ConfigSkeleton"
import { LocalDiningOutlined } from "@material-ui/icons";
import LanguageSwitcher from "./Language/Selection.js";
import { useTranslation } from 'react-i18next';
import SmallAlert from "../NewComponents/Modal/smallAlert.js";
import ModalParent from "../AllModals/ParentModal.js";
import { useNavigate } from 'react-router-dom';

const BILLING = process.env.REACT_APP_BILLING
const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0, // Extra small devices (phones)
            sm: 600, // Small devices (tablets)
            md: 1100, // Medium devices (laptops)
            lg: 1450, // Large devices (desktops)
            xl: 2000, // Extra large devices (large desktops)
        },
    },
    palette: {
        primary: {
            main: "#026277",
        },
    },
});
const customTypography = {
    color: "var(--slate-900, #0F172A)",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "36px", /* 120% */
    letterSpacing: "-0.225px",
};
const customTypographyInput = {
    color: "var(--slate-900, #0F172A)",

    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px", /* 120% */
    letterSpacing: "-0.12px",
};
const useStyles = makeStyles((theme) => ({
    flexContainer: {
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
        // padding: theme.spacing(2), // Optional: Add some padding
    },
    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",

        height: "fit-content",
        overflowY: "auto",
        margin: "0 auto"

    },

    userImage: {
        width: "56px",
        height: "56px",
        borderRadius: "50%",
        marginRight: "12px",
        objectFit: "cover",
        marginBottom: "10px"
    },
    paper: {
        background: "white",
        borderRadius: "16px",
        padding: "16px",
        boxShadow: "none",
        border: "1px solid #6BBDBD",
        borderRadius: "20px"

    },
    planContainer: {
        marginBottom: "16px",
        background: "#FAFAFA",
        borderRadius: "16px",
        padding: "20px",
    },
    planHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    planUpgradeLink: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#026277",
        cursor: "pointer"
    },
    iconTypographyContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    usageStats: {
        display: "flex",
        justifyContent: "space-between",
    },
    usageStat: {
        flex: 1,
        textAlign: "center",
        background: "#FAFAFA",
        borderRadius: "16px",
        padding: "20px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginBottom: "16px",
    },
    planContainerPaper: {
        width: "400px",
        [theme.breakpoints.down('1300')]: {
            width: '400px',
        },
    },
    textField: {
        backgroundColor: '#f5f5f5', // Light background color
        border: '1px solid #ddd', // Border to make it more visible
        borderRadius: '4px', // Rounded corners
        padding: '10px', // Padding inside the input field
        transition: 'all 0.3s ease', // Smooth transition for hover effect
        '&:hover': {
            backgroundColor: '#e0e0e0', // Darker background on hover
        },
        '&:focus-within': {
            backgroundColor: '#fff', // White background when focused
            borderColor: '#026277', // Change border color when focused
        },
    },
}));

function Config() {
    const { folder_id } = useParams();
    const [selectedValueShare, setSelectedValueShare] = useState("option1");
    const [selectedValueEvents, setSelectedValueEvents] = useState("option1");
    const { t } = useTranslation();

    //Something change in the system
    const [isChanged, setIsChanged] = useState(false);
    const [initialValues, setInitialValues] = useState({
        fullName: '',
        selectedValueShare: 'option1',
        selectedValueEvents: 'option1',
    });
    const [modalOpen, setModalOpen] = useState(false);

    // User info render
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("")
    const [imageProfile, setImageProfile] = useState("")
    const [editedName, setEditedName] = useState(fullName); // Initialize with the current name
    const [subscription, setSubscription] = useState(""); // Initialize with the current name
    const [subscriptionGuid, setSubscriptionGuid] = useState(""); // Initialize with the current name
    const [subscriptionMessages, setSubscriptionMessages] = useState(0); // Initialize with the current name
    const [subscriptionPages, setSubscriptionPages] = useState(0); // Initialize with the current name
    const [subscriptionTotalFolders, setSubscriptionTotalFolders] = useState(0); // Initialize with the current name
    const [subscriptionTotalDocsInFolder, setsubscriptionTotalDocsInFolder] = useState(0); // Initialize with the current name
    const [subscriptionQuestionsExam, setSubscriptionQuestionsExam] = useState(0); // Initialize with the current name
    const navigate = useNavigate();

    // Alert
    const [typeAlert, setTypeAlert] = useState("");
    const [linkinMessage, setLinkinMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");

    const [allInfo, setAllInfo] = useState({})
    const { accessToken, user, isLoading } = useAuth(); // Destructure isLoading from useAuth
    const classes = useStyles();
    const { logout } = useAuth0();

    const checkForChanges = () => {
        if (fullName !== initialValues.fullName ||
            selectedValueShare !== initialValues.selectedValueShare ||
            selectedValueEvents !== initialValues.selectedValueEvents) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    };

    useEffect(() => {

        async function fetchData() {
            if (accessToken && user) {
                try {
                    const userData = await fetchUserInfo(accessToken, "GET", null, user); // Call the fetchUserInfo function
                    const subResult = await fetchUserSubscription(accessToken, user); // Call the fetchUserInfo function

                    setAllInfo(userData)
                    setSubscription(subResult.plan.name)
                    setSubscriptionGuid(subResult.guid)
                    // setSubscriptionMessages(subResult.plan.max_num_messages)
                    // setSubscriptionTotalFolders(subResult.plan.max_number_folders)
                    // setSubscriptionPages(subResult.plan.max_number_pages)
                    // setSubscriptionQuestionsExam(subResult.plan.max_num_exam_questions)
                    // setsubscriptionTotalDocsInFolder(subResult.plan.max_number_files_in_folders)
                    let fullname = `${userData.name} ${userData.surname}`
                    setFullName(fullname)
                    let userEmail = userData.email

                    // Setting the states with fetched or modified user data
                    setImageProfile(userData.image)
                    setEmail(userEmail)
                    const userSettings = await fetchUserSettings(accessToken, "GET", null, user); // Call the fetchUserInfo function
                    setSelectedValueShare(`${(userSettings.share_automatic)}`)
                    setSelectedValueEvents(`${(userSettings.record_automatic)}`)

                    setInitialValues({
                        fullName: `${userData.name} ${userData.surname}`,
                        selectedValueShare: `${(userSettings.share_automatic)}`,
                        selectedValueEvents: `${(userSettings.record_automatic)}`
                    });

                } catch (error) {
                    console.error(error)
                }
            }
        }

        fetchData(); // Call the fetchData function

    }, [accessToken, user])

    useEffect(() => {
        checkForChanges();
    }, []);

    if (isLoading) {
        return (
            <ConfigSkeleton />
        );
    }
    return (
        <>
            {showAlert &&
                <SmallAlert
                    message={message}
                    progressBar={false}
                    onClose={() => setShowAlert(false)}
                    linkinMessage={linkinMessage}
                    typeAlert={typeAlert}
                />
            }
            <ThemeProvider theme={customTheme}>
                {modalOpen && (
                    <ModalParent
                        open={modalOpen}
                        title={t('delete_account')}
                        description={t('are_you_sure_delete_subscription')}
                        sendButton={t('delete')}
                        type="delete_account"
                        onClose={() => setModalOpen(false)} // Add the onClose handler
                        subscription={subscriptionGuid}

                    />
                )}
                <Container>

                    <Grid
                        container
                        spacing={3}
                        className={classes.gridContainer}

                    // style={{ height: "100%", flexWrap: "nowrap", display: "flex" }}
                    >
                        <Grid
                            item
                            style={{
                                paddingRight: "60px",
                                flexGrow: 2,
                                flexBasis: 0,
                                maxWidth: "initial",
                            }}
                        >
                            <Paper elevation={3} className={classes.paper} >
                                <div className={classes.iconTypographyContainer}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
                                        <path d="M26.6663 11.6667C26.6663 15.3486 23.6816 18.3333 19.9997 18.3333C16.3178 18.3333 13.333 15.3486 13.333 11.6667C13.333 7.98477 16.3178 5 19.9997 5C23.6816 5 26.6663 7.98477 26.6663 11.6667Z" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.9997 23.3333C13.5564 23.3333 8.33301 28.5567 8.33301 35H31.6663C31.6663 28.5567 26.443 23.3333 19.9997 23.3333Z" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <Typography style={{ customTypography, fontSize: "20px", fontWeight: "bold" }}>{t('your_account')}</Typography></div>
                                <div style={{ marginLeft: 40 }}>
                                    <Typography style={{ ...customTypography, fontSize: "14px", fontWeight: 400, marginBottom: 20 }}>{t('configure_account')}</Typography>

                                    <Typography style={customTypographyInput}>{t('photo')}</Typography>

                                    <img
                                        src={imageProfile}
                                        alt="User Image"
                                        className={classes.userImage}
                                    />
                                    <Typography style={customTypographyInput}>{t('name')}</Typography>
                                    <TextField
                                        style={{ paddingBottom: "20px", width: "100%" }}
                                        id="outlined-basic"
                                        variant="standard"
                                        inputProps={{ maxLength: 30 }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { input: classes.textField }, // Apply the custom class here

                                            style: { fontSize: "14px", color: "black", }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "10px" }
                                        }}
                                        value={fullName} // Add your default value here
                                        onChange={(evt) => {
                                            handle.handleNameChange(evt, setFullName); checkForChanges();
                                        }}
                                        fullWidth
                                    />
                                    <Typography style={customTypographyInput}>{t('email')}</Typography>
                                    <TextField
                                        style={{ paddingBottom: "20px", width: "436px" }}
                                        id="outlined-basic"
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: { input: classes.textField }, // Apply the custom class here

                                            style: { fontSize: "14px", color: "black", }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "10px" }
                                        }}
                                        inputProps={{ maxLength: 12 }}
                                        fullWidth
                                        disabled
                                        value={email}
                                    />
                                </div>
                                <div style={{ marginLeft: "40px" }}>
                                    <Typography style={customTypographyInput}>{t('select_language')}</Typography>
                                    <LanguageSwitcher />
                                </div>

                            </Paper>
                        </Grid>

                        <Grid
                            item
                            style={{
                                flexGrow: 3,
                                flexBasis: 0,
                                maxWidth: "initial",
                            }}
                        >
                            <Paper elevation={3} className={`${classes.paper} ${classes.planContainerPaper}`}>

                                <div className={classes.planContainer}>
                                    <div>
                                        <div className={classes.planHeader} >
                                            <Typography style={{
                                                ...customTypography, fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                            }}>{t('your_plan')}</Typography>
                                            <a target="_blank" onClick={() => navigate("/plans")} className={classes.planUpgradeLink} >
                                                {t('Administrador_cuenta')}
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{ marginBottom: '-3px' }} // Correct syntax for margin-bottom
                                                >
                                                    <path d="M9.91667 3.54166L14.875 8.49999M14.875 8.49999L9.91667 13.4583M14.875 8.49999L2.125 8.49999" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></a> */}
                                            </a>
                                        </div>
                                        <Typography style={{
                                            ...customTypography, fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                        }}>{subscription}</Typography>
                                    </div>

                                </div>

                                <div className={classes.usageStat} >
                                    <Typography style={{
                                        ...customTypography, fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                    }}>{t('creditos_restantes')}</Typography>
                                    <Typography style={{
                                        ...customTypography, fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                    }}>{allInfo.credits_plan + allInfo.credits_bought}</Typography>
                                </div>



                            </Paper>
                            <div style={{
                                display: "flex", justifyContent: "flex-start", padding: "16px", marginTop: "20px"
                            }}>
                                <Button
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        padding: "10px 20px",
                                        borderRadius: "8px",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        textTransform: "none",
                                        marginRight: '20px'
                                    }}
                                    // Need to appear a modal to accept deleting the account

                                    onClick={() => setModalOpen(true)}
                                >
                                    {t('delete_account')}

                                </Button>
                                {/* {allInfo.plan_name === "Basic" && (
                                    <Button
                                        style={{
                                            backgroundColor: "#026277",
                                            color: "white",
                                            padding: "10px 20px",
                                            borderRadius: "8px",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            cursor: "pointer",
                                            textTransform: "none",
                                            marginRight: '20px'
                                        }}
                                        // Need to appear a modal to accept deleting the account

                                        onClick={() => setModalOpen(true)}
                                    >
                                        {t('cancel_account')}

                                    </Button>)} */}


                                <Button
                                    style={{
                                        backgroundColor: isChanged ? "#026277" : "gray", // Gray background if not changed
                                        color: "white",
                                        padding: "10px 20px",
                                        borderRadius: "8px",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        cursor: isChanged ? "pointer" : "default", // Default cursor if not changed
                                        textTransform: "none"
                                    }}
                                    onClick={async () => {
                                        handle.updateAccount(accessToken, selectedValueShare, selectedValueEvents, fullName, setFullName, setIsChanged, user);
                                    }}
                                    disabled={!isChanged}
                                >
                                    {t('save')}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider >
        </>
    );
}

export default Config;
