import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import UploadButton from '../NewComponents/SubirApuntesButton';
import { makeStyles } from '@material-ui/core/styles';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchExamId } from '../Api/ExamsApi';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { t } from 'i18next';

const truncateText = (text, length) => {
    if (text.length <= length) {
        return text;
    }
    return text.substring(0, length) + '...';
};

const RowExamGrid = ({ guid, name, numQuestions, lastNote, lastAttempt, active, accessToken, user, onRemove }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClickMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleRemoveExam = async (exam_id) => {
        try {
            await fetchExamId(accessToken, "DELETE", exam_id, user);
            onRemove(exam_id);
            setAnchorEl(null);
        } catch (error) {
            console.error(error);
        }
    };

    const formattedDate = lastAttempt ? format(new Date(lastAttempt), 'dd/MM/yyyy') : '';

    return (
        <TableRow>
            <TableCell className={`${classes.tableCell} ${classes.multilineCell}`}>{name}</TableCell>
            <TableCell className={classes.tableCell} style={{ width: '20%', textAlign: "center" }}>{numQuestions}</TableCell>
            <TableCell className={classes.tableCell} style={{ width: '15%', textAlign: "center" }}>{lastNote}</TableCell>
            <TableCell className={classes.tableCell} style={{ width: '18%', textAlign: "center" }}>{formattedDate}</TableCell>
            <TableCell className={classes.tableCell}>
                {active ? (
                    <UploadButton onClick={() => {
                        const currentPath = window.location.pathname;
                        const newPath = `${currentPath}/exam/${guid}`;
                        navigate(newPath);
                    }} message={"Estudiar"} svg_icon={"_"} />
                ) : (
                    <ClipLoader color="#026277" size={15} />
                )}
            </TableCell>
            <TableCell className={classes.tableCell} style={{ cursor: "pointer" }}>
                <svg onClick={handleClickMenu} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18Z" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6Z" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => { handleRemoveExam(guid) }}>Delete</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles((theme) => ({
    tableCellHeader: {
        fontSize: '14px',
        fontWeight: 'bold',
        borderBottom: 'none',
    },
    tableCell: {
        fontSize: '14px',
        borderBottom: 'none',
    },
    multilineCell: {
        whiteSpace: 'pre-line',
    },
    tableContainer: {
        boxShadow: 'none',
    }
}));

const ExamGridView = ({ rawData, accessToken, user, onRemoveRow }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const newRows = Object.entries(rawData).map(([guid, item]) => {
            const documentName = item.location.split('Document: ')[1];
            const examName = item.title;

            const truncatedDocumentName = truncateText(documentName, 11);
            const truncatedExamName = truncateText(examName, 13);

            const title = (
                <span>
                    {' '}
                    {examName.length > 13 ? (
                        <Tooltip title={examName}>
                            <span>{truncatedExamName}</span>
                        </Tooltip>
                    ) : (
                        examName
                    )}
                    {'\n'}{t("apuntes")}:{' '}
                    {documentName.length > 13 ? (
                        <Tooltip title={documentName}>
                            <span>{truncatedDocumentName}</span>
                        </Tooltip>
                    ) : (
                        documentName
                    )}
                </span>
            );

            return {
                ...item,
                guid: guid,
                title: title,
            };
        });
        setRows(newRows);
    }, [rawData]);

    const handleRemoveRow = (exam_id) => {
        setRows(currentRows => currentRows.filter(row => row.guid !== exam_id));
        onRemoveRow(exam_id);
    };
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>{t("name")}</TableCell>
                        <TableCell className={classes.tableCellHeader}>Nº {t("de_preguntas")}</TableCell>
                        <TableCell className={classes.tableCellHeader}>{t("last_score")}</TableCell>
                        <TableCell className={classes.tableCellHeader}>{t("last_attempt")}</TableCell>
                        <TableCell className={classes.tableCellHeader}></TableCell>
                        <TableCell className={classes.tableCellHeader}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <RowExamGrid
                            key={index}
                            guid={row.guid}
                            name={row.title}
                            numQuestions={row.num_questions}
                            lastNote={row.final_score + "/100"}
                            lastAttempt={row.last_date_answered}
                            active={row.is_active}
                            user={user}
                            accessToken={accessToken}
                            onRemove={handleRemoveRow}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ExamGridView;
