import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './editor.css'; // Custom styles

const QuillEditor = ({ value, onChange, theme }) => {
    return (
        <div className="quill-editor-container">
            <ReactQuill
                value={value}
                onChange={(content, delta, source, editor) => {
                    onChange(content);
                }}
                theme={theme}
                modules={{
                    toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                        { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                    ],
                }}
                formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet',
                    'link', 'image', 'video'
                ]}
                className="quill-editor"
            />
        </div>
    );
};

export default QuillEditor;
