import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import Spinner from "../NewComponents/Spinner";
import { useAuth } from "../Auth/Authhandle";
import { getCsrfToken } from '../Api/TokenApi'

// const BACKEND_URL = "localhost";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;



export default function LoginSuccess({ handleLoad }) {
  const [loading, setLoading] = useState(true);
  const [newuser, setNewuser] = useState(false);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (loading === false) {
      if (newuser === false) {
        navigate("/");
      } else {
        navigate("/welcome");
      }
      setLoading(true);
    }
  }, [loading, newuser]);

  useEffect(() => {
    // Check if authentication is complete and the user is authenticated
    if (!isLoading && isAuthenticated && user) {
      const isEmailVerified = user && user.email_verified;
      // Perform registration or other post-login actions
      if (isEmailVerified) {
        registerUser(user);
      } else {
        window.location.href = `https://${REACT_FRONT_END}/verify`

      }
      // Update state based on user authentication or registration status
      // You might want to navigate to a different page or update the UI
      // Use React Router's `useNavigate` for navigation if needed
    }
  }, [isLoading, isAuthenticated, user]);


  const registerUser = async (userInfo) => {
    try {
      const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests

      const response = await fetch(`${BACKEND_URL}/api_v1/registration/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,

        },
        body: JSON.stringify(userInfo),
      });

      if (response.ok) {
        const data = await response.json();
        if (response.status === 201) {
          setNewuser(true);
        }
      } else {
        console.error("Registration failed", response);
        window.location.href = `https://${REACT_FRONT_END}/innactive`
        // Handle registration failure
      }
    } catch (error) {
      console.error("Error in registration process:", error);
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }
}
