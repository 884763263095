import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend) // enables loading of translation JSON files
    .use(LanguageDetector) // automatically detects the user's language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en', // use English if detected language is not available
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie']
        },
        backend: {
            loadPath: '/locales/{{lng}}.json', // path to load the translation files
        },
        interpolation: {
            escapeValue: false, // not needed for React as it escapes by default
        },
    });

export default i18n;
