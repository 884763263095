import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
    rootElement: {
        width: 350, // Adjust the card width
        height: 250, // Fixed height for the card

        margin: '20px',
        borderRadius: '6px',
        boxShadow: 'none', // Remove box shadow
        border: "2px solid #D9D9D9",
        backgroundColor: "#FFFDFD"
    },
    media: {
        height: 140,
    },
    image: {
        width: '80%',
        height: '150px',
        objectFit: 'cover',
        display: "flex",
        margin: "auto"
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0px', // Add space between cards
    },
    title: {
        fontSize: '14px', // Adjust title font size
        fontWeight: "700"
    },
    description: {
        fontSize: '10px', // Adjust description font size
        marginTop: '10px', // Add more space between text
    },
});

const ActionCard = ({ imageSrc, title, description, onClick }) => {
    const classes = useStyles();

    return (
        <Card className={classes.rootElement} onClick={onClick}>
            <CardActionArea >
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                        {description}
                    </Typography>
                </CardContent>
                <img src={imageSrc} alt={title} className={classes.image} />
            </CardActionArea>
        </Card>
    );
};

const General = ({ changeModalType }) => {
    const classes = useStyles();
    const [startZero, setStartZero] = useState(false);
    const [startNotes, setStartNotes] = useState(false);
    const { t } = useTranslation();
    const { folder_id, selectedFolderParam } = useParams();
    const { document_id, selectedDocumentParam } = useParams();
    const handleCardClickZero = () => {
        changeModalType('ponteprueba1', "350px", t("what_to_study"));
    };
    const handleCardClickNotes = () => {
        changeModalType('ponteprueba2', "350px", "Dinos como quieres que sea tu próximo examen");
    };
    useEffect(() => {
        if (document_id) {
            handleCardClickNotes();
        }
    }, [document_id]);
    return (
        <div className={classes.cardContainer}>
            <ActionCard
                imageSrc="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/7119225_3411083-removebg-preview+(1)+1.svg"
                title={t("start_from_zero")}
                description={t("start_from_zero_desc")}
                onClick={() => handleCardClickZero()}
            />
            <ActionCard
                imageSrc="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/14230944_5437683__1_-removebg-preview+1.svg"
                title={t("use_current_files")}
                description={t("use_current_files_desc")}
                onClick={() => handleCardClickNotes()}
            />
        </div>
    );
};

export default General;
