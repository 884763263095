import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Tooltip } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { fetchExamId, fetchFolderExams } from '../Api/ExamsApi';
import { useAuth } from "../Auth/Authhandle";
import useExamListStyles from "./Styles/useExamListStyles";
import { useNavigate } from "react-router-dom";
function ExamListView() {
    const { t } = useTranslation();
    const classes = useExamListStyles();
    const { accessToken, user } = useAuth();

    const [listExams, setListExams] = useState({});
    const [currentPage, setCurrentPage] = useState(0); // Starting index at 0 for the first page
    const rowsPerPage = 10; // Fixed value as per your requirement
    const indexOfLastExam = (currentPage + 1) * rowsPerPage;
    const indexOfFirstExam = indexOfLastExam - rowsPerPage;
    // At the top where you define other hooks
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchExams() {
            if (accessToken && user) {
                try {
                    setLoading(true);
                    const examsResponse = await fetchFolderExams(accessToken, "GET", null, user);
                    // Transform the response into an array of objects, each with a guid
                    const transformedExams = Object.entries(examsResponse).map(([guid, examDetails]) => ({
                        guid,
                        ...examDetails,
                    }));
                    setListExams(transformedExams);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                }
            }
        }

        fetchExams();
    }, [accessToken, user]);
    // Then, in your render:
    const handleRemoveExam = async (exam_id) => {
        // Immediately filter out the exam optimistically

        try {
            // Perform the deletion request
            await fetchExamId(accessToken, "DELETE", exam_id, user);
            // No need to do anything here on success, as we've already updated the UI
            setListExams(currentExams => currentExams.filter(exam => exam.guid !== exam_id));

        } catch (error) {
            console.error(error);
            // Handle error - e.g., show an alert or undo the optimistic update

            // Optionally, fetch the exams again or revert the change in the state
        }
    };


    if (loading) return <div>Loading...</div>;
    const totalExams = listExams.length;
    const totalPages = Math.ceil(totalExams / rowsPerPage);

    const currentExams = listExams.slice(indexOfFirstExam, indexOfLastExam);
    return (

        <Grid container className={classes.gridContainer} key={listExams.length}>
            <Grid item xs={12} className={classes.titleSection}>
                <Typography variant="h6"
                    style={{
                        color: "var(--slate-900, #0F172A)",

                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "36px",
                        letterSpacing: "-0.225px",
                        paddingLeft: 2
                    }}> {t("your_exams")} </Typography>
            </Grid>
            <Grid item xs={12} >
                {listExams.length > 0 ? (
                    <>
                        <TableContainer component={Paper} className={`${classes.tableContainer} custom-scrollbar`}>
                            <Table >
                                <TableHead stickyHeader aria-label="simple table" >
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableHeader}>{t("title")}</TableCell>
                                        <TableCell align="left" className={classes.tableHeader}>{t("unit")}</TableCell>
                                        <TableCell align="center" className={classes.tableHeader}>{t("punctuation")}</TableCell>
                                        <TableCell align="center" className={classes.tableHeader}></TableCell> {/* Empty cell for the button column */}
                                        <TableCell align="center" className={classes.tableHeader}></TableCell> {/* Empty cell for the button column */}
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {currentExams.map((exam) => (
                                        <TableRow
                                            key={exam.guid}
                                            className={classes.tableRowHover} // Apply the style here
                                        >
                                            <TableCell component="th" scope="row" className={`${classes.tableCell} ${classes.titleCell}`}>
                                                {exam.title}
                                            </TableCell>

                                            <Tooltip title={exam.location}>
                                                <TableCell className={`${classes.tableCell} ${classes.locationCell}`}>
                                                    {exam.location.length > 55 ? `${exam.location.substring(0, 55)}...` : exam.location}
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell className={`${classes.tableCell} ${classes.scoreCell}`}>{exam.score} / 100</TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.actionCell}`}>
                                                <Button className={classes.revisarButton} onClick={() => navigate(`/exam/${exam.guid}`)}>{t("review")}</Button>
                                            </TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.actionCell}`}>
                                                <Button className={classes.eliminarButton} onClick={async (e) => {
                                                    // Prevent row click event
                                                    e.stopPropagation(); // Prevent row click event
                                                    e.preventDefault(); // Prevent default action if it's causing a reload

                                                    handleRemoveExam(exam.guid)
                                                }} >{t("delete")}</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        {totalExams > rowsPerPage && (
                            <Box display="flex" justifyContent="center" alignItems="center" marginTop={2} style={{ width: '90%', marginLeft: '50px' }}>
                                <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0} className={classes.controllerButton}>
                                    {t("previous")}
                                </Button>
                                <Typography variant="body1" style={{ margin: '0 20px', }}>
                                    {t("page_citation")} {currentPage + 1} {t("of")} {totalPages}
                                </Typography>
                                <Button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage + 1 >= totalPages} className={classes.controllerButton}>
                                    {t("next")}
                                </Button>
                            </Box>
                        )}
                    </>
                ) : (
                    <Typography className={classes.noExams}>{t("not_exams_yet")}</Typography>
                )}
            </Grid>
        </Grid>
    );
}

export default ExamListView;
