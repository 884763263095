import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function




export async function deleteDocument(token, document_id, folder_id, user) {

    try {
        // Fetch folder name based on the first path segment (folder ID)
        const listTranscripts = await fetchUserFile(token, "DELETE", folder_id, document_id, null, user);


    } catch (error) {
        console.error(error);
    }
}
export async function updateDocumentName(token, document_id, folder_id, updateListDocuments, newName, user) {

    try {
        // Fetch folder name based on the first path segment (folder ID)
        const listTranscripts = await fetchUserFile(token, "PUT", folder_id, document_id, { "docName": newName }, user);
        updateListDocuments();


    } catch (error) {
        console.error(error);
    }
}
export async function updateDocumentFileLocation(token, document_id, folder_id, updateListDocuments, newFolderId, user, onClose, newFolderName) {

    try {
        // Fetch folder name based on the first path segment (folder ID)
        if (!newFolderName) {
            const listTranscripts = await fetchUserFile(token, "PUT", folder_id, document_id, { "folder_id": newFolderId.guid }, user);
        } else {
            const listTranscripts = await fetchUserFile(token, "PUT", folder_id, document_id, { "folder_id": newFolderName }, user);
        }
        if (updateListDocuments) {
            updateListDocuments();
        }

        onClose();


    } catch (error) {
        console.error(error);
    }
}

