import React from 'react';
import { List, ListItem, ListItemText, Typography, ListItemIcon } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const CorrectionList = ({ items }) => {
    const getColor = (key) => {
        switch (key) {
            case 'red':
                return 'red';
            case 'orange':
                return 'orange';
            case 'green':
                return '#00C608';
            default:
                return 'black';
        }
    };

    const sortedKeys = ['red', 'orange', 'green'];

    return (
        <List>
            {sortedKeys.map((key) => (
                items[key] && items[key].map((description, index) => (
                    <ListItem key={`${key}-${index}`} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemIcon>
                            <CircleIcon style={{ color: getColor(key), fontSize: "14px" }} />
                        </ListItemIcon>
                        <ListItemText
                            secondary={
                                <Typography style={{ fontSize: "14px", fontWeight: "normal" }}>
                                    {description}
                                </Typography>
                            }
                        />
                    </ListItem>
                ))
            ))}
        </List>
    );
};

export default CorrectionList;
