import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';

const usePlanStyles = makeStyles((theme) => ({
    root: {
        width: "90%",
        margin: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: "0px !important",
        borderRadius: "20px !important",
        boxShadow: "none !important",
        marginTop: "-50px",
        paddingBottom: "15px",
        border: "1px solid #6BBDBD  !important"

    },
    root2: {
        width: "90%",
        margin: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: "0px !important",
        borderRadius: "40px !important",
        boxShadow: "none !important",
        marginTop: "-50px",
        backgroundColor: "#6BBDBD !important",
        paddingBottom: "15px"

    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    button: {
        color: 'white !important',
        '&:hover': {
            backgroundColor: '#20b2b3', // Slightly darker on hover
        },
        fontSize: "14px !important",
        fontWeight: "bold !important",
        border: "0px",
        borderRadius: "24px !important",
        textTransform: "none !important",
        textDecoration: "none",
        background: '#6BBDBD !important', // Linear gradient background
        width: '60%', // Set the button width to 70%
        margin: '0 auto', // Auto margins for horizontal centering
    },
    button2: {
        color: '#6BBDBD !important',
        fontSize: "14px !important",

        fontWeight: "bold !important",
        border: "0px",
        borderRadius: "24px !important",
        textTransform: "none !important",
        textDecoration: "none",
        background: 'white !important', // Linear gradient background
        width: '60%', // Set the button width to 70%
        margin: '0 auto', // Auto margins for horizontal centering
    },

    button3: {
        color: '#6BBDBD !important',
        fontSize: "14px !important",

        fontWeight: "bold !important",
        border: "0px",
        borderRadius: "24px !important",
        textTransform: "none !important",
        textDecoration: "none",
        background: '#E2F1F1 !important', // Linear gradient background
        width: '60%', // Set the button width to 70%
        margin: '0 auto', // Auto margins for horizontal centering
    },
    listItem: {
        paddingTop: "2px !important",
        paddingBottom: "2px !important",
        fontSize: "12px !important"
    },
    listItem2: {
        paddingTop: "2px !important",
        paddingBottom: "2px !important",
        fontSize: "12px !important"
    },
    listItemIcon: {
        minWidth: '0px !important',
        marginRight: "10px !important"
    },
    listItemIcon2: {
        minWidth: '0px !important',
        marginRight: "10px !important",
    },
    priceText: {
        fontSize: '25px !important',
        fontWeight: '600 !important',
        margin: '1rem 0',
        marginBottom: 2
    },
    priceText2: {
        fontSize: '25px !important',
        fontWeight: '600 !important',
        margin: '1rem 0',
        marginBottom: 2,
        color: "white"
    },
    nameText: {
        fontSize: '10px',
        margin: '1rem 0',
        color: "gray"
    },
    element: {
        fontSize: "12px !important",
        color: "gray",
        '& .MuiListItemText-primary': {
            fontSize: '14px',
        },
    },
    element2: {
        fontSize: "12px !important",
        color: "white",
        '& .MuiListItemText-primary': {
            fontSize: '14px',
        },
    },
    priceStyle: {
        fontSize: "25px !important",
        paddingLeft: "18px",
        marginBottom: "15px"

    },
    priceStyle2: {
        fontSize: "25px !important",
        color: "white",
        paddingLeft: "19px",
        marginBottom: "15px"

    },
    planName2: {
        fontSize: "25px !important",
        fontWeight: "700 !important",
        color: "white",
        paddingLeft: "18px",


    },
    planName: {
        fontSize: "25px !important",
        fontWeight: "700 !important",
        color: "black",
        paddingLeft: "18px",

    },
    planDescription2: {
        fontSize: "12px !important",
        color: "white",
        paddingLeft: "18px",


    },
    planDescription: {
        fontSize: "12px !important",
        color: "gray",
        paddingLeft: "18px",

    },
    disabledButton: {
        cursort: "default",
    },
    smallFont: {
        fontSize: "12px"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px', // Add this line to maintain gaps between cards
        marginTop: "30px",
        [theme.breakpoints.down('1200')]: {
            height: '100%',
            flexDirection: 'column !important',
            gap: "30px",

        },

    },
    Maincontainer: {
        display: "flex",
        flexDirection: 'column !important',
        justifyContent: 'center',
        gap: "30px"
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px', // Adjust the padding as needed
    },
}));

export default usePlanStyles;
