import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    Paper,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles,
    Menu
} from "@material-ui/core";
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PDFViewerComponent from "../Documents/Viewers/PdfViewer";
import Chat from "../NewComponents/Chat";
import useChatViewStyles from "./Styles/useChatViewStyles";
import * as handle from "../Main/handle";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    select: {
        '&:before': {
            borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '& .MuiSelect-select': {
            fontWeight: '600',  // Make the selected item bold
        }
    },
    menuItem: {
        '&.Mui-selected': {
            fontWeight: '600',

        },
    },
}));
function ChatView({ folderSelected, listFolders }) {
    const { accessToken, user } = useAuth();
    const { t } = useTranslation();

    const [summary, setSummary] = useState("");
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [chaptersTitle, setChaptersTitle] = useState([]);
    const [chapterTitle, setChapterTitle] = useState([]);
    const [isDocument, setIsDocument] = useState(false);
    const [folder, setFolder] = useState(folderSelected);
    const [docInfo, setDocInfo] = useState(null);
    const [typeAlert, setTypeAlert] = useState("");
    const [linkinMessage, setLinkinMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [listDocuments, setListDocuments] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");

    const classes = useChatViewStyles();
    const customClasses = useStyles();

    useEffect(() => {
        async function fetchData() {
            if (accessToken && user) {
                await handle.fetchDocuments(accessToken, setListDocuments, folder.guid, user);
            }
        }

        fetchData();
    }, [accessToken, user, folder.guid]);

    useEffect(() => {
        if (listDocuments.length > 0) {
            setDocInfo(listDocuments[0]);  // Set the first document as the default selected document
        }
    }, [listDocuments, folderSelected]);

    const handleDocumentSelect = (document) => {
        setDocInfo(document);
    };
    const handleFolderChange = (event) => {
        const selectedFolder = listFolders.find(folder => folder.guid === event.target.value);
        setFolder(selectedFolder);
        setDocInfo(null)
    };
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMoreClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            {showAlert && (
                <SmallAlert
                    message={message}
                    progressBar={false}
                    onClose={() => setShowAlert(false)}
                    linkinMessage={linkinMessage}
                    typeAlert={typeAlert}
                />
            )}
            {showAlertUpload && (
                <SmallAlert
                    message={messageUpload}
                    progressBar={false}
                    onClose={() => setShowAlertUpload(false)}
                />
            )}
            {
                <NeedCredits open={showUpgrade} message={message}

                    onClose={() => setShowUpgrade(false)} />
            }
            <Container spacing={3} className={classes.gridContainer}>
                <>
                    <Grid item className={classes.leftPart}>
                        <Paper elevation={3} className={classes.paper} style={{ height: "100%" }}>
                            <div style={{ position: 'relative', gap: "20px", flexDirection: "column", display: "flex" }}>
                                <div style={{ position: 'relative', justifyContent: "space-between", flexDirection: "row", display: "flex" }}>
                                    {/* <Typography style={{
                                        color: "#026277",
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        letterSpacing: "-0.225px",
                                        paddingLeft: 2
                                    }}>{folder.name}</Typography> */}
                                    <FormControl style={{ minWidth: '150px' }}>
                                        <Select
                                            labelId="folder-select-label"
                                            id="folder-select"
                                            value={folder.guid}
                                            onChange={handleFolderChange}
                                            disableUnderline
                                            className={customClasses.select}

                                            MenuProps={{
                                                classes: { paper: customClasses.menuPaper },
                                            }}

                                        >
                                            {listFolders.map((item, i) => (
                                                <MenuItem key={i} value={item.guid} className={customClasses.menuItem}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    {listDocuments.slice(0, 4).map((item, i) => (
                                        <Button
                                            key={i}
                                            className={`${classes.folderButton} ${docInfo?.guid === item.guid ? 'active' : ''}`}
                                            onClick={() => handleDocumentSelect(item)}
                                        >
                                            {item.title}
                                        </Button>
                                    ))}
                                    {listDocuments.length > 4 && (
                                        <>
                                            <Button
                                                className={classes.moreButton}
                                                onClick={handleMoreClick}
                                            >
                                                {t("more")}
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleMoreClose}
                                                className={classes.dropdownMenu}
                                            >
                                                {listDocuments.slice(4).map((item, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        onClick={() => {
                                                            handleDocumentSelect(item);
                                                            handleMoreClose();
                                                        }}
                                                        className={` ${docInfo?.guid === item.guid ? 'active' : ''}`}

                                                        style={{ fontSize: "14px" }}
                                                    >
                                                        {item.title}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div style={{ overflowY: "scroll", height: "100%", marginTop: "30px" }}>
                                {docInfo ? (<PDFViewerComponent docInfo={docInfo} token={accessToken} />) : (
                                    <div style={{ margin: "auto", display: "flex", flexDirection: "column" }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={require("./img/noContent.svg")} alt="No Documents" className={classes.imgEmpty} />
                                        </div>
                                        <Typography style={{ fontSize: "14px", textAlign: "center", marginTop: "10px" }}>{t("no_docs_in_folder")}</Typography>

                                    </div>

                                )}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.rightPart}>
                        <Paper elevation={3} className={classes.paperright}>
                            <Chat
                                height={"100%"}
                                messages={messages}
                                setMessages={setMessages}
                                accessToken={accessToken}
                                user={user}
                                isPdf={true}
                                folder_id={folder.guid}
                            />
                        </Paper>
                    </Grid>
                </>
            </Container>
        </>
    );
}

export default ChatView;
