// StudyCard.js
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    cardStudy: {
        width: '230px !important',
        height: '259px !important',
        borderRadius: '20px !important',
        display: 'flex !important', // Makes the Card a flex container
        flexDirection: 'column !important', // Align children vertically
        justifyContent: 'center !important', // Centers content vertically
        alignItems: 'center !important', // Centers content horizontally
        border: '1px solid #6BBDBD !important',
        cursor: 'pointer !important',
        padding: '16px !important', // Optional: adds space around
        transition: 'transform 0.3s ease !important', // Transition for the transform property
        '&:hover': {
            transform: 'scale(1.15)', // Scale the card up by 5% on hover
        },
    },
    imageStudy: {
        maxWidth: '100% !important',
        maxHeight: '130px !important',
        objectFit: 'contain !important',
    },
    titleStudy: {
        marginTop: '30px !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important',
        textAlign: 'center !important',
    },
}));

function StudyCard({ title, image, handleClick }) {
    const classes = useStyles();

    return (
        <Card className={classes.cardStudy} onClick={handleClick}>
            <img src={image} alt={title} className={classes.imageStudy} />
            <Typography gutterBottom className={classes.titleStudy}>
                {title}
            </Typography>
        </Card>
    );
}

export default StudyCard;
