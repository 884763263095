import { makeStyles } from '@material-ui/core/styles';

const useTableDocStyles = makeStyles((theme) => ({
    inputField: {
        fontSize: '14px',
        padding: '4px 8px',
        border: '1px solid #CCCCCC',
        borderRadius: '4px',
        outline: 'none',
        width: '100%',
        boxSizing: 'border-box',
        transition: 'border-color 0.3s',
        '&:focus': {
            borderColor: '#026277',
        },
    },
    header: {
        color: "#A0A0A0",
        borderBottom: "none",
        position: "sticky",
        zIndex: 1,
        top: 0,
        backgroundColor: "#FAFAFA",
        fontSize: "12px",
        paddingBottom: "5px"
    },
    fileListContainer: {
        backgroundColor: "#FAFAFA",
        borderRadius: "20px",
        marginBottom: "15px",
        marginTop: "15px",
        overflowY: "scroll",
        boxShadow: "none",
        overflowX: "hidden",
        maxHeight: "200px",
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(2, 98, 119, 0.20)',
        },
        height: "15px !important",
    },
    tableCell: {
        borderBottom: "none",
        color: "#060D01",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        cursor: "pointer",
        padding: "4px 8px", // Adjust padding
        paddingLeft: "16px"
    },
    tableCellSpecial: {
        paddingLeft: 0,
        borderBottom: "none",
    },

    stickyHeader: {
        position: "sticky !important",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.4)',
            transition: 'transform 0.2s ease-in-out',
            borderRadius: '50%',
        },
        width: "35%"
    },

}));
export default useTableDocStyles;

