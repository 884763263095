import React, { useState } from 'react';
import AIChatSubject from './FormSubject';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Auth/Authhandle";
import { Container, Typography, Box } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import { useSubscription } from "../Context/SubscriptionContext";
import ChatView from './ChatView';

const AIChatView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useSubscription();
    const { accessToken, user } = useAuth();
    const [credits, setCredits] = useState(0);
    const [folder, setFolder] = useState("");
    const [folders, setFolders] = useState([]);

    const handleFoldersUpdate = (updatedFolders) => {
        setFolders(updatedFolders);
    };
    return (
        folder !== "" ? (
            <ChatView folderSelected={folder} listFolders={folders} />
        ) : (
            <Container
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    marginTop: "-70px"
                }}
            >
                <div style={{

                    width: 499,
                    height: 300,
                    background: 'white',
                    borderRadius: 20,
                    border: '1px #6BBDBD solid',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '20px 0',
                    overflowY: "auto",
                    paddingBottom: "0"
                }}>
                    <Box style={{ textAlign: "center", width: "100%" }}>
                        <Typography style={{ color: '#026277', fontSize: 30, fontFamily: 'Inter', fontWeight: '700' }}>
                            AI Chat
                        </Typography>
                        <Typography style={{ color: 'black', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', marginBottom: 20 }}>
                            {t("desc_ai_chat")}
                        </Typography>
                    </Box>
                    <Box style={{ textAlign: "center", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <AIChatSubject
                            user={user}
                            accessToken={accessToken}
                            setCredits={setCredits}
                            setFolder={setFolder}
                            onFoldersUpdate={handleFoldersUpdate}
                        />
                    </Box>
                </div>
            </Container>
        )
    );
};

export default AIChatView;
