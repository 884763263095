import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // You can replace this with your own SVGs
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { SvgIcon } from '@mui/material';

const CustomCheckCircleIcon = (props) => (
    <SvgIcon {...props}>
        <circle cx="12" cy="12" r="12" fill="rgba(107, 189, 189, 0.10)" />
        <path
            d="M10 15.172L7.414 12.586L6 14L10 18L18 10L16.586 8.586L10 15.172Z"
            fill="#6BBDBD"
        />
    </SvgIcon>
);


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '30px',
        width: '611px',
        height: '285px',
        overflow: 'hidden',
        position: 'relative',
        padding: 0
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 1,
}));

const CustomListItem = styled(ListItem)({
    alignItems: 'flex-start',
});

const CustomListItemIcon = styled(ListItemIcon)({
    marginTop: '2px',
    minWidth: '30px',
});

const ModalNotReady = ({ handleClose }) => {
    const { t } = useTranslation();

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogContent dividers style={{ padding: 0, border: 0 }}>
                <Grid container spacing={0} style={{ height: '100%' }}>
                    <Grid item xs={12} md={5} style={{ padding: '0', height: '100%' }}>
                        <img src={require("./img/featureComingSoon.png")} alt="Illustration" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px 0 0 20px' }} />

                    </Grid>
                    <Grid item xs={12} md={7} style={{ padding: '20px', height: '100%', overflow: 'hidden' }}>
                        <Typography fontSize="20px" fontWeight="bold" gutterBottom>
                            {t("feature_not_available")}
                        </Typography>
                        <Typography fontSize="14px" gutterBottom>
                            {t("desc_feat_not_available")}
                        </Typography>
                        <List>
                            <CustomListItem style={{ padding: 3 }} >
                                <CustomListItemIcon>
                                    <CustomCheckCircleIcon /> {/* Replace with your SVG icon */}
                                </CustomListItemIcon>
                                <ListItemText primary={t("desc_preguntas_examen")} primaryTypographyProps={{ fontSize: '12px' }} />
                            </CustomListItem>
                            <CustomListItem style={{ padding: 3 }} >
                                <CustomListItemIcon>
                                    <CustomCheckCircleIcon /> {/* Replace with your SVG icon */}
                                </CustomListItemIcon>
                                <ListItemText primary={t("desc_apuntes")} primaryTypographyProps={{ fontSize: '12px' }} />
                            </CustomListItem>
                            <CustomListItem style={{ padding: 3 }} >
                                <CustomListItemIcon>
                                    <CustomCheckCircleIcon /> {/* Replace with your SVG icon */}
                                </CustomListItemIcon>
                                <ListItemText primary={t("desc_subject")} primaryTypographyProps={{ fontSize: '12px' }} />
                            </CustomListItem>
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            {/* <CloseButton onClick={handleClose}>
                <CloseIcon />
            </CloseButton> */}
        </CustomDialog>
    );
};

export default ModalNotReady;
