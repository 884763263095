import { makeStyles } from '@material-ui/core/styles';

const useChapterStyles = makeStyles((theme) => ({

    markdownEditorWrapper: {
        fontSize: "12px"
    },
    generateSummary: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "20px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "200px",
        fontWeight: "bold",
        margin: "0 auto",
        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",


        }
    },

}));
export default useChapterStyles;

