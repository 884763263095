import * as val from "../NewComponents/EmailValidation";
import { fetchUserFolder } from '../Api/FolderApi'; // Import the fetchUserInfo function

export const handleKeyDown = (
  evt,
  value,
  setItems,
  setValue,
  items,
  setError
) => {
  if (["Enter", "Tab", ","].includes(evt.key)) {
    evt.preventDefault();

    var newValue = value.trim();

    if (newValue && val.isValid(newValue, setError, items)) {
      setItems([...items, value]);
      setValue("");
    }
  }
};
export const handleChange = (evt, setValue, setError) => {
  setValue(evt.target.value);
  setError(null);
};

export const handleDelete = (item, items, setItems) => {
  setItems(items.filter((i) => i !== item));
};

export const handlePaste = (evt, isInList, setItems, items) => {
  evt.preventDefault();

  var paste = evt.clipboardData.getData("text");
  var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  if (emails) {
    var toBeAdded = emails.filter((email) => !val.isInList(email, items));

    setItems([...items, ...toBeAdded]);
  }
};


export const handleButtonClick = async (token, folder_id = null, emails = null, type, onClose = null, user) => {

  if (type === "eliminar") {
    await fetchUserFolder(token, "DELETE", folder_id, null, user);
    onClose()
  } else if (type === "shareFolder") {

    await fetchUserFolder(token, "PUT", folder_id, { "emails": emails, "type": "share" }, user);
    onClose()

  }


  onClose(); // Close the dialog after the operation has completed

};

