
import { fetchUserFolder } from "../Api/FolderApi"; // Import the fetchData function from api.js
import { fetchUserFiles } from "../Api/FilesApi"; // Import the fetchData function from api.js


export async function fetchData(token, setFolder, folder_id, user) {

    try {
        // Fetch folder name based on the first path segment (folder ID)
        const folderInfo = await fetchUserFolder(token, "GET", folder_id, null, user);
        setFolder(folderInfo)

    } catch (error) {
        console.error(error);
    }
}


export async function fetchDocuments(token, setListDocuments, folder_id, user) {

    try {
        // Fetch folder name based on the first path segment (folder ID)
        const listDocuments = await fetchUserFiles(token, "GET", folder_id, null, user);
        setListDocuments(listDocuments)

    } catch (error) {
        console.error(error);
    }
}



export const handleShareFolder = (
    t, // Add this parameter
    setModalOpen,
    setTitle,
    setDescription,
    setButton,
    setPlaceholderMessage,
    setTypeModal,
    setOpenMenu
) => {
    setOpenMenu(false);

    setModalOpen(true);
    setTitle(t("share_folder_title"));
    setDescription(t("share_folder_description"));
    setButton(t("share_folder_button"));
    setPlaceholderMessage(t("share_folder_placeholder"));
    setTypeModal("shareFolder");
};
export const handleUploadFile = (
    t, // Add this parameter
    setModalOpen,
    setTitle,
    setDescription,
    setButton,
    setPlaceholderMessage,
    setTypeModal,

    setWidthModal


) => {
    setModalOpen(true);
    setTitle("📄 " + t("upload_file_sidebar"));
    setDescription("Elige las características de tu próximo examen");
    setButton(t("upload_button"));
    setPlaceholderMessage("");
    setTypeModal("upload");
    setWidthModal("350px")
};
export const handleCreateExam = (
    t, // Add this parameter
    setModalOpen,
    setTitle,
    setDescription,
    setButton,
    setPlaceholderMessage,
    setTypeModal,

    setWidthModal


) => {

    setModalOpen(true);
    setTitle("📝 " + t("upload_exam_modal"));
    setDescription(t("what_to_study_option"));
    setButton(t("create"));
    setPlaceholderMessage("");
    setTypeModal("layout_exam");
    setWidthModal("800px")
};



