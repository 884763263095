const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


export const updateUsersList = async (currentPage, searchQuery, token, setUsers, setTotalPages, setHasMore, pageSize, user_id) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
        };
        const response = await fetch(`${BACKEND_URL}/api_v1/subscription/?page=${currentPage}&search=${searchQuery}&user_id=${user_id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsers(data.results);
        // You may also update the total pages and hasMore states if necessary
        setTotalPages(Math.ceil(data.count / pageSize));
        setHasMore(data.next !== null);
    } catch (error) {
        console.error('Error fetching updated user data:', error);
        // Handle errors, e.g., show an error message
    }
};


export const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
};
