import { List, ListItem, ListItemIcon, ListItemText, Grid, Paper, Container, TextField, Box, Typography, Card, CardContent, Button } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PDFViewerComponent from "../Documents/Viewers/PdfViewer";
import useChooseFileStyles from "./styles/useStylesReadFile";
import { fetchMemorise } from "./Api/handle";
import IndexList from "./ListIndexComponent";


function ReadComponent({ fileChosen, accessToken, user, setFileChosen }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useChooseFileStyles()
    const { memorise_id } = useParams();
    const [fileData, setFileData] = useState(fileChosen);
    const [memoriseData, setMemoriseData] = useState(fileChosen);

    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + '...';
    };


    useEffect(() => {
        if (memorise_id && accessToken) {
            fetchMemorise(accessToken, memorise_id, user)
                .then(data => {
                    setFileData(data.document);
                    setFileChosen(data)
                    setMemoriseData(data)
                })
                .catch(error => {
                    console.error("Error fetching file data:", error);
                });
        }
    }, [memorise_id, accessToken, user]);
    return (
        <Container style={{
            display: "flex", flexDirection: "row", gap: "50px", backgroundColor: "#F8FFFF", maxWidth: "none", height: "100vh", overflowY: "hidden"

        }}>
            <Grid
                container
                spacing={0}

                className={classes.leftPart}

            >


                <Paper elevation={3} className={classes.paperLeft} >

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                        <Typography className={classes.title}>Prepara {truncateText(fileData?.title || '', 20)}</Typography>
                        <div><Button className={classes.buttonEmpezar} onClick={() => { navigate(`/memorise/${memorise_id}/evaluate`) }}>Evaluar</Button></div>
                    </div>
                    <Typography style={{ fontSize: "14px", marginBottom: "10px" }}>{t("readSyllabus")}</Typography>

                    {fileData && <PDFViewerComponent docInfo={fileData} token={accessToken} />}

                </Paper>
            </Grid>
            <Grid
                item
                className={`${classes.rightPart}`}
                style={{ overflowY: "scroll", paddingBottom: "30px", marginBottom: "40px" }}
            >
                {memoriseData && (
                    <IndexList accessToken={accessToken} guid={memorise_id} user={user} memoriseData={memoriseData} />
                )}


            </Grid>
        </Container>
    );
}

export default ReadComponent;
