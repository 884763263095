import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";

import { Scrollbars } from "react-custom-scrollbars";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import CardData from "../NewComponents/CardData"; // Import your ClipDialog component
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import { useSearchParams, useParams } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RetryButton from "../NewComponents/RetryButton";
import { useAuth } from "../Auth/Authhandle";
import Spinner from "../NewComponents/Spinner";
import { useTranslation } from 'react-i18next';

import * as handleOperations from "./handleOperations";
import ClipDialog from "../NewComponents/ClipDialog";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    identificar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginRight: "40px",
      paddingTop: "12px",
      textTransform: "none",
      fontSize: "14px",
      borderRadius: "10px",
      fontWeight: "bold",
    },

    column: {
      display: "flex",
      flexDirection: "column",
    },

    transcripts: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "0px",
      paddingBottom: "5px",
      borderRadius: "20px",
    },

    title: {
      fontSize: "30px",
      fontWeight: "bold",
    },

    grid: {
      marginTop: "35px",
    },
    grid2: {
      marginTop: "70px",
    },

    mynotesIcon: {
      width: "90%",
      paddingRight: "20px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    mynotes: {
      fontSize: "18px",
      fontWeight: "bold",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "5px",
    },

    info: {
      paddingLeft: "10px",
      paddingRight: "10px",
      fontSize: "14px",
    },

    text: {
      fontSize: "14px",
      paddingRight: "20px",
      paddingTop: "20px",
      paddingBottom: "5px",
      // Add these for `ul` and `li`
      '& ul': {
        listStyleType: 'disc',
        marginLeft: theme.spacing(3),
      },
      '& li': {
        marginTop: theme.spacing(1),
      },
    },

    info2: {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontSize: "14px",
      backgroundColor: "#9FF5DB",
      borderRadius: "4px",
    },

    image: {
      paddingBottom: "10px",
    },
    navbar: {
      textTransform: "none",
    },

    subtitle: {
      color: "#6A6A6A",
      fontSize: "14px",
    },
    subtitle2: {
      backgroundColor: "#9FF5DB",
      borderRadius: "6px",
      fontSize: "14px",
      marginLeft: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "center",
      textTransform: "none",
      disableElevation: "true",
      boxShadow: "0px",
    },
    agenda: {
      height: "90%",
      [theme.breakpoints.down("xl")]: {
        height: "90%",
        overflow: "hidden",

        borderRadius: "25px",
      },
      [theme.breakpoints.up("l")]: {
        height: "90%",
        overflow: "hidden",

        borderRadius: "25px",
      },
    },
    root: {
      // backgroundColor: '#f5f5f5',
      paddingLeft: 5,
      borderRadius: theme.spacing(1),
      fontSize: "0.9rem",
      // letterSpacing: '0.05em',
      lineHeight: "1.5",
      whiteSpace: "pre-wrap",
    },
    speaker: {
      fontWeight: "bold",
      marginRight: theme.spacing(2),
    },
    message: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(0),

    },
    separator: {
      margin: theme.spacing(2, 0),
      // borderTop: '1px dashed #ccc',
    },
    notes: {
      [theme.breakpoints.down("xl")]: {
        height: "300px",
        overflow: "hidden auto",
        width: "100%",
      },
      [theme.breakpoints.up("xl")]: {
        height: "300px",
        overflow: "hidden",
      },
    },
    downloadButton: {
      position: "relative",
      display: "inline-block",
      padding: "8px 16px",
      borderRadius: "4px",
      background: "#fff",
      color: "#000",
      cursor: "pointer",
      transition: "color 0.3s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        color: "#026277",
      },
    },
    centerContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%", // Ensure the container takes full height to center the content vertically
      marginTop: "30px",
    },
    descargarText: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#00f",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
    downloadButtonHovered: {
      "& .descargarText": {
        opacity: 1,
      },
    },
    topStyle: {
      display: "flex",
      flexDirection: "row",

      paddingLeft: "50px",

      [theme.breakpoints.down("sm")]: {
        marginTop: "60px",
        display: "flex",
        paddingLeft: "50px",
      },
    },
    highlighted: {
      backgroundColor: "#03829e",
      fontWeight: "bold", // Adjust styles as needed
      color: "white", // Adjust text color as needed
    },
    blueBackground: {
      backgroundColor: "blue",
      fontWeight: "bold",
      color: "white",
    },
    pinkBackground: {
      border: "2px solid #026277" /* Adjust the border style as needed */,
      padding:
        "2px 4px" /* Optional: Add padding for better visual appearance */,
      backgroundColor:
        "white" /* Optional: Add background color to highlight the word */,
      borderRadius: "10px",
    },
    toggleButtonGroup: {
      // background: "white",
      // border: "1px solid   !important",
      borderRadius: "6px !important",
      padding: "5px",
      backgroundColor: "var(--slate-100, #F1F5F9)",
    },
    toggleButton: {
      textTransform: "none !important",
      color: "var(--slate-700, #334155) !important",
      backgroundColor: "transparent",
      border: "0px !important",
      zIndex: 1,
      /* subtle-medium */

      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px" /* 142.857% */,

      "&.Mui-selected": {
        color: "white",
        borderRadius: "6px !important",
        color: "var(--slate-900, #0F172A)",
        /* subtle-medium */

        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px" /* 142.857% */,
        backgroundColor: "white !important",
      },
    },
  };
});
function Transcript({ docInfo, accessToken, user }) {
  const { document_id, selectedTranscript } = useParams();
  const { folder_id, selectedFolder } = useParams();
  // const transcript_id = "cd4cae07-45c2-42c1-b30a-806ed2100342";
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [messages, setMessages] = useState([]); // Move messages state up

  // access query parameters
  const searchParam = queryParams.get("search");
  const [search, setSearch] = useState(searchParam || ""); // Initialize with the search parameter from the URL

  const [transjson, setTransjson] = useState(null);
  const [summarytxt, setsummaryTxt] = useState(null);
  const [correctTranscript, setCorrectTranscript] = useState(false);
  const [speakerList, setspeakerList] = useState({ names: [] });
  const [getHTML, setHtml] = useState(false);


  const [alignment, setAlignment] = React.useState("center");
  const url = document.location.href;
  const [retryButton, setRetryButton] = useState(false);
  const contentRef = useRef(null); // Reference to the content container
  const [searchBarVisible, setSearchBarVisible] = useState(false);

  const [urlTranscript, setUrlTranscript] = useState(""); // State to store thes
  const classes = useStyles();

  let visibleContent;


  useEffect(() => {
    // Function to fetch and decrypt the HTML file
    const fetchHTML = async () => {
      var htmlUrl = docInfo.url_stored.match(/(.*\/)[^\/]*$/)[1];
      try {
        const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
          method: 'POST',
          body: JSON.stringify({ fileUrl: htmlUrl + "summary.html" }),
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` }
        });
        const data = await response.text();
        setsummaryTxt(data);
        setHtml(true)
      } catch (error) {
        console.error('Error fetching decrypted HTML:', error);
      }
    };

    fetchHTML();
  }, [docInfo]);
  useEffect(() => {
    // Function to fetch and decrypt the JSON file
    const fetchJSON = async () => {
      var jsonUrl = docInfo.url_stored.match(/(.*\/)[^\/]*$/)[1];

      try {
        const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
          method: 'POST',
          body: JSON.stringify({ fileUrl: jsonUrl + "transcript.json" }),
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` }
        });
        const data = await response.json();
        setTransjson(data);
      } catch (error) {
        console.error('Error fetching decrypted JSON:', error);
      }
    };

    fetchJSON();
  }, [docInfo]);


  if (alignment == "left") {
    visibleContent = (
      <>
        {summarytxt ? (
          // <Typography align="justify" className={classes.text}>
          //   {summarytxt}
          // </Typography>
          <Typography align="justify" className={classes.text} variant="body1">
            {getHTML ? (
              <div dangerouslySetInnerHTML={{ __html: summarytxt }}></div>
            ) : (
              <>
                HELLO
              </>
            )}
          </Typography>
        ) : (
          <Spinner left="40%" />
        )}
      </>
    );
  } else if (alignment == "center") {
    visibleContent = (
      <Typography className={classes.text}>
        <Typography align="justify" className={classes.root}>
          <div ref={contentRef}>
            {transjson &&
              correctTranscript !== "This transcript is not finish" ? (
              transjson.map((message, messageIndex) => (
                <div key={messageIndex}>
                  {messageIndex > 0 && (
                    <div className={classes.separator}></div>
                  )}
                  <div className={classes.speaker}>{message.speaker}:</div>

                  {message.words ? (
                    <div className={classes.message}>
                      {message.words.map((word, wordIndex) => (
                        <span
                          key={wordIndex}
                          id={`word-${messageIndex}-${wordIndex}`}
                          onClick={() => handleOperations.handleWordClick(word)}
                          onMouseUp={handleOperations.handleTextHighlight}
                          className="word" // Apply the "word" class here
                        >
                          {word.word}{" "}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className={classes.message}>{message.text}</div>
                  )}
                </div>
              ))
            ) : (
              <Spinner left="40%" />
            )}
          </div>
        </Typography>

      </Typography>
    );
  } else {
    visibleContent = (
      <Typography className={classes.text}>
        <Typography align="justify" className={classes.root}>
          <div ref={contentRef}>
            {transjson &&
              correctTranscript !== "This transcript is not finish" ? (
              transjson.map((message, messageIndex) => (
                <div key={messageIndex}>
                  {messageIndex > 0 && (
                    <div className={classes.separator}></div>
                  )}
                  <div className={classes.speaker}>{message.speaker}:</div>

                  {message.words ? (
                    <div className={classes.message}>
                      {message.words.map((word, wordIndex) => (
                        <span
                          key={wordIndex}
                          id={`word-${messageIndex}-${wordIndex}`}
                          onClick={() => handleOperations.handleWordClick(word)}
                          onMouseUp={handleOperations.handleTextHighlight}
                          className={classes.word}
                        >
                          {word.word}{" "}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className={classes.message}>{message.text}</div>
                  )}
                </div>
              ))
            ) : (
              <Spinner left="40%" />
            )}
          </div>
        </Typography>

      </Typography>
    );
  }
  useEffect(() => {



    setUrlTranscript(docInfo['url_stored'])
    setspeakerList(docInfo['info_transcription']['speakers'])


  }, [document_id]);

  useEffect(() => {
    const getJsonTrans = async (urlTranscript) => {
      return await handleOperations.getJson(

        urlTranscript
      );
    };

    // getJsonTrans(urlTranscript).then((res) => {
    //   setTransjson(res);
    // });

    const getSummaryText = async (setHtml, urlTranscript) => {
      return await handleOperations.getSummary(
        setHtml,
        urlTranscript
      );
    };

    // getSummaryText(setHtml, urlTranscript).then((res) => {
    //   setsummaryTxt(res);
    // });
  }, [document_id, urlTranscript, accessToken, user]);
  return (
    <>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div></div>{" "}
          {/* Empty div to keep space on the left, balancing out the space on the right for the Button */}
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            variant="outlined"
            aria-label="outlined button group"
            value={alignment}
            exclusive
            onChange={(e, newValue) => {
              if (newValue !== null) {
                setAlignment(newValue);
              }
            }}
          >
            <ToggleButton className={classes.toggleButton} value="left">
              {t('summary')}
            </ToggleButton>
            <ToggleButton className={classes.toggleButton} value="center">
              {t('transcription')}
            </ToggleButton>

          </ToggleButtonGroup>
        </div>
      </Box>
      <Box className={classes.agenda}>
        <Scrollbars>
          <Paper elevation={0} style={{ width: "100%" }}>
            <div ref={contentRef} style={{ padding: 0, margin: 0 }}>
              {visibleContent}
            </div>
          </Paper>
        </Scrollbars>
      </Box>

    </>
  );
}

export default Transcript;
