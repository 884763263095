// VideoDialog.js
import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

const VideoDialog = ({ open, handleClose, typeVideo }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="video-dialog-title"
            fullWidth
            maxWidth="md"
            PaperProps={{
                sx: {
                    height: 'auto', // Set a fixed or auto height for the dialog
                },
            }}
        >
            {/* <video open={open}
                onClose={handleClose} style={{ width: '100%', height: '100%' }} controls>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            {typeVideo == "memorise" ? (

                <div style={{ "position": "relative", "paddingBottom": "53.125%", "height": 0 }}>
                    <iframe src="https://www.loom.com/embed/4e044455fc524b50b3d7410bed46f3f5?sid=47f4572d-e2fd-44f3-adac-d339ee7cef86" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ "position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%" }}>
                    </iframe>
                </div>

            ) : (
                <div style={{ "position": "relative", "paddingBottom": "53.125%", "height": 0 }}><iframe src="https://www.loom.com/embed/b2a481c57c424c14b75332a8ef25dba1?sid=78689501-7db7-494a-b3a8-05c66fabd51e" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ "position": "absolute", "top": 0, "left": 0, "width": "100%", "height": "100%" }}></iframe></div>
            )}
        </Dialog >
    );
};

export default VideoDialog;
