import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography, Modal, Box, TextField, DialogContent, Dialog, DialogTitle } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { Snackbar, Link, Slide } from '@mui/material';
import Alert from '@mui/material/Alert';


const useStyles = makeStyles((theme) => {
    return {

        dialogTitle: {

            '& h2': {
                fontWeight: "600 !important",
                paddingBottom: 0,
            }
        },
        dialog: {
            '& .MuiPaper-root': {
                borderRadius: "20px !important",
                width: "350px",

            }
        },
        backdrop: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light semi-transparent background
        },
        textField: {
            border: '1px solid #ddd', // Border to make it more visible
            borderRadius: '4px', // Rounded corners
            padding: '10px', // Padding inside the input field
            transition: 'all 0.3s ease', // Smooth transition for hover effect
            backgroundColor: '#e0e0e0', // Darker background on hover

        },

    }
});
function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}
const SmallAlert = ({ message, duration = 5000, onClose, progressBar = false, linkinMessage, typeAlert }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [progress, setProgress] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(true);  // State to manage Snackbar visibility

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        let timer;


        if (isVisible && progressBar) {
            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const increment = Math.random() * 4; // This determines the speed and variability of the progress
                    const newProgress = oldProgress + increment;
                    if (newProgress >= 100) {
                        // When progress exceeds 100, restart from 0 immediately
                        return 0;
                    } else {
                        return newProgress;
                    }
                });
            }, 500);

            return () => clearInterval(interval); // Clean up the interval when the component unmounts or dependencies change
        }
        if (!progressBar) {
            const hideTimer = setTimeout(() => {
                setIsVisible(false);
                if (onClose) onClose();
            }, duration);

            return () => clearTimeout(hideTimer);
        }

        // return () => clearInterval(timer);
    }, [isVisible, progressBar, duration, onClose]);

    if (!isVisible) return null;

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return; // Prevents closing the Snackbar when clicking outside
        }
        setOpenSnackbar(false);  // Close the Snackbar
    };

    // Render for non-progress cases
    const renderAlert = () => (
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={handleCloseSnackbar}
                severity="info" // Changed severity from 'success' to 'info'
                variant="outlined" // Changed variant from 'filled' to 'outlined'
                sx={{
                    width: '100%',
                    backgroundColor: 'white', // ensure background is white
                    color: '#026277', // text color is black
                    border: "1px solid #026277", // border color
                    fontSize: "12px",
                    '& .MuiAlert-icon': { color: '#026277' } // Ensure the icon matches the text color

                }}
            >
                <span>
                    {message}
                    {linkinMessage && (
                        <Link href={`https://app.typedai.com/exam/${linkinMessage}`}
                            target="_blank" style={{ marginLeft: 5, color: "#026277", fontWeight: "bold" }}>
                            {typeAlert}
                        </Link>
                    )}

                </span>
            </Alert>
        </Snackbar >

        // <div style={{
        //     position: 'fixed',
        //     top: '20%',
        //     left: '50%',
        //     transform: 'translate(-50%, -50%)',
        //     backgroundColor: 'white',
        //     padding: '10px',
        //     width: 'auto',
        //     zIndex: 2000,
        //     border: '1px solid #ccc',
        //     fontWeight: 400,
        //     textAlign: 'center',
        //     borderRadius: '10px',
        //     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        // }}>
        //     {Array.isArray(message) ? (
        //         <List>
        //             {message.map((item, index) => (
        //                 <ListItem key={index}>
        //                     <ListItemText primary={item} />
        //                 </ListItem>
        //             ))}
        //         </List>
        //     ) : (
        //         <Typography >{message}</Typography>
        //     )}
        // </div>
    );

    // Render progress bar within a modal
    const renderProgressBarModal = () => (
        <Dialog
            open={isVisible}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setIsVisible(false);
                    if (onClose) onClose();
                }
            }}
            disableEscapeKeyDown // Prevent closing on escape key
            className={classes.dialog} BackdropProps={{
                classes: {
                    root: classes.backdrop
                }
            }} PaperProps={{
                style: {
                    width: "350px", // Set the width dynamically
                },
            }}
            style={{ overflowX: 'hidden' }}
        >
            <DialogTitle

                sx={{
                    fontWeight: "600 !important",
                    paddingBottom: 0,
                    '& .MuiTypography-root': { // Targets the Typography component inside DialogTitle
                        fontWeight: "600 !important",
                    },
                    fontSize: "14px"
                }}
            >
                {t("subiendo")}

            </DialogTitle>

            <DialogContent>
                <TextField
                    style={{ paddingBottom: "20px", width: "100%" }}
                    id="outlined-basic"
                    variant="standard"
                    inputProps={{ maxLength: 30 }}
                    InputProps={{
                        disableUnderline: true,
                        classes: { input: classes.textField }, // Apply the custom class here

                        style: { fontSize: "14px", color: "black", }
                    }}
                    InputLabelProps={{
                        style: { fontSize: "10px" }
                    }}
                    value={message}
                    // Add custom styling for the outlined variant if necessary
                    disabled
                />
                <div style={{ marginLeft: 30, marginRight: 30 }}>

                    <Box sx={{ width: '100%', my: 2 }}>
                        <Box sx={{
                            width: '100%',
                            height: '10px',
                            bgcolor: '#8db8c2',
                            borderRadius: '6px',
                            overflow: 'hidden' // Ensure the inner box doesn't overflow the rounded corners
                        }}>
                            <Box sx={{
                                width: `${progress}%`, // This represents the progress
                                height: '100%',
                                bgcolor: '#026277', // Color of the progress indicator
                            }} />
                        </Box>
                        <Typography style={{ fontWeight: 500, color: "gray", fontSize: "12px" }}>{t("process_take_time")}</Typography>
                    </Box>
                </div>
            </DialogContent>
        </Dialog >
    );

    return progressBar ? renderProgressBarModal() : renderAlert();
};

export default SmallAlert;
