import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Container, CardContent, Typography, CardMedia, Grid } from '@mui/material';
import firstTopicImage from './images/firstTopic.svg';
import firstExamImage from './images/firstExam.svg';
import firstSummaryImage from './images/firstSummary.svg';
import StudyCard from './StudyCards';
import { useAuth } from '../Auth/Authhandle';
import { fetchUserFolders } from '../Api/FolderApi';
import { useSubscription } from '../Context/SubscriptionContext';





const WelcomeOposicion = ({ }) => {
    const navigate = useNavigate();
    const [folders, setFolders] = useState([]);
    const { accessToken, user } = useAuth();
    const { userData } = useSubscription();
    const location = useLocation();

    async function fetchDataAndUpdate() {
        if (accessToken && user) {
            fetchUserFolders(accessToken, "GET", null, user).then(fetchFolders => {
                setFolders(fetchFolders);
            }).catch(error => {
                console.error("Error fetching folders: ", error);
            });
        }
    }

    useEffect(() => {
        fetchDataAndUpdate(); // Set loading to false once data is fetched
    }, [accessToken, user]);

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                backgroundColor: 'rgba(107, 189, 189, 0.20) !important', // Use !important to override Material-UI default hover styles if necessary
                width: "100%",
                maxWidth: "unset !important"
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom style={{ color: "#026277", fontSize: "40px", fontWeight: "bold" }}>
                <span role="img" aria-label="wave">👋</span> Hey {userData.name}!
            </Typography>
            <Typography style={{ color: "black", fontSize: "20px", fontWeight: "normal" }}>
                Typed te ayudará a preparar tu oposición de la mejor manera
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', width: "100%" }}>
                <Grid container spacing={3} justifyContent="center" gap="20px">
                    {/* <Grid item>
                        <StudyCard
                            title="Memoriza tu primer tema"
                            image={firstTopicImage}
                            handleClick={() => { navigate("/memorise") }}
                        />
                    </Grid> */}
                    <Grid item>
                        <StudyCard
                            title="Haz tu primer examen"
                            image={firstExamImage}
                            handleClick={() => {
                                navigate(`/${folders[0].guid}/${folders[0].document_guids[0]}`, {
                                    state: { shouldCreateExam: true, from: location.pathname } // Pass a boolean state
                                });
                            }}

                        />
                    </Grid>
                    <Grid item>
                        <StudyCard
                            title="Resume tu primer documento"
                            image={firstSummaryImage}
                            handleClick={() => {
                                navigate(`/${folders[0].guid}/${folders[0].document_guids[0]}`, {
                                    state: { shouldCreateSummary: true, from: location.pathname } // Pass a boolean state
                                });
                            }}

                        />
                    </Grid>
                </Grid>
                <Typography style={{ fontSize: '12px', color: 'gray', marginTop: '60px', cursor: "pointer" }} onClick={() => navigate("/")}>
                    Explorar por mi cuenta
                </Typography>
            </div>
        </Container>
    );
}

export default WelcomeOposicion;
