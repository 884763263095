import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel, Typography, TextField, Autocomplete } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fetchUserFolders } from "../../Api/FolderApi";
import { useAuth } from "../../Auth/Authhandle";
import { useTranslation } from 'react-i18next';

// const BACKEND_URL = "localhost";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400, // Adjust the width as needed
    },
    selectEmpty: (props) => ({
        marginTop: theme.spacing(2),
        border: '1px solid gray',
        borderRadius: '6px',
        '&:before': {
            borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none', // Remove underline on hover
        },
        '& .MuiSelect-selectMenu': {
            color: props.value === "" ? '#747D8C' : 'inherit', // Conditional color
            marginLeft: 10
        },
    }),
    placeholder: {
        color: '#747D8C',
        marginLeft: '3px !important'
    },
    formControl: {
        minWidth: 120,
        width: '100%',
        marginBottom: "0px",
        marginTop: "10px",
    },
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    menuItem: (props) => ({
        fontSize: props.fontSizeItems,
        borderRadius: '8px',
    }),
    select: (props) => ({
        height: '30px',
        '& .MuiSelect-selectMenu': {
            fontSize: props.fontSizeItems,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
        },
    }),
    inputLabel: (props) => ({
        fontSize: props.fontSizeItems,
        transform: 'translate(10px, 10px) scale(1)',
    }),
    shrinkLabel: {
        transform: 'translate(10px, -6px) scale(0.75)', // Position of the label when shrunk
        // width: "50px"
    },
    textFieldFolder: {
        backgroundColor: '#f5f5f5', // Light background color
        border: '1px solid #ddd', // Border to make it more visible
        borderRadius: '4px', // Rounded corners
        padding: '5px', // Padding inside the input field
        transition: 'all 0.3s ease', // Smooth transition for hover effect
        '&:hover': {
            backgroundColor: '#e0e0e0', // Darker background on hover
        },
        '&:focus-within': {
            backgroundColor: '#fff', // White background when focused
            borderColor: '#026277', // Change border color when focused
        },
        fontSize: "12px",
        width: "290px"
    },

}));

export default function ShowFolders({ value, setValue, handleNewFolder, sizeTextFolder = 12, fontSizeItems = '10px', onFoldersFetched, fontWeightNormal = false }) {
    const [folders, setFolders] = useState([]);
    const { accessToken, user } = useAuth(); // Use the hook at the top level
    const { t } = useTranslation();
    const [newFolderName, setNewFolderName] = useState("")
    const [openEditor, setOpenEditor] = useState(false)


    const handleChange = (event) => {
        setValue(event.target.value);
        setNewFolderName("")
    };

    const handleNewFolderNameChange = (event) => {
        setNewFolderName(event.target.value);

    };

    const classes = useStyles({ value, fontSizeItems });

    async function fetchDataAndUpdate() {
        if (accessToken && user) {
            try {
                const fetchFolders = await fetchUserFolders(accessToken, "GET", null, user);
                setFolders(fetchFolders);
                if (onFoldersFetched) {
                    onFoldersFetched(fetchFolders); // Pass folders data to parent
                }
            } catch (error) {
                console.error("Error fetching folders: ", error);
            }
        }
    }

    useEffect(() => {
        fetchDataAndUpdate();
    }, [accessToken, user]);

    useEffect(() => {
        if (newFolderName) {

            handleNewFolder(newFolderName);
            setValue("")
        }
    }, [newFolderName, handleNewFolder]);
    const handleKeyDown = (event) => {
        if (event.key === '/') {
            event.preventDefault();
        }
    };
    return (
        <>
            <Typography style={{
                color: "black",

                fontSize: sizeTextFolder,
                fontStyle: "normal",
                fontWeight: fontWeightNormal ? "normal" : 600,
                lineHeight: "normal",
            }}>{t("choose_folder")}</Typography>
            <FormControl className={classes.formControl} variant="outlined">
                <InputLabel
                    className={classes.inputLabel}
                    classes={{ shrink: classes.shrinkLabel }}
                    id="first-dropdown-label"
                >
                    {t("select_subject")}
                </InputLabel>
                <Select
                    labelId="first-dropdown-label"
                    id="first-dropdown"
                    value={value}
                    onChange={handleChange}
                    className={classes.select}
                    label={t("select_subject")}
                >

                    {folders.map((item, i) => (
                        <MenuItem className={classes.menuItem} value={item} key={i}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <div style={{ cursor: 'pointer', display: 'flex', flexDirection: "column", marginTop: "5px" }} >
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{ marginRight: 8 }}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9L9 9V4C9 3.44772 9.44772 3 10 3Z"
                            fill="black"
                        />
                    </svg>
                    <Typography style={{ fontSize: sizeTextFolder }} onClick={() => setOpenEditor(!openEditor)}>{t('create_new_folder')}</Typography>
                </div>
                {openEditor && (
                    <TextField
                        fullWidth

                        variant="standard"
                        placeholder={t('create_new_folder')}
                        InputProps={{
                            disableUnderline: true,
                            // style: { fontSize: "10px" },
                            classes: { input: classes.textFieldFolder }, // Apply the custom class here

                        }}
                        InputLabelProps={{
                            style: { fontSize: "10px" }
                        }}
                        style={{ padding: "3px" }}
                        value={newFolderName}
                        onChange={handleNewFolderNameChange}
                        onKeyDown={handleKeyDown} // Add the onKeyDown event handler

                    />
                )}

            </div >
            {/* <Select
                value={value}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
            >
                <MenuItem value="" disabled className={classes.placeholder}>
                    {t("select_folder")}
                </MenuItem>
                {folders.map((item, i) => (
                    <MenuItem value={item} key={i}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select> */}

        </>
    );
}
