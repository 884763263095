import React from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 8px;
    color:#026277
  }

  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: #026277;
  }

  &.MuiCheckbox-colorSecondary:hover {
    background-color: rgba(2, 98, 119, 0.1);
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`;

export default StyledCheckbox;
