import React from 'react';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tab: {
        minWidth: 100,
        textTransform: "none",
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

const TabNavigation = ({ selectedTab, setSelectedTab, num_docs, num_exams, num_memorise }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Tabs
            value={selectedTab}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
        >
            <Tab label={`${t("apuntes")} (${num_docs.length})`} className={classes.tab} />
            <Tab label={`${t("exams")} (${Object.keys(num_exams).length})`} className={classes.tab} />
            {/* <Tab label={`${t("study_session")} (${num_memorise.length})`} className={classes.tab} /> */}
            {/* <Tab label="Flashcards" className={classes.tab} /> */}
        </Tabs>
    );
};

export default TabNavigation;
