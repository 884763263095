import { makeStyles } from "@material-ui/core/styles";

const useTableFolderStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: theme.spacing(2),
        overflowY: 'auto', // Enable vertical scrolling
        height: '100%', // Adjust the height as needed
    },

}));

export default useTableFolderStyles;
