
import { fetchUserInfo, fetchUserSettings } from '../Api/UserApi'; // Import the fetchUserInfo function
const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;

export const handleNameChange = (event, setFullName, fullName, token) => {
    setFullName(event.target.value);
    // updateName(token, event.target.value, setFullName);
};

export const updateAccount = (token, selectedValueShare, selectedValueEvents, fullName, setFullName, setIsChanged, user) => {
    updateName(token, fullName, setFullName, user)
    // updateName(token, event.target.value, setFullName);

    updateSettings(token, selectedValueShare, selectedValueEvents, user)
    setIsChanged(false);


};

export const updateName = async (token, editedName, setFullName, user) => {
    try {
        // Make an API call to update the user's name

        let surname = "";
        const name = editedName.split(' ');

        // If there are more than one words, assign the rest as 'surname'
        if (name.length > 1) {

            surname = name.slice(1).join(' ');
        }

        const response = await fetchUserInfo(token, "PUT", { "name": name[0], "surname": surname }, user);// Replace with your actual API call
        if (response.status === 200) {
            // Update the state with the new name
            setFullName(editedName);
        } else {
            // Handle error response from the API
            console.error("Failed to update name.");
        }
    } catch (error) {
        console.error("Error while updating name:", error);
    }
};


export const updateSettings = async (token, selectedValueShare, selectedValueEvents, user) => {
    try {
        // Make an API call to update the user's name



        const response = await fetchUserSettings(token, "PUT", { "record_automatic": selectedValueEvents, "share_automatic": selectedValueShare }, user);// Replace with your actual API call
        if (response.status != 200) {

            // Handle error response from the API
            console.error("Failed to update name.");
        }
    } catch (error) {
        console.error("Error while updating name:", error);
    }
};

export const deleteAccount = async (token, user) => {
    try {
        // Make an API call to update the user's name


        const response = await fetchUserInfo(token, "DELETE", {}, user);// Replace with your actual API call
        if (response.status === 200) {
            // Update the state with the new name
            // window.location.href = 'www.typedai.com/waitlist'
            window.location.href = `https://${REACT_FRONT_END}/login`;
        } else {
            // Handle error response from the API
            console.error("Failed to update name.");
        }
    } catch (error) {
        console.error("Error while updating name:", error);
    }
};
export const handleChangeShare = (event, setSelectedValueShare) => {
    setSelectedValueShare(event.target.value);
};

export const handleChangeEvents = (event, setSelectedValueEvents) => {
    setSelectedValueEvents(event.target.value);
};