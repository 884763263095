import { List, ListItem, ListItemIcon, ListItemText, TextField, Box, Typography, Card, CardContent, Button } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchExamId, fetchFolderExams } from '../Api/ExamsApi'; // Import the fetchUserInfo function
import DocumentsTable from "../Documents/TableDocs";
import ExamButtonSVG from './Components/ExamButtonSVG'; // Adjust the path to where you saved the SVG component
import useFolderContentStyles from "./Styles/useFolderContentStyles";

import { fetchUserFolder } from "../Api/FolderApi";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import { WebSocketContext } from '../WebSocketProvider';
import ExamList from "../NewComponents/ListExams";
import cardsStyles from "../cardsStyles";
import TabNavigation from "./TabNavigation";
import FolderCard from "../Home/Components/FolderCard";
import DocumentsGrid from "./Components/DocumentsGrid";
import CardComponent from "../NewComponents/CardComponent";
import ExamGridView from "../Exam/ExamGridView";
import { fetchFolderMemorise } from "../Api/MemoriseApi";
import MemoriseGridView from "../Memorise/MemoriseGridView";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const MAX_TITLE_LENGTH = 255; // Adjust the maximum title length as needed

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

function FolderContent({ listDocuments, folder, updateListDocuments, accessToken, user, handleUploadFile, handleCreateExam, setListExams, listExams, handleClickOpenNotReady, setListMemorise, listMemorise }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [explanationFolder, setExplanationFolder] = useState("");
  const classes = useFolderContentStyles();
  const classesCards = cardsStyles();
  const [showAlert, setShowAlert] = useState(false)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [message, setMessage] = useState("")
  const [editMode, setEditMode] = useState(null); // Tracks the exam being edited
  const [editName, setEditName] = useState(''); // Temporarily holds the new name during edit
  const [autosaveTimeout, setAutosaveTimeout] = useState(null);

  const [selectedTab, setSelectedTab] = useState(0);


  const toggleEditMode = (event, guid, name) => {
    event.stopPropagation(); // Prevent triggering any parent click handlers

    if (editMode === guid) {
      // Exiting edit mode, could trigger update logic here
      setEditMode(null);
      setEditName('');
    } else {
      setEditMode(guid);
      setEditName(name);
    }
  };


  const updateFolderDesc = useCallback(debounce((newValue) => {
    if (accessToken && user) {
      try {

        const updateDescription = fetchUserFolder(accessToken, "PUT", folder.guid, { "description": newValue, "type": "None" }, user);

      } catch (error) {
        console.error(error);
      }
    }
  }, 1000), [accessToken, user, folder]); // Ensure to include all external variables in dependency array
  const handleChangeDescription = (e) => {
    const newValue = e.target.value;
    setExplanationFolder(newValue)

    updateFolderDesc(newValue);
  };
  // useEffect(() => {
  //   fetchExams();
  // }, [fetchExams]);

  async function fetchExams() {
    if (accessToken && user && folder) {
      if (folder.description == "") {

        setExplanationFolder(t("description_folder"))
      } else {
        setExplanationFolder(folder.description)

      }

      try {

        const listExamsFetched = await fetchFolderExams(accessToken, "GET", folder.guid, user,);
        setListExams(listExamsFetched)
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchMemorise() {
    if (accessToken && user && folder) {


      try {

        const listMemoriseFetched = await fetchFolderMemorise(accessToken, "GET", folder.guid, user,);
        setListMemorise(listMemoriseFetched)
      } catch (error) {
        console.error(error);
      }
    }
  }




  const updateExamInList = (examUpdate) => {
    setListExams(currentExams => {
      const updatedExams = { ...currentExams };
      if (updatedExams[examUpdate.guid]) {
        // Found the exam, update it
        updatedExams[examUpdate.guid] = { ...updatedExams[examUpdate.guid], ...examUpdate };
      }
      return updatedExams;
    });
  };

  const { messageCallback } = useContext(WebSocketContext);

  // useEffect(() => {
  //   if (messageCallback) {
  //     switch (messageCallback.type) {

  //       case "documentsUpdate":

  //         setShowAlert(false);

  //         break;


  //       default:
  //         console.log("Unhandled message type:", messageCallback.document);
  //     }
  //   }
  // }, [messageCallback]);
  // useEffect(() => {
  //   if (messageCallback) {

  //     // Do something with messageCallback
  //     updateExamInList((messageCallback));  // Function to update exam in the list
  //   }
  // }, [messageCallback]);


  useEffect(() => {


    fetchExams(); // Call the fetchData function
    fetchMemorise()

  }, [folder, accessToken, user])
  const handleItemClick = (guid, isActive) => {
    if (!isActive) {
      // You can add any action here if needed when the item is not active/loading
      setMessage("Aún se está creando el examen. Estará listo en unos minutos...")
      setShowAlert(true)
      return; // Early return to prevent action
    }
    // Redirect if the item is active
    navigate(`exam/${guid}`);
  };
  // Inside FolderContent component
  // const addNewExam = (newExam) => {
  //   setListExams(currentExams => [...currentExams, newExam]);
  // };
  const addNewExam = (newExamObject) => {
    setListExams(currentExams => ({
      ...currentExams,
      ...newExamObject // Merge the new exam object into the current list
    }));
  };
  const handleRemoveExam = async (exam_id) => {
    // Immediately filter out the exam optimistically

    try {
      setListExams(currentExams => {
        // Convert the object to an array of entries, filter out the exam, and convert back to an object
        const filteredEntries = Object.entries(currentExams).filter(([guid, examDetails]) => guid !== exam_id);
        return Object.fromEntries(filteredEntries);
      });      // Perform the deletion request
      await fetchExamId(accessToken, "DELETE", exam_id, user);
      // No need to do anything here on success, as we've already updated the UI

    } catch (error) {
      console.error("this error", error);
      // Handle error - e.g., show an alert or undo the optimistic update
      setMessage("Error removing exam. Please try again.");
      setShowAlert(true);
      // Optionally, fetch the exams again or revert the change in the state
    }
  };
  const [editableTitle, setEditableTitle] = useState("")
  const handleRemoveRow = (exam_id) => {
    setListExams(currentExams => {
      const filteredEntries = Object.entries(currentExams).filter(([guid]) => guid !== exam_id);
      return Object.fromEntries(filteredEntries);
    });
  };

  return (
    <>

      {showAlert &&
        <SmallAlert
          message={message}
          onClose={() => setShowAlert(false)}
        />
      }
      {
        <NeedCredits open={showUpgrade} message={message}

          onClose={() => setShowUpgrade(false)} />
      }



      <div className={classes.container}>
        <CardComponent handleCreateExam={handleCreateExam} handleClickOpenNotReady={handleClickOpenNotReady} />



      </div>

      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <TabNavigation selectedTab={selectedTab} setSelectedTab={setSelectedTab} num_docs={listDocuments} num_exams={listExams} num_memorise={listMemorise} />
        {selectedTab === 0 && (
          listDocuments.length > 0 && folder ? (
            <div style={{ marginTop: "10px" }} className={classes.gridContainer}>
              {listDocuments.map((element, index) => (
                <DocumentsGrid key={index} element={element} folder_id={folder.guid} document_id={element.guid} token={accessToken} user={user} updateListDocuments={updateListDocuments} />
              ))}
            </div>
          ) : (
            <div style={{ margin: "auto" }}>
              <Box className={classes.boxImg}>
                <div style={{ textAlign: 'center' }}>
                  <img src={require("./img/noContent.svg").default} alt="No Documents" className={classes.imgEmpty} />
                </div>
                <Typography style={{ fontSize: "14px" }}>{t("upload_first_notes")}</Typography>
                <Button
                  onClick={handleUploadFile}
                  style={{
                    alignSelf: 'center',
                    textTransform: 'none',
                    display: 'flex',
                    marginTop: '10px',
                    borderRadius: "8px",
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    color: "#026277",
                    backgroundColor: "#E2F1F1",
                    fontSize: "14px",
                    paddingRight: "12px"
                  }}
                  className="home-step"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style={{ marginRight: '8px' }} >
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 3.5C10.5523 3.5 11 3.94772 11 4.5V9.5H16C16.5523 9.5 17 9.94772 17 10.5C17 11.0523 16.5523 11.5 16 11.5H11V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V11.5H4C3.44772 11.5 3 11.0523 3 10.5C3 9.94771 3.44772 9.5 4 9.5L9 9.5V4.5C9 3.94772 9.44772 3.5 10 3.5Z" fill="#025365" />
                  </svg>
                  {t("upload_notes")}
                </Button>
              </Box>
            </div>
          )
        )}
        {selectedTab === 1 && <div style={{ margin: "auto" }}>
          {listExams && Object.keys(listExams).length > 0 ? (
            <div style={{ marginTop: "10px" }} >
              {/* {listExams.map((element, index) => (
                <ExamGridView key={index} element={element} folder_id={folder.guid} document_id={element.guid} token={accessToken} user={user} />
              ))} */}
              <ExamGridView rawData={listExams} accessToken={accessToken} user={user} onRemoveRow={handleRemoveRow} // Pass the callback function
              />


            </div>
          ) : (
            <Box className={classes.boxImg}>
              <div style={{ textAlign: 'center' }}>
                <img src={require('./img/emptyExams.svg').default} alt="Preview Exam" className={classes.imgEmpty} />
              </div>
              <Typography style={{ fontSize: "14px" }}>{t("create_first_exam")}</Typography>
              <Button
                onClick={handleCreateExam}
                style={{
                  alignSelf: 'center',
                  textTransform: 'none',
                  display: 'flex',
                  marginTop: '10px',
                  borderRadius: "8px",
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                  color: "#026277",
                  backgroundColor: "#E2F1F1",
                  fontSize: "14px",
                  paddingRight: "12px"
                }}
                className="home-step"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style={{ marginRight: '8px' }}>
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 3.5C10.5523 3.5 11 3.94772 11 4.5V9.5H16C16.5523 9.5 17 9.94772 17 10.5C17 11.0523 16.5523 11.5 16 11.5H11V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V11.5H4C3.44772 11.5 3 11.0523 3 10.5C3 9.94771 3.44772 9.5 4 9.5L9 9.5V4.5C9 3.94772 9.44772 3.5 10 3.5Z" fill="#025365" />
                </svg>
                {t("create_exam")}
              </Button>
            </Box>)}
        </div>}
        {selectedTab === 2 && <div style={{ margin: "auto" }}>
          {listMemorise && Object.keys(listMemorise).length > 0 ? (
            <div style={{ marginTop: "10px" }} >
              {/* {listExams.map((element, index) => (
                <ExamGridView key={index} element={element} folder_id={folder.guid} document_id={element.guid} token={accessToken} user={user} />
              ))} */}
              <MemoriseGridView rawData={listMemorise} accessToken={accessToken} user={user} onRemoveRow={handleRemoveRow} // Pass the callback function
              />


            </div>
          ) : (
            <Box className={classes.boxImg}>
              <div style={{ textAlign: 'center' }}>
                <img src={require('./img/noMemoriseFolder.svg').default} alt="Preview Exam" className={classes.imgEmpty} />
              </div>
              <Typography style={{ fontSize: "14px" }}>{t("create_first_memorise")}</Typography>
              <Button
                onClick={handleCreateExam}
                style={{
                  alignSelf: 'center',
                  textTransform: 'none',
                  display: 'flex',
                  marginTop: '10px',
                  borderRadius: "8px",
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                  color: "#026277",
                  backgroundColor: "#E2F1F1",
                  fontSize: "14px",
                  paddingRight: "12px"
                }}
                className="home-step"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style={{ marginRight: '8px' }}>
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 3.5C10.5523 3.5 11 3.94772 11 4.5V9.5H16C16.5523 9.5 17 9.94772 17 10.5C17 11.0523 16.5523 11.5 16 11.5H11V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V11.5H4C3.44772 11.5 3 11.0523 3 10.5C3 9.94771 3.44772 9.5 4 9.5L9 9.5V4.5C9 3.94772 9.44772 3.5 10 3.5Z" fill="#025365" />
                </svg>
                {t("create_memorise")}
              </Button>
            </Box>)}
        </div>}
      </div>

      {/* <DocumentsTable listDocuments={listDocuments} updateListDocuments={updateListDocuments} accessToken={accessToken} user={user} handleUploadFile={handleUploadFile} />

      <div className={classes.uploadSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ fontWeight: 600, fontSize: "14px", marginTop: "15px" }}>{t("prepare_exam")}</Typography>

        </div>
        {
          listExams && Object.keys(listExams).length > 0 ? (

            <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />


          ) : (<>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: "5px",
              height: '100%',
            }}>
              <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/preview_exam.svg" alt="Preview Exam" />
              <Typography style={{ fontSize: "12px" }}>{t("exams_dont_exist")}</Typography>
            </div>
          </>
          )}


      </div > */}
    </>
  );
}

export default FolderContent;
