import React, { useState } from "react";
import {
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Divider
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QuizIcon from "@mui/icons-material/Quiz";

const DropdownMenu = ({ option2 }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                    backgroundColor: "#6BBDBD",
                    color: "white",
                    textTransform: "none",
                    borderRadius: "6px",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "2px",
                    "&:disabled": {
                        backgroundColor: "#B0B0B0", // Gray color for the disabled state
                        color: "#FFFFFF", // White text for the disabled state
                    },
                    "&:hover": {
                        backgroundColor: "#6BBDBD", // Keep the hover background color same as normal state
                        color: "white",
                    },
                    padding: "4px 11px !important",
                }}
                endIcon={<ArrowDropDownIcon />}
            >
                Ponte a prueba
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                componentsProps={{
                    paper: {
                        sx: {
                            borderRadius: "8px",
                            boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
                            backgroundColor: "#f9f9f9",
                            padding: "0",
                            marginTop: "8px",
                        },
                    },
                }}
            >
                {/* <MenuItem
                    onClick={() => {
                        navigate("/memorise");
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <LibraryBooksIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography
                            variant="inherit"
                            style={{
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "gray",
                            }}
                        >
                            Memorizar
                        </Typography>
                    </ListItemText>
                </MenuItem>
                <Divider /> */}
                <MenuItem
                    onClick={() => {
                        option2();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <QuizIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography
                            variant="inherit"
                            style={{
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "gray",
                            }}
                        >
                            Crear examen
                        </Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default DropdownMenu;
