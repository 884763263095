import { makeStyles } from '@material-ui/core/styles';

const useFolderContentStyles = makeStyles((theme) => ({
    uploadSection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end", // Ensures the content is at the bottom
    },
    positionImage: {
        position: "relative",
        display: "flex",
        justifyContent: "space-evenly",
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.4)',
            transition: 'transform 0.2s ease-in-out',
            borderRadius: '50%',
        },
    },
    boxStyle: {
        border: '2px solid #026277',
        borderRadius: '20px',
        // background: '#E2F1F1',
        padding: '10px',
        position: 'relative',
        height: '70px', // Adjust height as needed
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: "200px",
        gap: "20px",
        // boxShadow: "1px 1px",
        cursor: "pointer"
    },
    boxImg: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "100px",
        '@media (max-height: 800px)': {
            marginTop: "30px",
        },
    },
    examTitle: {
        fontSize: '12px !important', // Use important to override any existing styles
    },
    // container: {
    //     display: "grid",
    //     gridTemplateColumns: "repeat(3, 1fr)",
    //     gridGap: "20px",
    //     alignItems: "start",


    // },
    container: {
        display: "flex",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "20px",
        alignItems: "start",
        justifyContent: "center"


    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: theme.spacing(2),
        overflowY: 'auto', // Enable vertical scrolling
        height: '100%', // Adjust the height as needed
    },
}));

export default useFolderContentStyles;
