import { fetchUserFiles } from "../Api/FilesApi"; // Import the fetchUserInfo function
import { fetchUserFolders } from "../Api/FolderApi"; // Import the fetchUserInfo function
import { fetchListUsers, fetchUserInfo, postInvite } from "../Api/UserApi"; // Import the fetchUserInfo function
import * as val from "../NewComponents/EmailValidation";
import posthog from 'posthog-js'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;

export const handleKeyDown = (
  evt,
  value,
  setItems,
  setValue,
  items,
  setError
) => {
  if (["Enter", "Tab", ","].includes(evt.key)) {
    evt.preventDefault();

    var newValue = value.trim();

    if (newValue && val.isValid(newValue, setError, items)) {
      setItems([...items, value]);
      setValue("");
    }
  }
};
export const handleChange = (evt, setValue, setError) => {
  setValue(evt.target.value);
  setError(null);
};

export const handleDelete = (item, items, setItems) => {
  setItems(items.filter((i) => i !== item));
};

export const handlePaste = (evt, isInList, setItems, items) => {
  evt.preventDefault();

  var paste = evt.clipboardData.getData("text");
  var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  if (emails) {
    var toBeAdded = emails.filter((email) => !val.isInList(email, items));

    setItems([...items, ...toBeAdded]);
  }
};

function checkErrorMessage(data) {
  // Check if any message in the array matches "User exceeded plan"
  const hasErrorMessage = data.messages_error.some(message => message === "User exceeded plan");

  return hasErrorMessage;
}

export const handleButtonClick = async ({
  typed,
  file = null,
  emails = null,
  recordLink = null,
  value = null,
  token = null,
  onClose,
  setShowAlert,
  setMessage = null,
  setProgressBar = false,
  setShowUpgrade,
  emailToDelete,
  updateUsersList,
  setShowSpinner,
  user, valueText, newFolderName, newExerciseName, numQuest,
  navigate, updateListDocuments
}
) => {
  if (typed === "upload" || typed === "carpeta") {
    let folder_id = "";
    console.log("WE GO HERE NOW")
    if (newFolderName) {
      folder_id = newFolderName

    }
    else {
      folder_id = typeof value === 'string' ? value : value.guid;
    }

    const formData = new FormData();
    let filesToUpload;
    if (file && file.length > 0) {
      posthog.capture('upload_file');

      filesToUpload = file.map((fileItem) => fileItem.file); // Extracting File objects
      // setMessage("El archivo se está procesando. Te avisaremos cuando esté");

      // Function to load metadata for a single file and append to FormData
      const loadMetadata = (file, index) => {
        return new Promise((resolve) => {
          formData.append(`file_${index}`, file);
          formData.append(`file_${index}_size`, file.size);
          formData.append(`file_${index}_duration`, 0);

          resolve();
          // }
        });
      };


      // Wait for all files to have their metadata loaded
      const metadataPromises = filesToUpload.map((file, index) => loadMetadata(file, index));
      await Promise.all(metadataPromises);
    }
    if (filesToUpload) {
      setMessage(filesToUpload[0].name)
      setProgressBar(true)
      setShowAlert(true);

    }

    fetchUserFiles(token, "POST", folder_id, formData, user)
      .then((data) => {
        console.log("WE GO HERE NOW2")

        if (typeof updateListDocuments === 'function') {
          console.log("WE GO HERE NOW3")

          updateListDocuments();
        }


      })
      .catch((error) => {
        setShowSpinner(false)
        setShowAlert(false)
        setProgressBar(false)
        setShowAlert(false);
        console.error(error);
      });



    onClose()


    // Appear message if user doesnt have enough minutes

  } else if (typed === "grabar") {
    // Need to call 
    let folder_id = ""
    if (value || newFolderName) {
      if (newFolderName) {
        folder_id = newFolderName

      }
      else {
        folder_id = value.guid;
      }

      const apiUrl = `${BACKEND_URL}/api_v1/bot/${folder_id}/?user_id=${user['sub']}`;
      const requestOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({ "link": valueText })
      };
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      let message = data['message']
      setMessage(message);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      onClose()

    } else {
      setMessage("Debes añadir una carpeta donde se guardará la transcripción");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }



  } else if (typed === "invite") {
    if (emails.length > 0) {
      const postInviteAnswer = await postInvite(token, { emails: emails }, user);
      onClose();
    } else if (value) {
      setMessage("Debes hacer click en Enter para añadir el correo");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setMessage("Añade correos para invitar");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  } else if (typed === "subscription") {

    if (emails.length > 0) {
      const postAddToTeam = await fetchListUsers(token, 0, "POST", {
        emails: emails,
      }, user);

      await updateUsersList(); // Update the user list after adding a new user
      onClose();
    } else if (value) {
      setMessage("Debes hacer click en Enter para añadir el correo");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setMessage("Añade correos para invitar");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  } else if (typed === "carpeta") {

    if (value) {
      const folderName = value;
      const fetchFolders = await fetchUserFolders(token, "POST", {
        name: folderName,
      }, user);
      onClose();
    } else {
      setMessage("Añade nombre de la carpeta para crearla");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  } else if (typed === "removeuser") {

    await deleteUser(emailToDelete, token, user['sub']);
    onClose();
    await updateUsersList();

  } else if (typed === "delete_account") {
    try {
      // Make an API call to update the user's name


      const response = await fetchUserInfo(token, "DELETE", {}, user);// Replace with your actual API call
      if (response.status === 200) {
        // Update the state with the new name

        window.location.href = `https://${REACT_FRONT_END}/login`;
      } else {
        // Handle error response from the API
        console.error("Failed to update name.");
      }
    } catch (error) {
      console.error("Error while updating name:", error);
    }
    onClose();


  }
};
const deleteUser = async (email, token, user_id) => {
  try {
    // Replace with your actual delete request to the backend
    const requestOptions = {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
      },
    };
    const response = await fetch(`${BACKEND_URL}/api_v1/subscription/user/${encodeURIComponent(email)}/?user_id=${user_id}`, requestOptions)


  } catch (error) {
    console.error('Error deleting user:', error);
    // Handle errors, e.g., show an error message
  }
};

