import { makeStyles } from "@material-ui/core/styles";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import React, { useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    Button,
    Typography
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress'; // Import MUI CircularProgress for loading indicator
import "./style.css";

import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import MultiStageModal from "../ExamModal/ModalParent";
import pencil from '../Icons/svg_pencil.svg';

// Assuming you have added the scrollbar styles to your global CSS or a styled component
const GradientButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, #026277 10%, #7CC8FB 90%)', // Linear gradient background
    color: 'white', // Text color
    fontWeight: 600, // Font weight
    textTransform: "none",
    borderRadius: '15px', // Rounded corners
    ':hover': {
        // Define styles for when the button is hovered over
        background: 'linear-gradient(45deg, #024E5A 30%, #6AB2E7 90%)', // Slightly darker gradient on hover
    },
    width: "80%",
    height: "80px",
}));
const useStyles = makeStyles(theme => ({
    modalContainer: {
        margin: '1em',
        textAlign: 'center',
        [theme.breakpoints.down('1600')]: {
            display: 'None', // Example adjustment for smaller screens
        }
    },
    // Other styles you might need
}));

const CustomList = ({ chaptersTitle, generateSummary, loadingStates, listDocuments, token, user, setShowUpgrade, setMessage, setShowAlert }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const { chapter_id, selectedChapter } = useParams();

    const { document_id, selectedDocument } = useParams();
    const { folder_id, selectedFolder } = useParams();

    let path = folder_id + "/" + document_id + "/" + chapter_id
    const handleGenerateExam = (path) => {

    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // const generateSummary = async (chapter_id) => {
    //     setLoadingStates(prevState => ({ ...prevState, [chapter_id]: true }));
    //     try {
    //         const updatedChapter = await fetchSummaryChapterid(token, "POST", chapter_id, user);
    //         console.log("Updated chapters data:", updatedChapter);
    //         // Make sure updatedChapter is the full, updated list of chapters
    //         setChaptersTitle(updatedChapter); // This should be the full, updated list
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setLoadingStates(prevState => ({ ...prevState, [chapter_id]: false }));
    //     }
    // };


    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (chapter) => {
        // Obtain the current pathname
        const pathname = location.pathname;
        // Split the pathname into segments
        const segments = pathname.split('/').filter(Boolean);

        // Use regex to filter out segments that are GUIDs
        const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        const guidSegments = segments.filter(segment => guidRegex.test(segment));

        if (guidSegments.length === 3) {
            // If there are already three GUIDs, replace the last one
            segments[segments.lastIndexOf(guidSegments[2])] = chapter.guid;
        } else {
            // If there are fewer than three GUIDs, add the new chapter GUID
            segments.push(chapter.guid);
        }

        // Create the new path from the segments
        const newPath = `/${segments.join('/')}`;
        // Navigate to the new path
        navigate(newPath);
    };





    return (
        <>
            <List
                className="custom-scrollbar"
                sx={{
                    maxHeight: 'calc(5 * 38px)', // Adjust based on your item height
                    overflowY: 'scroll', // Keep scrollbar visible
                    // Add any additional styles here
                    marginBottom: "20px",

                }}
            >
                {chaptersTitle.length === 0 ? (
                    // Render this when chaptersTitle is empty
                    <Typography sx={{ textAlign: 'center', mt: 2, fontSize: "14px" }}>
                        {t("chapter_generating")}
                    </Typography>
                ) : (
                    // Render this when chaptersTitle is not empty
                    chaptersTitle.map((chapter, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                cursor: 'pointer',
                                paddingLeft: 0,
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%', // Ensure the div takes full width of the ListItem
                            }}>
                                <Tooltip title={chapter.title} placement="top" arrow>
                                    <ListItemText
                                        primary={`${t("chapter")} ${index + 1}: ${chapter.title}`}
                                        primaryTypographyProps={{
                                            fontWeight: 500,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: "14px"
                                        }}
                                        style={{ maxWidth: '60%' }} // Adjust the max-width to the desired value
                                    />
                                </Tooltip>

                                {!chapter.is_active ? (
                                    <Button style={{ textTransform: "none" }} onClick={() => generateSummary(chapter.guid)}>
                                        {loadingStates[chapter.guid] ? <CircularProgress size={20} /> : t("generate_summary")}
                                    </Button>
                                ) : (
                                    <Button style={{ textTransform: "none" }} onClick={() => handleNavigation(chapter)}>{t("see_summary")}</Button>
                                )}
                            </div>
                        </ListItem>
                    ))
                )}
            </List>

            <div className={classes.modalContainer}>
                {isModalOpen && <MultiStageModal open={isModalOpen} onClose={handleCloseModal} listDocuments={listDocuments} token={token} user={user} setShowUpgrade={setShowUpgrade} setMessage={setMessage} setShowAlert={setShowAlert} />}

                <GradientButton onClick={handleOpenModal}>
                    <img src={pencil} />
                    <Typography style={{ fontWeight: 600, fontSize: 16 }}>{t("generate_exam_questions")}</Typography>
                </GradientButton>
            </div>
        </>
    );
};
export default CustomList;