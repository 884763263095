import { Button, FormControl, FormControlLabel, Radio, Box, RadioGroup, TextField, Typography, List, ListItemIcon, ListItem, ListItemText, InputAdornment, IconButton } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from "react-spinners";
import { ReactComponent as StopIcon } from './stop.svg'; // replace with your SVG path
import { ReactComponent as MicIcon } from './mic.svg'; // replace with your SVG path
import { fetchQuestionAnswerId } from '../../Api/QuestionsApi';
import useQuestionsStyle from "./Styles/useQuestionsStyle";
import StyledCheckbox from './Styles/StyleRadio';
import { FiberManualRecord as BulletIcon } from '@mui/icons-material';

const GradientButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, #026277 10%, #7CC8FB 90%)',
    color: 'white',
    fontWeight: 600,
    textTransform: "none",
    borderRadius: '7px',
    ':hover': {
        background: 'linear-gradient(45deg, #024E5A 30%, #6AB2E7 90%)',
    },
    padding: "7px",
    paddingLeft: "25px",
    paddingRight: "25px",
}));

const Question = ({ questionNumber, question, answer, onAnswerChange, onQuestionCorrection, token, user, corrected, setCorrected, reset, updateQuestionData, validated }) => {
    const [hintActive, setHintActive] = useState(false);
    const [processingAnswer, setProcessingAnswer] = useState(false);
    const [punctuationQuestion, setPunctuationQuestion] = useState(question.punctuation || '');
    const [questionCorrect, setQuestionCorrect] = useState(question.question_correct_answer || '');
    const { t } = useTranslation();
    const [selectedAnswer, setSelectedAnswer] = useState(answer);
    const [recognition, setRecognition] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isCorrectingOT, setIsCorrectingOT] = useState(false);
    const [interimTranscript, setInterimTranscript] = useState("");
    const [badAnswer, setBadAnswer] = useState(question.ot_bad || "");
    const [goodAnswer, setGoodAnswer] = useState(question.ot_good || "");
    const [debounceTimer, setDebounceTimer] = useState(null); // Timer for debounce

    const classes = useQuestionsStyle();
    useEffect(() => {
        if (reset) {
            setSelectedAnswer(answer || '');
        }
    }, [answer, reset]);

    useEffect(() => {
        if (question.question_type === 'OT' && 'webkitSpeechRecognition' in window) {
            const speechRecognition = new window.webkitSpeechRecognition();
            speechRecognition.continuous = true;
            speechRecognition.interimResults = true;
            speechRecognition.lang = 'es-ES';

            speechRecognition.onresult = (event) => {
                let interimTranscript = "";
                let finalTranscript = "";
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        finalTranscript += event.results[i][0].transcript + ' ';
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                    }
                }
                setInterimTranscript(interimTranscript); // Update interim transcript
            };

            speechRecognition.onerror = (event) => {
                console.error("Speech recognition error detected: " + event.error);
            };

            setRecognition(speechRecognition);
        } else {
            console.warn("Web Speech API is not supported by this browser.");
        }
    }, [question.question_type]);

    const handleQuestionCorrection = async () => {
        if (isRecording) {
            stopListening();
        }
        if (!validated) {
            setIsCorrectingOT(true)
            const correctionStatus = true;
            setCorrected(correctionStatus);
            setProcessingAnswer(true);
            const payload = {
                "answer": selectedAnswer
            };

            try {
                let corrected_answer = await fetchQuestionAnswerId(token, "POST", question.guid, user, payload);
                onQuestionCorrection(question.guid);

                setCorrected(corrected_answer.punctuation_active);
                setPunctuationQuestion(corrected_answer.punctuation);
                setIsCorrectingOT(false)
                setGoodAnswer(corrected_answer.ot_good)
                setBadAnswer(corrected_answer.ot_bad)
                updateQuestionData(question.guid, {
                    punctuation: corrected_answer.punctuation,
                    punctuation_active: corrected_answer.punctuation_active,
                    ot_good: corrected_answer.ot_good,
                    ot_bad: corrected_answer.ot_bad,
                });

            } catch (error) {
                console.error('Failed to submit answer:', error);
            } finally {
                setProcessingAnswer(false);

            }
        }
    };

    const handleOptionChange = async (event) => {
        if (!validated) {
            const newAnswer = event.target.value;
            setSelectedAnswer(newAnswer);
            onAnswerChange(question.guid, newAnswer);
            const payload = { "answer": newAnswer };
            await handleUpdateAnswer(payload);
        }
    };

    useEffect(() => {

        setCorrected(question.punctuation_active);
        setProcessingAnswer(question.processing_answer);
        setPunctuationQuestion(question.punctuation);
    }, [question.guid, question.punctuation_active, question.processing_answer]);

    const handleQuestion = async (event) => {
        setProcessingAnswer(true);
        const payload = {
            "answer": selectedAnswer
        };

        try {
            let corrected_answer = await fetchQuestionAnswerId(token, "POST", question.guid, user, payload);
            setProcessingAnswer(false);
            setCorrected(corrected_answer.punctuation_active);

            setPunctuationQuestion(corrected_answer.punctuation);
        } catch (error) {
            console.error('Failed to submit answer:', error);
        }
    };

    const handleUpdateAnswer = async (payload) => {
        try {
            await fetchQuestionAnswerId(token, "PUT", question.guid, user, payload);
        } catch (error) {
            console.error('Failed to submit answer:', error);
        }
    };

    const startListening = () => {
        if (recognition) {
            recognition.start();
            setIsRecording(true);
        }
    };
    const stopListening = () => {
        if (recognition) {
            recognition.stop();
            setIsRecording(false);
            setSelectedAnswer(interimTranscript)
            setInterimTranscript(""); // Clear interim transcript when stopping
            onAnswerChange(question.guid, interimTranscript);
            const payload = { "answer": interimTranscript };
            handleUpdateAnswer(payload);
        }
    };


    const handleTextFieldChange = (event) => {
        const newAnswer = event.target.value;
        setSelectedAnswer(newAnswer);

        // Clear the previous timer if the user is still typing
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        // Set a new timer that will execute the save operation 1 second after the user stops typing
        const newTimer = setTimeout(() => {
            onAnswerChange(question.guid, newAnswer); // Notify the parent component of the change
            const payload = { answer: newAnswer };
            handleUpdateAnswer(payload); // Make the PUT request to save the answer
        }, 1000); // 1000ms = 1 second debounce time

        // Store the timer ID in state
        setDebounceTimer(newTimer);
    };


    return (
        <div style={{ marginBottom: "10px" }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ fontWeight: 600, marginBottom: '10px', fontSize: "14px" }}>
                    {questionNumber}. {question.question_statement}
                </Typography>
                {question.question_type === 'MC' ? (
                    <FormControl component="fieldset" disabled={question.processing_answer || question.punctuation_active} style={{ marginLeft: "15px" }}>
                        <RadioGroup
                            name={`question_${question.guid}`}
                            value={selectedAnswer}
                            onChange={handleOptionChange}
                        >
                            {question.question_options.map((option) => (
                                <FormControlLabel
                                    key={option.guid}
                                    control={
                                        <StyledCheckbox
                                            checked={selectedAnswer === option.text}
                                            onChange={handleOptionChange}
                                            value={option.text}
                                        />
                                    }
                                    label={<Typography style={{ fontSize: '14px' }}>{option.text}</Typography>}
                                    style={{ fontSize: '14px' }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                ) : (
                    <TextField
                        multiline
                        rows={6}
                        variant="outlined"
                        className={`${classes.input} ${classes.textField}`}
                        value={selectedAnswer}
                        onChange={handleTextFieldChange}
                        placeholder={t('your_answer')}
                        fullWidth
                        disabled={question.processing_answer || question.punctuation_active || validated}

                    />
                )}
                {question.question_type != "MC" && (<>

                    <div style={{ margin: "0 auto", marginTop: "10px", cursor: "pointer" }} onClick={isRecording ? stopListening : startListening} disabled={question.processing_answer || question.punctuation_active || validated}>
                        {isRecording ? <StopIcon /> : !validated && <MicIcon />
                        }
                    </div>

                </>)}
                {hintActive && (
                    <div>
                        <div className={classes.hintPista}><Typography>{t("hint")}</Typography></div>
                        <Typography style={{ fontSize: 14 }}> {question.hint}</Typography>
                    </div>
                )}
                {validated && !isCorrectingOT && (
                    // <div className={classes.scoreBox}>
                    //     <Typography style={{ fontSize: 14, color: "white", margin: "auto" }}>{t("score")}: {punctuationQuestion} </Typography>
                    // </div>
                    <div
                        className={classes.scoreBox}
                        style={{
                            backgroundColor: punctuationQuestion < 5 ? "red" : "#6BBDBD",
                        }}
                    >
                        <Typography style={{ fontSize: 14, color: "white", margin: "auto" }}>
                            {t("score")}: {punctuationQuestion}
                        </Typography>
                    </div>

                )}
                {validated && question.question_type === "MC" ? (
                    <>
                        {/* <div className={classes.respuestaCorrecta}><Typography style={{ fontSize: 14 }}>{t("correct_answer_")}</Typography></div> */}
                        <Typography style={{ fontSize: "14px", marginTop: "10px" }}><strong>{questionCorrect}</strong> -- {question.question_explanation}</Typography>
                    </>
                ) : (validated && question.question_type === "OT" && !isCorrectingOT
                    && (<>
                        {/* <div className={classes.respuestaCorrecta}><Typography style={{ fontSize: 14 }}>{t("correct_answer_")}</Typography></div> */}
                        <Typography style={{ fontSize: "14px", marginTop: "10px" }}><strong>{questionCorrect}</strong> -- {question.question_explanation}</Typography>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", }}>
                            <div style={{ width: "50%" }}>

                                <Typography className={classes.badButton} >{t("bad")} </Typography>
                                <List dense>
                                    {badAnswer && badAnswer.map((sentence, index) => (
                                        <ListItem key={index} style={{ padding: '0px 16px', alignItems: 'center' }}>
                                            <ListItemIcon style={{ minWidth: 'auto', marginRight: 8 }}>
                                                <BulletIcon style={{ fontSize: 8, verticalAlign: 'middle' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography style={{ fontSize: 12 }}>{sentence}</Typography>}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            <div style={{ width: "50%" }}>
                                <Typography className={classes.goodButton}>{t("good")} </Typography>
                                <List dense>
                                    {goodAnswer && goodAnswer.map((sentence, index) => (
                                        <ListItem key={index} style={{ padding: '0px 16px', alignItems: 'center' }}>
                                            <ListItemIcon style={{ minWidth: 'auto', marginRight: 8 }}>
                                                <BulletIcon style={{ fontSize: 8, verticalAlign: 'middle' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography style={{ fontSize: 12 }}>{sentence}</Typography>}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </div>
                    </>

                    ))}
                <Box display="flex" justifyContent="center" style={{ gap: "10px" }} mt={2}>
                    <Button className={classes.hintButton} onClick={() => setHintActive(true)}>{t("hint")}</Button>
                    <Button
                        className={classes.correctQuestion}
                        onClick={handleQuestionCorrection}
                        disabled={validated}
                    >
                        {processingAnswer ? <ClipLoader color="white" size={20} /> : t("correct")}
                    </Button>
                </Box>
            </div>
        </div>
    );
};

export default Question;
