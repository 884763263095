import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [previousPath, setPreviousPath] = useState("");

    useEffect(() => {
        if (location.pathname !== currentPath) {
            setPreviousPath(currentPath);
            setCurrentPath(location.pathname);
        }
    }, [location, currentPath]);

    return (
        <NavigationContext.Provider value={{ currentPath, previousPath }}>
            {children}
        </NavigationContext.Provider>
    );
};
