import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // You can replace this with your own SVGs
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
    makeStyles
} from "@material-ui/core";
import { SvgIcon } from '@mui/material';
import { useSubscription } from '../Context/SubscriptionContext';
import { useNavigate } from 'react-router-dom';


const CustomCheckCircleIcon = (props) => (
    <SvgIcon {...props}>
        <circle cx="12" cy="12" r="12" fill="rgba(107, 189, 189, 0.10)" />
        <path
            d="M10 15.172L7.414 12.586L6 14L10 18L18 10L16.586 8.586L10 15.172Z"
            fill="#6BBDBD"
        />
    </SvgIcon>
);


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '30px',
        width: '611px',
        height: '385px',
        overflow: 'hidden',
        position: 'relative',
        padding: 0
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 1,
}));

const CustomListItem = styled(ListItem)({
    alignItems: 'flex-start',
});

const CustomListItemIcon = styled(ListItemIcon)({
    marginTop: '2px',
    minWidth: '30px',
});
const useStyles = makeStyles((theme) => ({
    currentPlan: {
        padding: 10,
        display: "flex",
        justifyContent: 'space-between',
        border: '1px solid #6BBDBD',
        color: "#6BBDBD",
        borderRadius: "20px",

    },
    buttonManage: {
        backgroundColor: "#6BBDBD !important",
        color: "white !important",
        textTransform: "none !important",
        borderRadius: "6px !important",
        fontSize: "14px !important",
        display: "flex !important",
        alignItems: "flex-end !important",
        gap: "2px !important",
        borderRadius: "20px !important",
        display: "flex !important"
        , '&:disabled': {
            backgroundColor: '#B0B0B0 !important', // Gray color for the disabled state
            color: '#FFFFFF !important'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD !important', // Gray color for the disabled state
            color: "white !important",
            // padding: "8px 14px", // Added padding for better appearance

        },
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
        fontWeight: "bold !important"
    },
    textDesc: {
        fontSize: "14px"
    }
}));
function NeedCredits({ open = true, onClose }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { userData } = useSubscription();
    const [currentPlan, setCurrentPlan] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        if (userData && userData.plan_id) {
            setCurrentPlan(userData.plan_credits);
        }
    }, [userData]);
    return (
        <CustomDialog open={open} onClose={onClose}>
            <DialogContent dividers style={{ padding: 0, border: 0 }}>
                <Grid container spacing={0} style={{ height: '100%' }}>
                    <Grid item xs={12} md={5} style={{ padding: '0', height: '100%' }}>
                        <img src={require('./img/paywall.png')} alt="Illustration" style={{ width: '255px', height: '100%', objectFit: 'cover', borderRadius: '20px 0 0 20px' }} />
                    </Grid>
                    <Grid item xs={12} md={7} style={{ padding: '20px', height: '100%', overflow: 'hidden' }}>
                        <Typography fontSize="20px" fontWeight="bold" gutterBottom>
                            {t("monthly_limit")}
                        </Typography>
                        <Typography fontSize="14px" gutterBottom>
                            {t("keep_studying")}
                        </Typography>
                        <List>
                            <CustomListItem style={{ padding: 3 }} >
                                <CustomListItemIcon>
                                    <CustomCheckCircleIcon /> {/* Replace with your SVG icon */}
                                </CustomListItemIcon>
                                <ListItemText primary={t("paywall_desc_1")} primaryTypographyProps={{ fontSize: '13px !important' }} />
                            </CustomListItem>
                            <CustomListItem style={{ padding: 3 }} >
                                <CustomListItemIcon>
                                    <CustomCheckCircleIcon /> {/* Replace with your SVG icon */}
                                </CustomListItemIcon>
                                <ListItemText primary={t("paywall_desc_2")} primaryTypographyProps={{ fontSize: '13px !important' }} />
                            </CustomListItem>
                            <CustomListItem style={{ padding: 3 }} >
                                <CustomListItemIcon>
                                    <CustomCheckCircleIcon /> {/* Replace with your SVG icon */}
                                </CustomListItemIcon>
                                <ListItemText primary={t("paywall_desc_3")} primaryTypographyProps={{ fontSize: '13px !important' }} />
                            </CustomListItem>
                        </List>
                        <div className={classes.currentPlan}>
                            <div><Typography style={{ fontSize: "12px" }}>{t("current_plan_text")}</Typography></div>
                            <div><Typography style={{ fontSize: "12px" }}>{currentPlan} {t("credits")}</Typography></div>
                        </div>
                        {/* <Typography style={{ fontSize: 12, marginTop: "10px", marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: t('keep_studying_desc') }} /> */}
                        <Button style={{ margin: "0 auto", marginTop: "25px" }} className={classes.buttonManage} onClick={() => navigate("/plans")}>{t("manage_subscription")}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
            {/* <CloseButton onClick={handleClose}>
                <CloseIcon />
            </CloseButton> */}
        </CustomDialog >
    );
}

export default NeedCredits;
