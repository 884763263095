import { useAuth0 } from "@auth0/auth0-react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useEffect, useState, useContext } from "react";
import { WebSocketContext } from '../WebSocketProvider';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import { fetchUserInfo } from '../Api/UserApi'; // Import the fetchUserInfo function
import { useAuth } from "../Auth/Authhandle";
import { useSubscription } from '../Context/SubscriptionContext'; // Adjust the import path accordingly
import SmallAlert from "../NewComponents/Modal/smallAlert";
import SpinnerUpload from "../NewComponents/Upload/SpinnerUpload";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import useLayoutStyles from "./Styles/useLayoutStyles";
import ModalParent from "../AllModals/ParentModal";
import sesionesEstudio from './Icons/sesionesEstudio.svg';
import ModalNotReady from "../NewComponents/ModalNotReady";
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import clsx from 'clsx';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useCredits } from "../Context/CreditsContext";


const Sidebar = ({ children }) => {
  const { t } = useTranslation();
  const classes = useLayoutStyles();
  const { messageCallback } = useContext(WebSocketContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [type, setType] = useState("");
  const { hasSubscription, setHasSubscription, userData } = useSubscription();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [progressBar, setProgressBar] = useState(false);
  const navigate = useNavigate();
  const { credits } = useCredits();

  //logout
  const { logout } = useAuth0();

  // call useEffect to render userInfo
  const location = useLocation(); // Get the current location

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("")
  const [imageProfile, setImageProfile] = useState("")
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [widthModal, setWidthModal] = useState("350px")

  const [openNotReady, setOpenNotReady] = useState(false);
  const [currentCredits, setCurrentCredits] = useState(credits || 200);
  useEffect(() => {
    setCurrentCredits(credits)
  }, [credits, accessToken, user])



  useEffect(() => {
    if (messageCallback) {

      switch (messageCallback.type) {
        case "Exam":
          setCurrentCredits(messageCallback.credits)
          break;

        case "summary":
          setCurrentCredits(messageCallback.credits)
          break;
        case "Error":
          alert(messageCallback.messageAlert)

          setShowSpinner(true);
          setShowSpinner(false);
          break;
        case "documentsUpdate":
          console.log("ERROR HERE TOO?")
          setShowSpinner(false);
          break;
        default:
          console.log("Unhandled message type:", messageCallback.document);
      }

    }
  }, [messageCallback]);
  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };
  useEffect(() => {

    async function fetchData() {
      try {
        if (accessToken && user) {
          const userData = await fetchUserInfo(accessToken, "GET", null, user); // Call the fetchUserInfo function

          const surnameFirstPart = userData.surname.split(' ')[0];

          let fullname = `${userData.name} ${surnameFirstPart}`
          let userEmail = userData.email
          if (userEmail.length > 8) {
            // Taking only the beginning of the email and appending "...."
            userEmail = userEmail.substring(0, 13) + "...";
          }
          if (fullName.length > 7) {
            // Taking only the beginning of the email and appending "...."
            fullname = fullname.substring(0, 13) + "...";
          }
          setFullName(fullname)

          // Setting the states with fetched or modified user data
          setImageProfile(userData.image)
          setEmail(userEmail)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData(); // Call the fetchData function
  }, [imageProfile, accessToken, user]);
  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();

    logout({ logoutParams: { returnTo: "https://www.typedai.com" } })
  };
  useEffect(() => {
    // Load the Tally script
    const script = document.createElement('script');
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  const handleOpenTallyPopup = () => {
    window.Tally.openPopup('nrldZv', {
      layout: 'default',
      autoClose: 5000,
      hideTitle: false,
      emoji: {
        text: "👋",        // specify the emoji text
        animation: "wave"  // choose an animation from the options
      },
      onOpen: () => console.log("Popup opened"),
      onClose: () => console.log("Popup closed")
    });
  };

  const handleCloseTallyPopup = () => {
    window.Tally.closePopup('nrldZv');
  };
  const [collapsed, setCollapsed] = useState(false); // State to manage the Drawer collapse

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Detect small screen size
  useEffect(() => {
    if (isSmallScreen) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }

  }, [isSmallScreen]);
  return (
    <>

      {modalOpen && (
        <ModalParent
          open={modalOpen}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          type={type}
          onClose={() => setModalOpen(false)}
          setShowSpinner={setShowSpinner}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
          setProgressBar={setProgressBar}
          setShowUpgrade={setShowUpgrade}
          width={widthModal}




        />
      )}

      {showAlert &&
        <SmallAlert
          message={message}
          progressBar={progressBar}
          onClose={() => setShowAlert(false)}
        />
      }
      {
        showUpgrade && <NeedCredits message={message}

          onClose={() => setShowUpgrade(false)} />
      }
      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {showSpinner && <SpinnerUpload customTransform="translate(180%, -50%)" />}



      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !collapsed && !isSmallScreen,
          [classes.drawerClose]: collapsed || isSmallScreen,
        })}
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !collapsed && !isSmallScreen,
            [classes.drawerClose]: collapsed || isSmallScreen,
          }),
        }}
        anchor="left"

      >
        {/* <Button onClick={toggleDrawer}>
          {collapsed ? ">" : "<"}
        </Button> */}
        <div className={classes.top}>
          {/* Header section with Image and Name */}

          {!collapsed ? (

            <div className={classes.header}>
              <div style={{ width: '100%', height: '100%', alignItems: 'flex-start', gap: 7, display: 'inline-flex' }}>
                <div style={{ width: 29.79, height: 30, position: 'relative' }}>
                  <div style={{ width: 29.79, height: 29.17, left: -0, top: 2, position: 'absolute', textAlign: 'center', color: '#6BBDBD', fontSize: 22, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }} className="notranslate">T </div>
                  <div style={{ width: 29.79, height: 30, left: 0, top: 0, position: 'absolute', background: 'rgba(255, 255, 255, 0)', borderRadius: 8, border: '1px #6BBDBD solid' }} />
                </div>
                <div style={{ textAlign: 'center', color: '#6BBDBD', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }} className="notranslate">Typed</div>
              </div>
            </div>) : (

            <div className={classes.header}>
              <div style={{ width: '100%', height: '100%', alignItems: 'flex-start', gap: 7, display: 'inline-flex' }}>
                <div style={{ width: 29.79, height: 30, position: 'relative' }}>
                  <div style={{ width: 29.79, height: 29.17, left: -0, top: 2, position: 'absolute', textAlign: 'center', color: '#6BBDBD', fontSize: 22, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>T </div>
                  <div style={{ width: 29.79, height: 30, left: 0, top: 0, position: 'absolute', background: 'rgba(255, 255, 255, 0)', borderRadius: 8, border: '1px #6BBDBD solid' }} />
                </div>
              </div>
            </div>
          )}

          <List >
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>

              <ListItem button key="Inicio"
                className={`${classes.listItem}  ${location.pathname === '/' && classes.selected}`}

              >
                <ListItemIcon className={classes.listItemIcon}>
                  {" "}
                  <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 12L5 10M5 10L12 3L19 10M5 10V20C5 20.5523 5.44772 21 6 21H9M19 10L21 12M19 10V20C19 20.5523 18.5523 21 18 21H15M9 21C9.55228 21 10 20.5523 10 20V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V20C14 20.5523 14.4477 21 15 21M9 21H15" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                  </svg>
                </ListItemIcon>
                {!collapsed && (
                  <ListItemText
                    primary={t("home")}
                    classes={{ primary: classes.text }}
                  />)}
              </ListItem>
            </Link>
            {/* <ListItem
              button
              onClick={() => {
                navigate("/aichat")
              }} key="AIChat"
              // onClick={handleClickOpenNotReady}
              style={{ marginTop: "5px" }}
              className={`${classes.listItem}  ${location.pathname === '/aichat' && classes.selected}`}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                  <path d="M6.57104 17.3265C7.72735 17.9934 9.06896 18.375 10.4997 18.375C14.8489 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 11.9307 3.00654 13.2723 3.67342 14.4286L3.676 14.4331C3.74017 14.5444 3.77253 14.6005 3.78718 14.6535C3.80101 14.7035 3.80487 14.7485 3.80133 14.8002C3.79752 14.8558 3.77878 14.9135 3.74035 15.0288L3.06763 17.047L3.06678 17.0496C2.92484 17.4754 2.85387 17.6883 2.90446 17.8302C2.94857 17.9539 3.04647 18.0515 3.17017 18.0956C3.31171 18.1461 3.52366 18.0754 3.9476 17.9341L3.95288 17.9322L5.97104 17.2595C6.08595 17.2212 6.14437 17.2017 6.19989 17.1979C6.25166 17.1944 6.2963 17.1991 6.34631 17.2129C6.39947 17.2276 6.45561 17.26 6.56743 17.3245L6.57104 17.3265Z" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                </svg>

              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={"AI Chat"}
                  classes={{ primary: classes.text }}
                />)}
            </ListItem>
 */}

            <ListItem
              button
              key="Exams"
              onClick={() => {
                navigate("/exams")
              }}
              className={`${classes.listItem} ${location.pathname === '/exams' && classes.selected}`}
              style={{ marginTop: "5px" }}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                  <path d="M5.25 10.0835V5.68351C5.25 4.65675 5.25 4.14299 5.44074 3.75081C5.60852 3.40585 5.87604 3.12559 6.20532 2.94982C6.57967 2.75 7.07008 2.75 8.05017 2.75H11.9654C12.0726 2.75 12.1664 2.75 12.25 2.7508M17.4992 8.25C17.5 8.33764 17.5 8.43605 17.5 8.54854V16.3199C17.5 17.3447 17.5 17.8571 17.3094 18.2489C17.1417 18.5939 16.8734 18.8746 16.5441 19.0504C16.1702 19.25 15.6809 19.25 14.7027 19.25L11.375 19.25M17.4992 8.25C17.4968 7.9883 17.4875 7.82261 17.4513 7.66455C17.4084 7.47748 17.3374 7.29863 17.2415 7.1346C17.1333 6.94958 16.9828 6.79148 16.6801 6.4744L13.9453 3.60938C13.6428 3.2925 13.4912 3.13369 13.3147 3.02035C13.1581 2.91983 12.9876 2.84557 12.809 2.80066C12.658 2.76269 12.4999 2.75318 12.25 2.7508M17.4992 8.25H17.5002M17.4992 8.25H15.0473C14.0691 8.25 13.5793 8.25 13.2053 8.05037C12.876 7.87461 12.6085 7.59359 12.4407 7.24863C12.25 6.85645 12.25 6.34343 12.25 5.31667V2.7508M7.875 12.8333L9.625 14.6667M3.5 19.25V16.9583L10.0625 10.0833L12.25 12.375L5.6875 19.25H3.5Z" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                </svg>


              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={t("your_exams")}
                  classes={{ primary: classes.text }}
                />)}
            </ListItem>
            {/* <ListItem
              button
              key="Memorise"
              className={`${classes.listItem} sidebar-step-file `}
              onClick={() => { navigate("/memorise", { state: { reset: true }, replace: true }) }}
              style={{ marginTop: "5px" }}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIconFlashcards} xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                  <path d="M11.933 21.7965C12.2821 21.9996 12.6886 22.053 13.0786 21.9462C13.467 21.8393 13.792 21.5847 13.9926 21.2295L20.799 9.18832C20.9996 8.83361 21.0523 8.42067 20.947 8.02554C20.8417 7.6304 20.5907 7.3012 20.2407 7.09714L13.5501 3.20404C13.2005 3.00045 12.792 2.94655 12.404 3.05436C12.0151 3.1612 11.6901 3.4158 11.4895 3.77051L10.5954 5.35152V15.7044C10.5954 16.8147 9.70549 17.7176 8.61213 17.7176H5C5.0759 17.7855 5.15321 17.8519 5.24144 17.9034L11.933 21.7965Z" />
                  <path d="M0 16.3727C0 17.2699 0.630663 18 1.40628 18H8.59416C9.36934 18 10 17.2699 10 16.3727V1.62679C10 0.730061 9.36934 0 8.59416 0H1.40628C0.631105 0 0 0.730061 0 1.62679V16.3727Z" />
                </svg>
              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={t("study_session")}
                  classes={{ primary: classes.text }}

                />)}
            </ListItem> */}
            {/* 
            <ListItem button
              className={`${classes.listItem} sidebar-step-file `}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <img src={sesionesEstudio} />
              </ListItemIcon>
              <ListItemText
                primary={"Sesiones de estudio"}
                classes={{ primary: classes.text }}

              />
            </ListItem> */}



          </List>

          {/* Bottom section */}
          <div className={classes.bottom}>

            <List style={{ marginRight: 10 }}>
              {/* {hasSubscription && (


                <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit' }}>

                  <ListItem button key="Mi cuenta" className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 9V12M18 12V15M18 12H21M18 12H15M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM3 20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21H3V20Z" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </ListItemIcon>
                    <ListItemText
                      primary="Admin"
                      classes={{ primary: classes.text }}
                    />
                  </ListItem>
                </Link>)} */}
              {!collapsed && (
                <div className={classes.boxCredits}>
                  <Typography className={classes.creditsText}>
                    {t("credits_left", { currentCredits })} <a style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }} className={classes.creditsText} onClick={() => navigate("/plans")}>{t("buy")}</a>
                  </Typography>

                </div>)}
              {!collapsed ? (
                <Button className={classes.buttonPlan} onClick={() => { navigate("/plans") }}>{t("upgrade_plan")}</Button>
              ) :
                <ListItemIcon onClick={() => { navigate("/plans") }} className={classes.listItemIconUpgrade}>

                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="46" height="46" rx="8" fill="#E2F1F1" />
                    <path d="M31.357 21.2733L20.3022 33.0612C19.9881 33.3961 19.4494 33.0244 19.651 32.612L23.5835 24.5653C23.7133 24.2996 23.5198 23.9897 23.2241 23.9897H14.9329C14.5813 23.9897 14.4008 23.5686 14.6432 23.3139L24.8994 12.5377C25.2043 12.2174 25.7268 12.5556 25.5594 12.9649L22.6201 20.1522C22.5117 20.4174 22.709 20.7073 22.9955 20.7036L31.06 20.5997C31.4127 20.5952 31.5982 21.0161 31.357 21.2733Z" fill="#6BBDBD" />
                  </svg>
                </ListItemIcon>
              }

              <Link to="/myaccount" style={{ textDecoration: 'none', color: 'inherit', margin: 0, padding: 0 }}>
                <ListItem button key="Mi cuenta" className={`${classes.listItem2} ${location.pathname === '/myaccount' && classes.selected}`}

                >

                  <ListItemIcon className={classes.listItemIcon}>
                    <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                      <path d="M17.5 18.375C17.5 15.9588 14.366 14 10.5 14C6.63401 14 3.5 15.9588 3.5 18.375M10.5 11.375C8.08375 11.375 6.125 9.41625 6.125 7C6.125 4.58375 8.08375 2.625 10.5 2.625C12.9162 2.625 14.875 4.58375 14.875 7C14.875 9.41625 12.9162 11.375 10.5 11.375Z" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                    </svg>
                  </ListItemIcon>
                  {!collapsed && (
                    <ListItemText
                      primary={t("my_account")}
                      classes={{ primary: classes.text }}
                    />)}
                </ListItem>
              </Link>
              {/* <ListItem button key="Help" onClick={handleOpenTallyPopup} className={classes.listItem2}
              >
                <ListItemIcon className={classes.listItemIcon}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.14648 9.07361C9.31728 8.54732 9.63015 8.07896 10.0508 7.71948C10.4714 7.36001 10.9838 7.12378 11.5303 7.03708C12.0768 6.95038 12.6362 7.0164 13.1475 7.22803C13.6587 7.43966 14.1014 7.78875 14.4268 8.23633C14.7521 8.68391 14.9469 9.21256 14.9904 9.76416C15.0339 10.3158 14.9238 10.8688 14.6727 11.3618C14.4215 11.8548 14.0394 12.2685 13.5676 12.5576C13.0958 12.8467 12.5533 12.9998 12 12.9998V14.0002M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 17V17.1L11.9502 17.1002V17H12.0498Z" stroke="black" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                </svg>

                </ListItemIcon>
                <ListItemText
                  primary={t("help_layout")}
                  classes={{ primary: classes.text }}
                />
              </ListItem> */}
              <ListItem button key="Logout" onClick={handleLogout} className={classes.listItem2}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                  </svg>
                </ListItemIcon>
                {!collapsed && (
                  <ListItemText
                    primary={t("exit")}
                    classes={{ primary: classes.text }}
                  />)}
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer >
      {/* <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div> 
       <Outlet /> */}


    </>
  );
};

export default Sidebar;
