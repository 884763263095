import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from "@material-ui/icons/Share";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import LinkIcon from "@material-ui/icons/Link";
import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const MAX_TITLE_LENGTH = 25; // Replace 100 with your desired character limit
const MAX_DESCRIPTION_LENGTH = 200; // Replace 100 with your desired character limit

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    marginBottom: theme.spacing(2),
    border: "1px solid #ddd",
    borderRadius: 8,
    overflow: "hidden",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease",
    "&:hover": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
    },
    width: 600,
    height: "100%",
  },

  trashbutton: {
    marginBottom: "5px",
    "&:hover": {
      backgroundColor: "red",
      color: "white",
      marginBottom: "5px",
    },
  },
  editorTitleContainer: {
    "& .ce-header": {
      // Replace 'Arial, sans-serif' with your desired font-family
      fontFamily: "Arial, sans-serif",
      fontSize: "20px", // Adjust the font size as needed
    },
  },
  editorContainer: {
    minHeight: "1.2rem", // Reduce the minimum height to show only one line
    maxHeight: "3.6rem", // Reduce the maximum height to show a maximum of three lines
    overflow: "hidden", // Hide any overflow beyond the maximum height
    // border: "0.5px solid #ddd",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  media: {
    width: "40%",
    height: 160,
    objectFit: "cover",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px",
    marginLeft: "20px",
  },

  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    cursor: "text",
    "&.titleExceedsLimit": {
      border: "1px solid red",
    },
  },
  container: {
    width: "30%", // Set a custom width for the Editor.js container (adjust as needed)
    border: "0.5px solid #ddd",
    borderRadius: "4px",
    marginBottom: "8px",
    padding: "4px",
    wordWrap: "break-word", // Make the content wrap within the specified width
  },
  description: {
    marginBottom: theme.spacing(2),
    color: "#555",
    cursor: "text",
    "&.descriptionExceedsLimit": {
      border: "1px solid red",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "auto",
  },
  actionButton: {
    padding: 4,
  },
}));
const editorStyles = {
  tools: {
    header: {
      "text-align": "left",
      "font-family": "Arial, sans-serif", // Change the font here
      "font-size": "20px", // Adjust the font size as needed
    },
  },
};

const CardData = ({ transcriptId }) => {
  const classes = useStyles();
  let token = localStorage.getItem("outseta_token");

  const [editorTitleData, setEditorTitleData] = useState({}); // Editor.js data for the title
  const [editorDescriptionData, setEditorDescriptionData] = useState({}); // Editor.js data for the description
  const [cardData, setCardData] = useState([]); // Your actual JSON data for the cards
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const titleEditorInstance = useRef({}); // Reference to the Editor.js instances for the title
  const descriptionEditorInstance = useRef({}); // Reference to the Editor.js instances for the description
  const copyLink = (c_id) => {
    const currentUrl = window.location.href;

    const link = `https://app.typedai.com/typedai_share_clip/${c_id}`;
    navigator.clipboard.writeText(link);
  };

  const redirect_transcript = (clip) => {
    let path = `/clips/${clip["T_id"]}/${clip["C_id"]}`;
    navigate(path);
  };
  const saveDataToBackend = async (data, C_id, type) => {
    // Replace 'your-backend-url' with your actual backend URL

    const response = await fetch(
      `${BACKEND_URL}/api_v1/clip_action/?access_token=${token}&C_id=${C_id}&type=${type}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    // Handle the response from the backend if needed
    if (!response.ok) {

      console.error("Failed to save data!");
    }
  };

  const handleTitleChange = (event, clip) => {
    const newTitle = event.target.innerText;
    // Check if the newTitle length exceeds the maximum limit
    if (newTitle.length > MAX_TITLE_LENGTH) {
      // Truncate the title to the maximum limit
      const truncatedTitle = newTitle.slice(0, MAX_TITLE_LENGTH);
      // If you want to show some kind of warning message when the title is truncated, you can do it here

      // Update the clip.title with the truncatedTitle
      clip.title = truncatedTitle;
      // Add the .titleExceedsLimit class to the title element
      event.target.classList.add("titleExceedsLimit");
    } else {
      // If the newTitle length is within the limit, update the clip.title with the newTitle
      clip.title = newTitle;
      // Remove the .titleExceedsLimit class from the title element (if it was added before)
      event.target.classList.remove("titleExceedsLimit");
    }
    // Save the updated title back to the cardData state
    setCardData((prevCardData) =>
      prevCardData.map((item) => (item.C_id === clip.C_id ? clip : item))
    );
    // Save title data to backend
    saveDataToBackend(
      { blocks: [{ type: "header", data: { text: clip.title, level: 1 } }] },
      clip.C_id,
      "title"
    );
  };

  const DeleteClip = async (C_id) => {
    const response = await fetch(
      `${BACKEND_URL}/api_v1/clip_action/?access_token=${token}&C_id=${C_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Handle the response from the backend if needed
    if (response.ok) {
      // If you want to reload the page, you can do it here
      window.location.reload(true);
    } else {
      console.error("Failed to delete data!");
    }
  };
  const handleDescriptionChange = (event, clip) => {
    const newDescription = event.target.innerText;
    // Check if the newDescription length exceeds the maximum limit
    if (newDescription.length > MAX_DESCRIPTION_LENGTH) {
      // If the newDescription exceeds the limit, add the .descriptionExceedsLimit class to the description element
      event.target.classList.add("descriptionExceedsLimit");
    } else {
      // If the newDescription length is within the limit, remove the .descriptionExceedsLimit class from the description element (if it was added before)
      event.target.classList.remove("descriptionExceedsLimit");
    }
    // Update the clip.description with the newDescription (even if it exceeds the limit)
    clip.description = newDescription;
    // Save the updated description back to the cardData state
    setCardData((prevCardData) =>
      prevCardData.map((item) => (item.C_id === clip.C_id ? clip : item))
    );
    // Save description data to backend
    saveDataToBackend(
      { blocks: [{ type: "paragraph", data: { text: clip.description } }] },
      clip.C_id,
      "description"
    );
  };
  const sortByDate = (a, b) => {
    // Assuming each clip object has a 'date' property in ISO 8601 format
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Use the comparison operator to sort the dates in ascending order
    return dateA - dateB;
  };
  useEffect(() => {
    // Fetch the clip data from the backend and update the state when the data is available
    fetch(
      `${BACKEND_URL}/api_v1/clip_action/?access_token=${token}&T_id=${transcriptId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setCardData((prevCardData) => res.sort(sortByDate));
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching clip data:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [transcriptId, token]);
  // ... (previous code)

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        cardData.map((clip) => (
          <Card key={clip.C_id} className={classes.card}>
            <CardMedia
              className={classes.media}
              image={clip.image}
              title={clip.title}
              onClick={() => {
                redirect_transcript(clip);
              }}
            />
            <CardContent
              style={{ paddingBottom: 0, paddingTop: "8px" }}
              className={classes.content}
            >
              {/* Editable title */}
              <Typography
                contentEditable
                suppressContentEditableWarning
                className={classes.title}
                variant="h6"
                onBlur={(event) => handleTitleChange(event, clip)}
              >
                {clip.title}
              </Typography>

              {/* Editable description */}
              <Typography
                contentEditable
                suppressContentEditableWarning
                className={`${classes.description} ${clip.description.length > MAX_DESCRIPTION_LENGTH
                  ? "descriptionExceedsLimit"
                  : ""
                  }`}
                variant="body2"
                onBlur={(event) => handleDescriptionChange(event, clip)}
              >
                {clip.description}
              </Typography>

              {/* Container for the buttons */}
              <div className={classes.buttonContainer}>
                <Tooltip title="Eliminar clip">
                  <IconButton
                    aria-label="Eliminar clip"
                    onClick={() => DeleteClip(clip.C_id)}
                    className={classes.trashbutton}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copiar link">
                  <IconButton
                    aria-label="Copiar Link"
                    onClick={() => copyLink(clip.C_id)}
                    onMouseEnter={() => copyLink(clip.C_id)}
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
};
export default CardData;
