import React, { useState, useEffect, useRef } from "react";
import * as handle from "./handleLayout";
import {
  Paper, List, ListItem, ListItemText, Typography, Tooltip
} from '@material-ui/core';
import { PDFDocument } from 'pdf-lib';
import { useSubscription } from "../../Context/SubscriptionContext";
import { useTranslation } from 'react-i18next';
import useUploadFileChooseFolderStyles from "./Styles/useUploadFilesFolder";
import upload_notes from './SVG/upload_notes.svg';
import trash_icon from './SVG/trash_icon.svg';

const PAGE_CREDIT_PRICE = 0.375
const DragAndDrop = ({ setFiles, is_multiple, limit = 5, setPages, setNumDocuments, maxWidthError = "none", handleDeleteFileLoad }) => {
  const { t } = useTranslation();
  const classes = useUploadFileChooseFolderStyles();

  const [openSpinner, setOpenSpinner] = useState(false);
  const [openUploadedSpinner, setOpenUploadSpinner] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const { userData } = useSubscription();

  useEffect(() => {
    setFiles(selectedFiles);
    if (setNumDocuments) {
      setNumDocuments(selectedFiles.length)
    }
    if (selectedFiles.length === 0) {
      setPages(0)

    }
  }, [selectedFiles]);

  useEffect(() => {
    const handleBeforeUnloadListener = (event) => {
      handle.handleBeforeUnload(event, openSpinner, openUploadedSpinner);
    };
    window.addEventListener("beforeunload", handleBeforeUnloadListener);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnloadListener);
    };
  }, [openSpinner, openUploadedSpinner]);

  window.onbeforeunload = (event) => {
    if (openSpinner || openUploadedSpinner) {
      return t("file_still_uploading");
    }
  };

  const openFileInput = () => {
    handle.openFileInput(fileInputRef);
  };

  const handleDragOver = (event) => {
    handle.handleDragOver(event);
  };

  const handleDeleteFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));


  };

  const MAX_FILE_SIZE = 300 * 1024 * 1024; // 300 MB in bytes

  const validateDuration = (file, onSuccess, onError) => {
    const url = URL.createObjectURL(file);
    const media = file.type.includes("audio") ? new Audio(url) : document.createElement("video");
    media.src = url;

    media.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      const durationInHours = media.duration / 3600;
      if (durationInHours > 2) {
        onError(t("over_limit_hours"));
      } else {
        onSuccess(file);
      }
    };

    media.onerror = () => {
      URL.revokeObjectURL(url);
      onError(t("error_loading_file"));
    };
  };

  let totalPdfPages = 0;
  let validFiles = [];
  let invalidFiles = [];
  let filesProcessed = 0;
  let filePageCounts = [];

  const handleFileSelection = (files) => {
    validFiles = [];
    invalidFiles = [];
    filesProcessed = 0;
    filePageCounts = [];

    Array.from(files).forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        const alertMessage = t('file_too_large', { fileName: file.name, maxFileSize: MAX_FILE_SIZE });
        alert(alertMessage);
        invalidFiles.push(file);
        checkAllFilesProcessed(files.length);
      } else if (file.type.includes("audio") || file.type.includes("video")) {
        alert("We do not allow video or audio files")
        // validateDuration(
        //   file,
        //   (validFile) => {
        //     validFiles.push({ file: validFile, size: validFile.size });
        //     checkAllFilesProcessed(files.length);
        //   },
        //   (errorMsg) => {
        //     alert(`File ${file.name}: ${errorMsg}`);
        //     invalidFiles.push(file);
        //     checkAllFilesProcessed(files.length);
        //   }
        // );
      } else if (file.type === "application/pdf") {

        processPDF(file).then(() => checkAllFilesProcessed(files.length));
      } else if (file.type !== "application/pdf") {

        alert(t("only_pdf"))
      }
      else {
        validFiles.push({ file, size: file.size });
        checkAllFilesProcessed(files.length);
      }
    });
  };

  const checkAllFilesProcessed = (totalFiles) => {
    filesProcessed++;
    if (filesProcessed === totalFiles) {
      const existingSelectedFilesPages = selectedFiles.reduce((acc, fileInfo) => acc + fileInfo.pages, 0);
      const processedTotalPages = filePageCounts.reduce((acc, pages) => acc + pages, 0);
      const totalCombinedPages = existingSelectedFilesPages + userData.pages + processedTotalPages;

      if (userData && totalCombinedPages > userData.maxPages) {
        const alertMessage = t('pages_exceed_limit', { totalCombinedPages, maxPages: userData.maxPages });
        alert(alertMessage);
        validFiles = [];
      } else {
        totalPdfPages += processedTotalPages;
        processFiles(validFiles);
      }
      filePageCounts = [];
    }
  };

  const processPDF = async (file) => {
    try {
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer(), { ignoreEncryption: true });
      console.log("PDFDOC ", pdfDoc)
      const numberOfPages = pdfDoc.getPageCount();
      let filename = file.name
      let alertMessage = t('exceed_pages', { document_name: filename })
      if (numberOfPages > 200) {
        setErrorMessages(prevMessages => [...prevMessages, alertMessage]);
        invalidFiles.push(file);
      } else {
        validFiles.push({ file, size: file.size, pages: numberOfPages });
        filePageCounts.push(numberOfPages);
        setErrorMessages([])
        if (setNumDocuments) {
          setNumDocuments(validFiles.length)
        }
        setPages(numberOfPages)
      }
    } catch (error) {
      console.error("Error reading PDF file:", error);
      alert(t("error_processing_file"), error);
      invalidFiles.push(file);
    }
  };

  const processFiles = (validFiles) => {
    const newFilesArray = validFiles.map(fileInfo => {
      const { file, size, pages } = fileInfo;
      let formattedSize = size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / 1024 / 1024).toFixed(2)} MB`;
      return {
        file: file,
        size: formattedSize,
        pages: pages,
      };
    });

    if (limit === 1) {
      setSelectedFiles(newFilesArray.slice(0, 1)); // Only keep the latest uploaded file
    } else {
      if (newFilesArray.length > 3 || selectedFiles.length === 3) {
        alert("Has subido más de 3 documentos.")
      } else {


        setSelectedFiles(prevFiles => {
          const updatedFiles = [...prevFiles, ...newFilesArray];
          // Limit the selected files to a maximum of 3
          return updatedFiles.slice(0, 3);
        });
      }
    }
  };

  return (
    <>
      <div
        className={classes.container}
        onClick={openFileInput}
        onDragOver={handleDragOver}
        onDrop={(event) => {
          event.preventDefault();
          const files = event.dataTransfer.files;
          if (files.length > 0) {
            handleFileSelection(files);
          }
        }}
      >
        <div>
          <img src={upload_notes} />
        </div>
        <div>
          <Typography className={classes.text}>
            {t('drag_and_drop')}
            <span style={{ color: '#026277', fontWeight: 500, textDecoration: "underline" }}>{t('browse')}</span>
          </Typography>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          accept=".pdf"
          style={{ display: "none" }}
          multiple={is_multiple}
          onChange={(event) => {
            const files = event.target.files;
            if (files.length > 0) {
              handleFileSelection(files);
            }
          }}
        />
      </div>
      <div><Typography style={{ fontSize: 10, color: "gray" }}>{t("max_pages")} // Máximo 3 ficheros </Typography></div>

      {selectedFiles.length > 0 && (
        <>
          <Typography className={classes.text} style={{ marginTop: 10 }}>
            {t("files_selected")}
          </Typography>
          <List component={Paper} className={classes.fileListContainerStyle} style={{
            '& .MuiPaper-root': {
              borderRadius: "0px !important",
            },
            borderRadius: "0px !important",
          }}>
            {selectedFiles.map((fileInfo, index) => (
              <ListItem key={index} className={classes.listItem}>
                <ListItemText
                  primary={
                    <Tooltip title={fileInfo.file.name} placement="top">
                      <span style={{ fontSize: "10px" }}>
                        {fileInfo.file.name.length > 20 ? fileInfo.file.name.substring(0, 20) + '...' : fileInfo.file.name}
                      </span>
                    </Tooltip>
                  }
                />
                <img src={trash_icon} onClick={() => { handleDeleteFile(index); handleDeleteFileLoad() }} style={{ width: "20px" }} />
              </ListItem>
            ))}
          </List>
        </>
      )}
      {errorMessages.length > 0 && (
        <div style={{ width: maxWidthError }}>
          {errorMessages.map((message, index) => (
            <Typography key={index} style={{ color: 'red', fontSize: "8px" }}>
              {message}
            </Typography>
          ))}
        </div>
      )}



    </>
  );
};

export default DragAndDrop;
