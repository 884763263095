import {
    Box,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Button,
    Container
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Chat from "../NewComponents/Chat";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'; // Add these imports for arrow icons

import EditIcon from '@material-ui/icons/Edit';
import { useParams } from "react-router-dom";
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { fetchAllCorrectAIRemarks, fetchExamId } from "../Api/ExamsApi";
import Document from "../Documents/Document";
import Questions from "./Questions/QuestionsView";
import "./Styles/style.css";
import useExamViewStyles from "./Styles/useExamViewStyles";
import CorrectInfo from "./CorrectInfo";
import { fetchQuestionAnswer, fetchQuestionAnswerId } from "../Api/QuestionsApi";
import ModalParent from "../AllModals/ParentModal";
import * as handle from "../Main/handle";
import FullScreenLoader from "../NewComponents/LoadingExam";
import NeedCredits from "../NoCreditsModal/NeedCredits";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



function ExamView() {
    const classes = useExamViewStyles();

    const { accessToken, user } = useAuth(); // Use the hook at the top level

    const [messages, setMessages] = useState([]); // Move messages state up

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
    const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
    const { t } = useTranslation();
    const [examData, setExamData] = useState({ questions: [] });
    const [pdfData, setPDFData] = useState({ questions: [] });
    const [answers, setAnswers] = useState([]);
    const [isPdfExam, setIsPdfExam] = useState(false)
    const { exam_id } = useParams();
    const [corrected, setCorrected] = useState(false)
    const [allCorrected, setAllCorrected] = useState(false)
    const [needReset, setNeedReset] = useState(false)
    const [improve, setImprove] = useState([])
    const [finalScore, setFinalScore] = useState("")
    const [listScore, setListScore] = useState([])
    // Managing state of quesiton 
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Add this state

    const [validatedQuestions, setValidatedQuestions] = useState({});
    const navigate = useNavigate();


    // Assuming modalOpenUpload is a boolean to control the visibility of a modal
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [titleOptionUpload, setTitleOptionUpload] = useState('');
    const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
    const [buttonOptionUpload, setButtonOptionUpload] = useState('');
    const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
    const [typeUpload, setTypeUpload] = useState('');
    const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [correctingExam, setCorrectingExam] = useState(false);
    const [width, setWidthModal] = useState("");
    const [isPreparingExam, setIsPreparingExam] = useState(false);

    async function fetchExam() {
        if (accessToken && user) {
            try {

                const examDataFetch = await fetchExamId(accessToken, "GET", exam_id, user);
                if (examDataFetch['is_pdf_exam']) {

                    setIsPdfExam(examDataFetch['is_pdf_exam'])

                }
                setImprove(examDataFetch['notes'])
                setPDFData(examDataFetch)
                setEditedTitle(examDataFetch['title'])
                const scores_ = examDataFetch.questions.map(question => question.punctuation);
                const totalScore = scores_.reduce((acc, score) => acc + score, 0);

                setListScore(scores_)
                setExamData(examDataFetch)
                setFinalScore(Math.round(examDataFetch['final_score']))
                const initialAnswers = {};
                const initialValidated = {};
                examDataFetch.questions.forEach(question => {
                    initialAnswers[question.guid] = {
                        answer: question.question_answer || '',
                    };
                    initialValidated[question.guid] = question.punctuation_active || false;
                });

                setAnswers(initialAnswers);
                setValidatedQuestions(initialValidated);

            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {


        fetchExam(); // Call the fetchData function


    }, [exam_id, accessToken, user])

    const handleCorrectCurrentQuestion = async () => {

        const question = examData.questions[currentQuestionIndex];
        const answer = answers.find(a => a.hasOwnProperty(question.guid)) || {};
        const payload = {
            answer: answer[question.guid]
        };

        try {
            // Make your API call with the payload containing all question IDs and answers
            let corrected_answer = await fetchQuestionAnswerId(accessToken, "POST", question.guid, user, payload);

        } catch (error) {
            console.error('Failed to submit answers:', error);
            // Handle error
        }

    };

    const handleQuestionCorrection = async (questionId) => {
        // Update the state to mark the question as validated
        setValidatedQuestions((prevValidated) => {
            return { ...prevValidated, [questionId]: true };
        });

        // Check if all questions are validated after the state update
        const allQuestionsValidated = examData.questions.every(question =>
            validatedQuestions[question.guid] || question.guid === questionId
        );

        if (allQuestionsValidated) {
            // All questions are validated, set allCorrected to true
            setAllCorrected(true);

            // Call the API since all questions are now validated
            try {
                let improveCorrection = await fetchAllCorrectAIRemarks(accessToken, "POST", exam_id, user);
                setImprove(improveCorrection['notes']);
                console.log("improveCorrection", improveCorrection)
                setFinalScore(Math.round(improveCorrection['final_score']));
                const examDataFetch = await fetchExamId(accessToken, "GET", exam_id, user);
                const scores_ = examDataFetch.questions.map(question => question.punctuation);

                setListScore(scores_)
            } catch (error) {
                console.error("Failed to fetch AI correction remarks:", error);
            }
        }
    };


    useEffect(() => {
        const allQuestionsValidated = examData.questions.every(question => validatedQuestions[question.guid]);
        setAllCorrected(allQuestionsValidated);
    }, [validatedQuestions, examData.questions]);


    // const handleAnswerChange = (questionId, answer_enter) => {
    //     console.log("answer enter ", answer_enter)
    //     console.log("answer ", answers)
    //     setAnswers((prevAnswers) => ({
    //         ...prevAnswers,
    //         [questionId]: answer_enter,
    //     }));
    // };
    const handleAnswerChange = (questionId, answer_enter) => {
        setNeedReset(false)
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: {
                ...prevAnswers[questionId],
                answer: answer_enter,
            },
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Submit logic goes here
    };

    const handleAnswers = async () => {


        setCorrectingExam(true)
        const processedAnswers = Object.keys(answers).map(questionId => {
            return { [questionId]: answers[questionId].answer || '' };
        });
        setNeedReset(false)


        const payload = {
            questions_answers: processedAnswers,
        };
        try {
            // Make your API call with the payload containing all question IDs and answers
            const examCorrection = await fetchQuestionAnswer(accessToken, "POST", exam_id, user, payload);
            const examDataFetchProcessed = examCorrection['data_exam']
            setExamData(examDataFetchProcessed)
            setImprove(examCorrection['correction_notes'])
            const scores_ = examDataFetchProcessed.questions.map(question => question.punctuation);
            const totalScore = scores_.reduce((acc, score) => acc + score, 0);

            setListScore(scores_)
            setFinalScore(Math.round(totalScore / scores_.length * 10));

            const correctedData = examDataFetchProcessed.questions.reduce((acc, question) => {
                acc[question.guid] = {
                    correctAnswer: question.question_answer,
                    punctuation: question.punctuation
                };
                return acc;
            }, {});
            fetchExam(); // Call the fetchData function
            setAllCorrected(true);
            setCorrectingExam(false);


        } catch (error) {
            console.error('Failed to submit answers:', error);
            // Handle error
        }
    };

    const handleNextQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, examData.questions.length - 1));
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    // Title editing
    const [editMode, setEditMode] = useState(false);
    const [editedTitle, setEditedTitle] = useState(""); // Local state for edited title

    useEffect(() => {
        setEditedTitle(examData.title); // Update local state when examData changes
    }, [exam_id]);

    const handleTitleChange = (event) => {
        setEditedTitle(event.target.value);
    };

    const handleBlur = async () => {
        setEditMode(false);
        // Placeholder for update logic
        // Update examData.title or perform API call to save the updated title
        let payload = { "title": editedTitle }
        const response = fetchExamId(accessToken, "PUT", exam_id, user, payload)
        setExamData(prevExamData => ({
            ...prevExamData,
            title: editedTitle
        }));
        // Assuming updateExamTitle is a function to update the title
        // updateExamTitle(editedTitle);
    };
    const resetExam = async () => {
        let payload = { "reset_exam": true }

        try {
            const examDataFetch = await fetchExamId(accessToken, "PUT", exam_id, user, payload)
            setExamData(examDataFetch)

            // Clear the answers and reset the corrected state
            const clearedAnswers = examDataFetch.questions.reduce((acc, question) => {
                acc[question.guid] = { answer: '' };
                return acc;
            }, {});

            // Reset validatedQuestions state to mark all questions as not validated
            const resetValidatedQuestions = examDataFetch.questions.reduce((acc, question) => {
                acc[question.guid] = false;
                return acc;
            }, {});

            setAnswers(clearedAnswers);
            setValidatedQuestions(resetValidatedQuestions);
            setCorrected(false);
            setNeedReset(true);
            setCurrentQuestionIndex(0)
        } catch (error) {
            console.error('Failed to reset exam:', error);
            // Handle error
        }
    };
    const docInfo = React.useMemo(() => ({
        url_stored: pdfData['pdf_link'] // or any dynamic value
    }), [pdfData['pdf_link']]);
    const handleCreateExam = () => {
        handle.handleCreateExam(
            t,
            setModalOpenUpload,
            setTitleOptionUpload,
            setDescriptionOptionUpload,
            setButtonOptionUpload,
            setPlaceholderMessageUpload,
            setTypeUpload,
            setWidthModal,

        );
    };
    const truncateText = (text, maxLength) => {
        if (!text) {
            return ""; // Return an empty string if text is null, undefined, or empty
        }
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    useEffect(() => {
        if (isPreparingExam) {
            const timer = setTimeout(() => {
                setIsPreparingExam(false);
            }, 4000);
            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
        }
    }, [isPreparingExam]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (correctingExam) {



            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const increment = Math.random() * 4; // This determines the speed and variability of the progress
                    const newProgress = oldProgress + increment;
                    if (newProgress >= 100) {
                        // When progress exceeds 100, restart from 0 immediately
                        return 0;
                    } else {
                        return newProgress;
                    }
                });
            }, 300);

            return () => clearInterval(interval); // Clean up the interval when the component unmounts or dependencies change

        }

    }, [correctingExam]);

    const updateQuestionData = (questionGuid, updatedData) => {
        setExamData((prevExamData) => {
            const updatedQuestions = prevExamData.questions.map((question) =>
                question.guid === questionGuid ? { ...question, ...updatedData } : question
            );

            return {
                ...prevExamData,
                questions: updatedQuestions,
            };
        });
    };

    return (
        <>
            {showAlert &&
                <SmallAlert
                    message={message}
                    onClose={() => setShowAlert(false)}
                />
            }
            {
                correctingExam && (
                    <div className={classes.step3Container}>
                        <div className={classes.step3Content}>
                            <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Animation+1718118643424.gif" className={classes.step3Image} />

                            <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#026277" }}>{t('exam_correcting')}</Typography>
                            <Box sx={{ width: '100%', my: 2 }}>
                                <Box sx={{
                                    width: '100%',
                                    height: '10px',
                                    bgcolor: '#8db8c2',
                                    borderRadius: '6px',
                                    overflow: 'hidden' // Ensure the inner box doesn't overflow the rounded corners
                                }}>
                                    <Box sx={{
                                        width: `${progress}%`, // This represents the progress
                                        height: '100%',
                                        bgcolor: '#026277', // Color of the progress indicator
                                    }} />
                                </Box>
                                <Typography style={{ fontWeight: 500, color: "gray", fontSize: "12px" }}>{t("process_take_time")}</Typography>
                            </Box>
                        </div>
                    </div>
                )
            }
            {
                <NeedCredits open={showUpgrade} message={message}

                    onClose={() => setShowUpgrade(false)} />
            }
            {
                modalOpenUpload && (
                    <ModalParent
                        open={modalOpenUpload}
                        title={titleOptionUpload}
                        description={descriptionOptionUpload}
                        sendButton={buttonOptionUpload}
                        placeholdMessage={placeholderMessageUpload}
                        type={typeUpload}
                        onClose={() => setModalOpenUpload(false)}
                        setShowSpinner={setShowSpinnerUpload}
                        setShowAlert={setShowAlertUpload}
                        setMessage={setMessageUpload}
                        setProgressBar={setProgressBar}
                        setShowUpgrade={setShowUpgrade}
                        width={width}
                        setIsPreparingExam={setIsPreparingExam}
                        setFolderSelectedGuid={setFolderSelectedGuid}
                        setDocumentSelectedGuid={setDocumentSelectedGuid}


                    />
                )
            }
            {isPreparingExam && <FullScreenLoader
                imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
                title={t('fullScreenLoader.examPreparingTitle')}
                message={t('fullScreenLoader.examPreparingMessage')}
                buttonText={t('fullScreenLoader.examPreparingButton')}
                onClose={() => { setIsPreparingExam(false); }}
                onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
            />}




            <Container
                container
                spacing={3}
                className={classes.gridContainer}
            >
                <>
                    <Grid item className={classes.leftPart}>
                        {/* Adjust grid sizes for responsiveness */}



                        <Paper elevation={3} className={classes.paperLeft} style={{ height: "100%" }}>


                            <div style={{
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'space-between',
                                // width: "100%",
                                // margin: "0 auto",
                                // marginTop: '40px', // Adjust top margin of container instead of using 'top' property
                                // overflowX: "hidden",

                            }}>
                                {/* Title with fixed position */}
                                {editMode ? (
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Enter title here"
                                        value={editedTitle}
                                        onChange={handleTitleChange}
                                        onBlur={handleBlur}
                                        style={{
                                            flexGrow: 1, // Allow TextField to take up available space
                                        }}
                                    />
                                ) : (
                                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between" margin="20px 0px">
                                        {/* <Typography>HELLOOOO</Typography> */}
                                        <div style={{ display: 'flex', flexDirection: "row", }}>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                    marginRight: 20, // Space between text and icon
                                                    fontSize: "18px",
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                {truncateText(examData.title, 30)}

                                            </Typography>
                                            <IconButton style={{ padding: "0px" }} onClick={() => setEditMode(true)}>
                                                <EditIcon />
                                            </IconButton>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            <Button className={classes.buttonNewExam} onClick={handleCreateExam}>
                                                &nbsp;{t("new_exam")}</Button>
                                            {allCorrected ? (
                                                <>

                                                    <RefreshIcon onClick={resetExam} style={{ cursor: "pointer", color: "#026277", marginTop: "3px" }} />
                                                </>
                                            ) : (
                                                <>
                                                    <Button className={classes.buttonUpload} onClick={handleAnswers}>{t("correct_exam")}</Button>
                                                </>

                                            )}
                                        </div>
                                    </Box>
                                )}
                                {/* Image with left margin to push it away from the title */}
                                {/* <img src={icon_pdf} style={{ marginLeft: '850px' }} /> Adjust the marginLeft value as needed */}
                            </div>

                            {/* Scrollable form container */}
                            <div style={{
                                top: "115px",
                                bottom: 0,
                                overflowY: 'auto', // Enable vertical scrolling
                                paddingTop: '15px', // Adjust padding as needed


                            }}
                            >
                                <form >
                                    {examData.questions.length > 0 && (
                                        <Questions
                                            key={examData.questions[currentQuestionIndex].guid}
                                            questionNumber={currentQuestionIndex + 1}
                                            question={examData.questions[currentQuestionIndex]}
                                            answer={answers[examData.questions[currentQuestionIndex].guid]?.answer || ""}
                                            onAnswerChange={handleAnswerChange}
                                            onQuestionCorrection={handleQuestionCorrection}

                                            token={accessToken}
                                            user={user}
                                            corrected={corrected}
                                            setCorrected={setCorrected}
                                            valueAnswer={examData.questions[currentQuestionIndex].punctuation}
                                            reset={needReset}
                                            validated={validatedQuestions[examData.questions[currentQuestionIndex].guid] || false}
                                            updateQuestionData={updateQuestionData} // Add this line

                                        />
                                    )}

                                </form>
                            </div>

                            <Box display="flex" justifyContent="center" alignItems="center" >
                                <IconButton
                                    onClick={handlePreviousQuestion}
                                    disabled={currentQuestionIndex === 0}
                                    style={{ color: '#6BBDBD' }}
                                >
                                    <ArrowBackIos style={{ fontSize: 'small' }} />
                                </IconButton>
                                <Typography variant="body1" style={{ color: '#6BBDBD' }}>
                                    {currentQuestionIndex + 1} de {examData.questions.length}
                                </Typography>
                                <IconButton
                                    onClick={handleNextQuestion}
                                    disabled={currentQuestionIndex === examData.questions.length - 1}
                                    style={{ color: '#6BBDBD' }}
                                >
                                    <ArrowForwardIos style={{ fontSize: 'small' }} />
                                </IconButton>
                            </Box>

                        </Paper>

                    </Grid >

                    <Grid item className={classes.rightPart}>
                        {/* Adjust grid sizes for responsiveness */}
                        <Paper elevation={3} className={classes.paperright}


                        >
                            {allCorrected ? (

                                <><CorrectInfo listScores={listScore} final_score={finalScore} items={improve} /></>
                            ) : (<Chat
                                height={"100%"}
                                messages={messages}
                                setMessages={setMessages}
                                accessToken={accessToken} // Passing accessToken as a prop
                                user={user}
                                folder={examData.document?.folder?.guid ?? ""}
                                document={examData.document?.guid ?? ""}

                            />)}



                        </Paper>
                    </Grid>
                </>
            </Container >

        </>
    );
}

export default ExamView;
