
import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function
import { getCsrfToken } from '../Api/TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const MAX_TITLE_LENGTH = 2; // Replace 100 with your desired character limit



export const onCloseSearchBar = (setSearchBarVisible, contentRef, classes) => {
  removeAllHighlights(contentRef, classes); // Call the function to remove highlights
  setSearchBarVisible(false);
};

export const removeAllHighlights = (contentRef, classes) => {
  const content = contentRef.current;
  if (content) {
    const words = content.querySelectorAll(".word"); // Assuming your words have a class 'word'
    words.forEach((wordElement) => {
      wordElement.classList.remove(classes.pinkBackground); // Remove the 'highlighted' class
    });
  }
};

export const handleSearch = (
  input,
  setSearchTerms,
  contentRef,
  classes,
  currentIndex,
  setCurrentIndex
) => {
  if (input.trim() !== "") {
    const terms = input.trim().toLowerCase().split(" ");
    setSearchTerms(terms);
    const content = contentRef.current;

    if (content) {
      const words = content.querySelectorAll(".word");

      const foundWords = Array.from(words).filter((wordElement) => {
        return terms
          .filter((term) => term.trim() !== "")
          .some((term) => wordElement.textContent.toLowerCase().includes(term));
      });

      // Apply the pink background to all words in foundWords
      foundWords.forEach((wordElement) => {
        wordElement.classList.add(classes.pinkBackground);
      });

      // Remove the pink background from words not in foundWords
      words.forEach((wordElement) => {
        if (!foundWords.includes(wordElement)) {
          wordElement.classList.remove(classes.pinkBackground);
        }
      });

      if (foundWords.length > 0) {
        const nextIndex = (currentIndex + 1) % foundWords.length;
        const selectedWord = foundWords[nextIndex];

        // Scroll to the position of the selected word element
        selectedWord.scrollIntoView({ behavior: "smooth" });

        // Update the current index state
        setCurrentIndex(nextIndex);

      } else {
        // No matches found, reset the current index state and styles
        setCurrentIndex(-1);
      }
    }
  } else {
    const content = contentRef.current;
    const terms = input.trim().toLowerCase().split(" ");

    if (content) {
      const words = content.querySelectorAll(".word");
      const foundWords = Array.from(words).filter((wordElement) => {
        return terms
          .filter((term) => term.trim() !== "")
          .some((term) => wordElement.textContent.toLowerCase().includes(term));
      });

      // Apply the pink background to all words in foundWords
      foundWords.forEach((wordElement) => {
        wordElement.classList.add(classes.pinkBackground);
      });
      words.forEach((wordElement) => {
        if (!foundWords.includes(wordElement)) {
          wordElement.classList.remove(classes.pinkBackground);
        }
      });
    }
  }
};

export const onNextOccurrence = (
  highlightedWordsSearch,
  setHighlightedWordsSearch
) => {
  handleNextOccurrence(highlightedWordsSearch, setHighlightedWordsSearch); // Call your existing function for navigating to the next occurrence
};

export const handleNextOccurrence = (
  highlightedWordsSearch,
  setHighlightedWordsSearch
) => {
  const { occurrences, currentIndex } = highlightedWordsSearch;
  if (occurrences.length > 0 && currentIndex < occurrences.length - 1) {
    // Move to the next occurrence
    setHighlightedWordsSearch({
      ...highlightedWordsSearch,
      currentIndex: currentIndex + 1,
    });

    // Scroll to the position of the next found word element
    occurrences[currentIndex + 1].scrollIntoView({ behavior: "smooth" });
  }
};

export const toggleSearchBar = (setSearchBarVisible, searchBarVisible) => {
  setSearchBarVisible(!searchBarVisible);
};

export const handleTextHighlight = (
  transjson,
  setHighlightedWords,
  setShowDialog,
  setFirstWordTime,
  setLastWordTime
) => {
  const selection = window.getSelection();
  const selectedRange = selection.getRangeAt(0);
  const selectedText = selectedRange.toString();

  if (selectedText) {
    const selectedWords = transjson.reduce((acc, message, messageIndex) => {
      const words = message.words
        .filter((word, wordIndex) => {
          const wordElement = document.getElementById(
            `word-${messageIndex}-${wordIndex}`
          );
          const wordRange = document.createRange();
          wordRange.selectNodeContents(wordElement);
          return selectedRange.intersectsNode(wordElement);
        })
        .map((word) => ({
          ...word,
          messageIndex, // Add the message index for reference
        }));
      return acc.concat(words);
    }, []);
    if (selectedWords.length > 0) {
      const firstWord = selectedWords[0];
      const lastWord = selectedWords[selectedWords.length - 1];

      setHighlightedWords({ first: firstWord.word, last: lastWord.word });
      setShowDialog(true); // Show the dialog
      setFirstWordTime(firstWord.time_end);
      setLastWordTime(lastWord.time_end);
    }
  } else {
    setHighlightedWords({ first: null, last: null });
  }
};




export const getJson = async (urlTranscript) => {

  try {
    // Split the URL into parts

    // Extract and re-join the desired parts
    var jsonUrl = urlTranscript.match(/(.*\/)[^\/]*$/)[1];
    var jsonUrl = await decryptFileUrl(jsonUrl + "transcript.json", token);

    const response = await fetch(
      jsonUrl
    );

    if (!response.ok) {
      throw new Error("Error fetching transcript.json");
    }
    const res = await response.json();

    // This is wrong; we should get speakers from recall
    return res;
  } catch (error) {
    console.error(error);

  }
};

export const getSummary = async (setHtml, urlTranscript) => {
  try {
    var jsonUrl = urlTranscript.match(/(.*\/)[^\/]*$/)[1];

    var summaryUrl = await decryptFileUrl(jsonUrl + "summary.html");

    const response = await fetch(
      summaryUrl
    );

    if (response.ok) {
      const text = await response.text();
      setHtml(true);
      return text;
    } else {
      throw new Error("Network response was not ok.");
    }
  } catch (error) {
    console.error(error);
  }
};

export const decryptFileUrl = async (fileUrl, token) => {
  try {
    const csrfToken = getCsrfToken()

    const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
      method: 'POST',
      body: JSON.stringify({ fileUrl: fileUrl }),
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken, 'Authorization': `Bearer ${token}` }
    });

    if (!response.ok) {
      throw new Error("Error decrypting file URL");
    }

    const decryptedUrl = await response.json();
    return decryptedUrl; // Assuming the response contains the decrypted URL
  } catch (error) {
    console.error(error);
    return null; // Handle null case in calling function
  }
};






export const handleClickOpenShare = (setOpenShare) => {
  setOpenShare(true);
};

export const handleCloseShare = (setOpenShare) => {
  setOpenShare(false);
};

export const handleCloseDialog = (setShowDialog) => {
  setShowDialog(false);
};

export const handleMouseEnter = (setIsHovered) => {
  setIsHovered(true);
};

export const handleMouseLeave = (setIsHovered) => {
  setIsHovered(false);
};
export const handleOpen = (setOpen) => {
  setOpen(true);
};
export const handleClose = (setOpen) => {
  setOpen(false);
};
export const handleWordClick = (word, setMoment) => {
  setMoment(word.time_begin);
};
export const handleTitleChange = (event, transcript) => {
  const newTitle = event.target.innerText;
  // Check if the newTitle length exceeds the maximum limit
  if (newTitle.length > MAX_TITLE_LENGTH) {
    // Truncate the title to the maximum limit
    const truncatedTitle = newTitle.slice(0, MAX_TITLE_LENGTH);
    // If you want to show some kind of warning message when the title is truncated, you can do it here

    // Update the clip.title with the truncatedTitle
    transcript.title = truncatedTitle;
    // Add the .titleExceedsLimit class to the title element
    event.target.classList.add("titleExceedsLimit");
  } else {
    // If the newTitle length is within the limit, update the clip.title with the newTitle
    transcript.title = newTitle;
    // Remove the .titleExceedsLimit class from the title element (if it was added before)
    event.target.classList.remove("titleExceedsLimit");
  }
  // Save the updated title back to the cardData state
  // Save title data to backend
  saveDataToBackend(newTitle, transcript.T_id);
};

export const saveDataToBackend = async (newTitle, T_id) => {
  // Replace 'your-backend-url' with your actual backend URL
  const csrfToken = getCsrfToken()

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", 'X-CSRFToken': csrfToken },
    body: JSON.stringify({ title: newTitle, T_id: T_id, option: "rename" }),
  };
  const response = await fetch(
    `${BACKEND_URL}/api_v1/show_transcript/?access_token=` +
    localStorage.getItem("outseta_token"),
    requestOptions
  ).then((res) => {
    if (res.status != 202) {
      alert("No tienes permisos para cambiar el nombre a esta transcripción");
    }
  });

  // Handle the response from the backend if needed
  if (response.ok) {
    console.log("Data saved successfully!");
  } else {
    console.error("Failed to save data!");
  }
};
let token = localStorage.getItem("outseta_token");



export const handleRowClick = (id, navigate) => {


  const currentPath = window.location.pathname; // Get the current path
  const newPath = `${currentPath}/${id}`; // Append the new ID to the current path
  navigate(newPath); // Use navigate to redirect
};