import React, { useState, useEffect } from 'react';
import UploadFilesFolder from "../NewComponents/Upload/UploadFileFolder";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Typography, Box
} from "@material-ui/core";
import ShowFolders from '../NewComponents/Upload/ShowFolders';
import { fetchUserFiles } from '../Api/FilesApi';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '26px'
        },
        buttonUpload: {
            backgroundColor: "#026277",
            color: "white",
            textTransform: "none",
            borderRadius: "6px",
            fontSize: "10px",
            display: "flex",
            alignItems: "flex-end",
            gap: "2px",
            width: "30px",

            padding: "6px 12px", // Added padding for better appearance,
            '&:disabled': {
                backgroundColor: '#B0B0B0', // Gray color for the disabled state
                color: '#FFFFFF'           // White text for the disabled state
            },
            '&:hover': {
                backgroundColor: '#026277', // Gray color for the disabled state
                color: "white",
                // padding: "8px 14px", // Added padding for better appearance

            }
        },
    };

});
const ModalUploadFiles = ({ handleButtonClick, setShowSpinner,
    setShowAlert,
    setMessage,
    setProgressBar,
    setShowUpgrade, user, accessToken, onClose, updateListDocuments, setCredits, currentCredits }) => {
    const classes = useStyles();
    const { folder_id, selectedFolderParam } = useParams();

    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [value, setValue] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [pages, setPages] = useState("")
    const [numDocuments, setNumDocuments] = useState("")
    const [requiredCredits, setRequiredCredits] = useState(40);

    // useEffect(() => {
    //     const creditsNeeded = numDocuments * 20;
    //     setRequiredCredits(creditsNeeded);
    //     setCredits(creditsNeeded);
    // }, [numDocuments]);
    const handleSetValue = (value) => {
        setSelectedFolder(value);
        // Perform action based on selected folder
    };

    const handleNewFolder = (folderName) => {
        setNewFolderName(folderName);
        // Perform action based on new folder name
    };
    const navigate = useNavigate();

    const handleUploadClick = () => {

        handleButtonClick({
            typed: "upload",
            file: files,
            value: folder_id || selectedFolder,
            newFolderName,
            setShowSpinner,
            setShowAlert,
            setMessage,
            setProgressBar,
            setShowUpgrade,
            user: user,
            token: accessToken,
            onClose: onClose,
            navigate: navigate,
            updateListDocuments: updateListDocuments


        });

    };
    console.log("seectec folder ", selectedFolder, "newFolderName ", newFolderName, "folder_id ", folder_id);
    return (
        <div style={{ marginTop: "10px" }}>
            <Typography className={classes.title} style={{ marginBottom: "10px" }}>{t("load_your_notes_desc")}</Typography>

            <UploadFilesFolder files={files} setFiles={setFiles} is_multiple={true} setPages={setPages} setNumDocuments={setNumDocuments} />
            {!folder_id && (


                <div style={{ marginTop: "14px" }}>

                    <FormControl required error={error}>
                        {/* Call api to show list of folders */}

                        <ShowFolders
                            value={selectedFolder}
                            setValue={handleSetValue}
                            handleNewFolder={handleNewFolder}
                            margin="dense"
                        />
                    </FormControl>


                </div>
            )}
            <Box display="flex" justifyContent="flex-end" marginTop="10px">
                <Button disabled={files.length == 0 || (selectedFolder == "" && newFolderName == "" && (folder_id === "" || folder_id === undefined))} className={classes.buttonUpload} onClick={handleUploadClick} >{t("upload_button")}</Button>
            </Box>
        </div>

    );
};

export default ModalUploadFiles;
