import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';

import { useAuth } from "../Auth/Authhandle";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import { Typography } from "@material-ui/core";
import "./style.css"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

const ReturnStripe = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const { accessToken, user } = useAuth(); // Use the hook at the top level


    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        if (accessToken && sessionId) {
            fetch(`${BACKEND_URL}/session-status?session_id=${sessionId}`,
                {
                    headers: { 'Authorization': `Bearer ${accessToken}` }
                })
                .then((res) => res.json())
                .then((data) => {
                    setStatus(data.status);
                    setCustomerEmail(data.customer_email);
                });
        }
    }, [accessToken]);
    const text = "Gracias por subscribirte a typed.";

    if (status === 'open') {
        return (
            <Navigate to="/checkout" />
        )
    }

    if (status === 'complete') {
        return (
            <Navigate to="/" />
        )
    }

    return null;
}

export default ReturnStripe;