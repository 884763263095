import { makeStyles } from '@material-ui/core/styles';

const useExamListStyles = makeStyles((theme) => ({
    gridContainer: {
        width: '100%',
        margin: '0 auto',
        maxWidth: "calc(100% - 250px)",
        border: "1px solid #6BBDBD",
        borderRadius: "20px",
        backgroundColor: "white",
        paddingBottom: "20px"
    },
    tableHeader: {
        backgroundColor: theme.palette.background.paper, // ensure it has a solid background
        position: "sticky",
        top: 0,
        zIndex: 2, // higher than table body content
        fontWeight: 600,
    },
    titleSection: {
        fontSize: 14,
        fontWeight: 600,
        marginLeft: 50,
        color: "var(--slate-900, #0F172A)",
        marginTop: "20px",
        fontStyle: "normal",
        lineHeight: "36px",
        letterSpacing: "-0.225px",
    },
    tableContainer: {
        overflowY: 'auto',
        boxShadow: "none",
        width: "90%",
        marginLeft: "50px",
        // border: "1px solid black",
    },
    revisarButton: {
        color: "white",
        backgroundColor: "#026277",
        textTransform: "none !important",
        '&:hover': {
            backgroundColor: "white",
            color: "#026277",
        },
        fontSize: '12px !important'
    },
    eliminarButton: {
        color: "white",
        backgroundColor: "red",
        textTransform: "none !important",
        '&:hover': {
            backgroundColor: "white",
            color: "red",
        },
        fontSize: '12px !important'
    },
    controllerButton: {
        textTransform: "none !important",
    },
    noExams: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.text.secondary,
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: 'rgba(2, 98, 119, 0.20) !important', // Use !important to override Material-UI default hover styles if necessary
        },
        '& .MuiTableCell-root': {
            padding: "8px"
        }
    },
    tableCell: {
        textAlign: 'left', // Centers text in table cells
        borderBottom: 'none', // Removes the line under each cell

    },
    // Define fixed widths for each type of cell
    titleCell: {
        width: '25%', // Adjust as needed

    },
    locationCell: {
        width: '35%', // Adjust as needed
    },
    scoreCell: {
        width: '30%', // Adjust as needed
        textAlign: 'center', // Assuming score is centered
    },
    actionCell: {
        width: '15%', // Adjust as needed
        textAlign: 'center', // Actions like buttons are usually centered
    },
}));

export default useExamListStyles;
