

import { makeStyles } from "@material-ui/core/styles";

const useHomeStyles = makeStyles((theme) => ({


    card: {
        maxWidth: 230,
        borderRadius: 20,
        backgroundColor: '#E2E8F0',
        boxShadow: 'none',

    },
    media: {
        height: 100,
        backgroundColor: '#CBD5E0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px 8px 0 0',
    },
    icon: {
        width: '60%',
        height: '60%',
    },
    content: {
        backgroundColor: '#F1F5F9',
        padding: '8px 14px !important',
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',
    },
    description: {
        color: '#4A5568',
        fontSize: '10px',
    },
    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        height: "100%",
        marginTop: 1,
        overflowX: "hidden",
        [theme.breakpoints.down('1700')]: {
            maxHeight: "75vh",
        },
        [theme.breakpoints.down('1000')]: {
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            marginTop: "5vh",
            width: "100%",
            height: "auto",
            overflowY: "auto"
        },
    },
    chatContainer: {
        width: '45vh',
        [theme.breakpoints.down('1450')]: {
            width: '38vh',
        },
        [theme.breakpoints.down('1160')]: {
            width: '30vh',
        },
    },
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        flex: 2, // This will make the paper fill the height of its container
        minHeight: '100%',
        height: "auto",
        overflowY: 'auto',
    },
    paperRight: {
        display: "flex",
        flexDirection: "column",
        flex: 1, // This will make the paper fill the height of its container
        height: "100%",
        marginTop: "5vh",
        paddingBottom: "3px",
        overflow: 'hidden', // Prevent scrolling

    },
    boxImg: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "100px",
        '@media (max-height: 800px)': {
            marginTop: "30px",
        },
    },
    imgEmpty: {
        transform: 'scale(2)',
        [theme.breakpoints.down('xs')]: {
            transform: 'scale(1)'
        },
        '@media (max-height: 800px)': {
            transform: 'scale(1)'
        },
    },
    boxStyle: {
        border: '2px solid #026277',
        borderRadius: '20px',
        padding: '10px',
        position: 'relative',
        height: '70px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: "200px",
        gap: "20px",
        cursor: "pointer"
    },
    divider: {
        margin: "20px 0",
    },
    link: {
        color: "#096CFB",
    },
    buttonBox: {
        marginTop: "20px",
    },
    connect: {
        width: "100%",
        marginBottom: "10px",
        color: "black",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "35px",
        letterSpacing: "-0.135px",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid #6E6E6E",
        background: "#FFF",
        textTransform: "None",
    },
    access: {
        marginTop: "30px",
        borderRadius: "15px",
        background: "#026277",
        color: "#FFF",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        padding: "30px",
    },
    accessCalendar: {
        marginTop: "20px",
        marginBottom: "10px",
        borderRadius: "15px",
        background: "#026277",
        color: "#FFF",
        width: "100%",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        padding: "20px",
    },
}));

export default useHomeStyles;
