import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import cardsStyles from '../cardsStyles';
import VideoDialog from '../Video/TutorialComponent';
const CardItem = ({ title, description, iconSrc, altText, color, executeFunction }) => {
    const classes = cardsStyles({ color });

    return (
        <Card className={classes.card} onClick={executeFunction}>
            <Box className={classes.media}>
                <img src={iconSrc} alt={altText} className={classes.icon} />

            </Box>
            <CardContent className={classes.content}>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Typography variant="body2" className={classes.description}>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

const CardVideo = ({ videoSrc, color }) => {
    const classes = cardsStyles({ color });
    const [open, setOpen] = useState(false);

    // Handle opening the dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Handle closing the dialog
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={classes.cardVideo}>
                <Box className={classes.mediaVideo}>
                    {/* Image that covers the full card */}
                    {/* <img
                        src="https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322868/golden-retriever-puppy.jpg"
                        alt="Video Thumbnail"
                        className={classes.mediaFull}
                        onClick={handleClickOpen}
                    /> */}
                    <div>

                        <a onClick={handleClickOpen}
                        >
                            <img
                                style={{ maxWidth: '300px' }}
                                src="https://cdn.loom.com/sessions/thumbnails/b2a481c57c424c14b75332a8ef25dba1-d1070b40d3d0b2d7-full-play.gif"
                                alt="Thumbnail of video"
                            />
                        </a>
                    </div>
                </Box>
            </Card>
            <VideoDialog open={open} handleClose={handleClose} typeVideo="global" />
        </>
    );
};





const CardComponent = ({ handleCreateExam, handleClickOpenNotReady }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCreateMemorise = () => {
        navigate("/memorise", { state: { reset: true }, replace: true })
    }
    const do_exam = {
        title: t('do_exam.title'),
        description: t('do_exam.description'),
        iconSrc: 'https://sourceimagestyped.s3.eu-west-2.amazonaws.com/DoExam.svg',
        altText: 'Exam Icon',
        color: "#ACB3FD"
    };
    const do_flashcards = {
        title: t('do_flashcards.title'),
        description: t('do_flashcards.description'),
        iconSrc: 'https://sourceimagestyped.s3.eu-west-2.amazonaws.com/flashcard.svg',
        altText: 'FlashcardIcon',
        color: "#F8DB69"
    };
    const do_studysession = {
        title: t('do_studysession.title'),
        description: t('do_studysession.description'),
        iconSrc: 'https://sourceimagestyped.s3.eu-west-2.amazonaws.com/activerecall.svg',
        altText: 'sesionestudio Icon',
        color: "#E6ADFD"
    };

    return (
        <>
            {/* <CardItem {...do_exam} executeFunction={handleCreateExam} /> */}
            <CardVideo videoSrc="https://www.loom.com/share/4e044455fc524b50b3d7410bed46f3f5?sid=c7202e36-019a-4c26-9873-ee2a45f337e1" color="#E2E8F0" />
            <CardItem {...do_exam} executeFunction={handleCreateExam} />
            {/* <CardItem {...do_studysession} executeFunction={handleCreateMemorise} /> */}
        </>
    );
};

export default CardComponent;
