import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import UploadButton from '../NewComponents/SubirApuntesButton';
import { makeStyles } from '@material-ui/core/styles';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchExamId } from '../Api/ExamsApi';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { t } from 'i18next';

const truncateText = (text, length) => {
    if (text.length <= length) {
        return text;
    }
    return text.substring(0, length) + '...';
};

const RowExamGrid = ({ memoriseId, name, pageIni, pageFin, lastAttempt, grade, accessToken, user, onRemove }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const handleClickMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    // const handleRemoveExam = async (memoriseId) => {
    //     try {
    //         await fetchMemoriseId(accessToken, "DELETE", memoriseId, user);
    //         onRemove(memoriseId);
    //         setAnchorEl(null);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const formattedDate = lastAttempt ? format(new Date(lastAttempt), 'dd/MM/yyyy') : '';
    const pagesIniFin = `${pageIni} - ${pageFin}`

    return (
        <TableRow>
            <TableCell className={`${classes.tableCell} ${classes.multilineCell}`}>{name}</TableCell>
            <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>{pagesIniFin}</TableCell>
            <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>{formattedDate}</TableCell>
            <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>{grade}</TableCell>
            <TableCell className={classes.tableCell}>

                <UploadButton onClick={() => {
                    let newPath = ""
                    if (grade) {

                        newPath = `/memorise/${memoriseId}/correction`.trim();
                    } else {
                        newPath = `/memorise/${memoriseId}/read`.trim();
                    }
                    navigate(newPath);
                }} message={"Repasar"} svg_icon={"_"} />

            </TableCell>
            {/* <TableCell className={classes.tableCell} style={{ cursor: "pointer" }}>
                <svg onClick={handleClickMenu} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18Z" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6Z" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => { handleRemoveExam(memoriseId) }}>Delete</MenuItem>
                </Menu>
            </TableCell> */}
        </TableRow>
    );
};

const useStyles = makeStyles((theme) => ({
    tableCellHeader: {
        fontSize: '14px',
        fontWeight: 'bold',
        borderBottom: 'none',
        textAlign: "center"
    },
    tableCell: {
        fontSize: '14px',
        borderBottom: 'none',
    },
    multilineCell: {
        whiteSpace: 'pre-line',
    },
    tableContainer: {
        boxShadow: 'none',
    }
}));

const MemoriseGridView = ({ rawData, accessToken, user, onRemoveRow }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const newRows = Object.entries(rawData).map(([guid, item]) => {
            const memoriseName = item.document.title;
            const truncatedDocumentName = truncateText(memoriseName, 17);

            const title = (
                <span>
                    {' '}
                    {memoriseName.length > 17 ? (
                        <Tooltip title={memoriseName}>
                            <span>{truncatedDocumentName}</span>
                        </Tooltip>
                    ) : (
                        memoriseName
                    )}

                </span>
            );

            return {
                ...item,

                title: title,
            };
        });
        setRows(newRows);
    }, [rawData]);

    const handleRemoveRow = (memoriseId) => {
        setRows(currentRows => currentRows.filter(row => row.guid !== memoriseId));
        onRemoveRow(memoriseId);
    };
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader} style={{ textAlign: 'left' }}>{t("name")}</TableCell>
                        <TableCell className={classes.tableCellHeader}> {t("pages")}</TableCell>
                        <TableCell className={classes.tableCellHeader}>{t("last_attempt")}</TableCell>
                        <TableCell className={classes.tableCellHeader}>{t("last_score")}</TableCell>
                        <TableCell className={classes.tableCellHeader}></TableCell>
                        {/* <TableCell className={classes.tableCellHeader}></TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {rows.map((row, index) => (
                        <RowExamGrid

                            memoriseId={row.guid}
                            name={row.title}
                            pageIni={row.init_page}
                            pageFin={row.final_page}
                            lastAttempt={row.date}
                            grade={row.grade}
                            user={user}
                            accessToken={accessToken}
                            onRemove={handleRemoveRow}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MemoriseGridView;
