// api.js
import { getCsrfToken } from './TokenApi'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



export async function fetchUserFolders(token, method = "GET", requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];

    const apiUrl = `${BACKEND_URL}/api_v1/folders/?user_id=${user_id}`; // Update with your actual API endpoint

    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };
        if (method === "POST" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchUserFolder(token, method = "GET", folder_id, requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/folder/${folder_id}/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };


        if (method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        if (method === "DELETE") {
            return true
        }
        if (method === "PUT") {
            return true
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}
